import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const reportsSlice = createSlice({
  name: "reportids",
  initialState,
  reducers: {
    GetReportids: (state, action) => {
      // debugger;
      console.log("slice", action.payload);

      state.push(action.payload);
    },
    reset: (state, action) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { GetReportids, reset } = reportsSlice.actions;

export default reportsSlice.reducer;
