import React, { useState, useEffect, useMemo, useRef } from "react";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import SearchWorkItemDetails from "./SearchWorkItemDetails";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import tableIcons from "../../../../MaterialTableIcons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../contexts/DashboardContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { FcSearch } from "react-icons/fc";
import { FcImageFile } from "react-icons/fc";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import Iframe from "react-iframe";
import { DisplayTimeZone, UploaddateLocalTime } from "../../Comman/Timezone";
//Added By Durgansh
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AttachmentIcon from "@mui/icons-material/Attachment";
//Added By Durgansh
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";
import { cloneDeep } from 'lodash';

import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useCallback } from "react";

const SearchWorkItem = () => {
  const WorkitemIdRef = useRef(null);
  const CuurentStateRef = useRef(null);
  const CuurentStatusRef = useRef(null);
  const CuurentWithRef = useRef(null);
  const SearchTextRef = useRef(null);
  const ddlSourceFieldsRef = useRef(null);
  const ddlPreferredFieldsRef = useRef(null);
  const btnApplySearchRef = useRef(null);

  const { addToast } = useToasts();
  const { REACT_APP_SEARCHWORKITEMGRIDDATA } = process.env;
  const { REACT_APP_GET_SEARCHWORKITEMDETAILS } = process.env;

  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GETWORKFLOWSTATES } = process.env;
  const { REACT_APP_GETWORKFLOWSTATUS } = process.env;
  const { REACT_APP_GETWORKFLOWITEMFIELDS } = process.env;
  const { REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY } = process.env;
  const { REACT_APP_FETCH_EMPLOYEESFORSUBPROCESS } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  //Added By Durgansh
  const { REACT_APP_GETWORKITEMATTACHMENTS } = process.env;
  const { REACT_APP_DOWNLOADWORKITEMATTACHMENT } = process.env;
  const { REACT_APP_DELETEWORKITEMATTACHMENT } = process.env;
  //Added By Durgansh
  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  let currentAcitvity = "";
  if (typeof processingDashboardParam.row.activityName !== "undefined") {
    currentAcitvity = processingDashboardParam.row.activityName;
  } else {
    currentAcitvity = processingDashboardParam.currentAcitvity;
  }
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [notifyModal, setShow] = useState(false);
  const [workitemId, setworkitemId] = useState("");
  const [currentState, setcurrentState] = useState("");
  const [currentStateID, setcurrentStateID] = useState(0);
  const [currentStatus, setcurrentStatus] = useState("");
  const [subproseeworkflowConfigid, setsubproseeworkflowConfigid] = useState(
    ""
  );
  const [currentWith, setcurrentWith] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [imageUrlTemplate, setimageUrlTemplate] = useState("");
  const [uplodadedDateTimeFrom, setuplodadedDateTimeFrom] = useState("");
  const [uplodadedDateTimeTo, setuplodadedDateTimeTo] = useState("");
  const [scanDateTimeFrom, setscanDateTimeFrom] = useState("");
  const [scanDateTimeTo, setscanDateTimeTo] = useState("");
  const [activityDateTimeFrom, setactivityDateTimeFrom] = useState("");
  const [activityDateTimeTo, setactivityDateTimeTo] = useState("");
  const [checkArchive, setcheckArchive] = useState(false);
  const [showStatusReasonComment, setshowStatusReasonComment] = useState(false);
  const [prefenceValue, setprefenceValue] = useState(3);
  const [advSearchField, setadvSearchField] = useState("");
  const [advSearchMapToField, setadvSearchMapToField] = useState("");
  const [advCondition, setadvCondition] = useState("");
  const [advSearchText, setadvSearchText] = useState("");
  const [advSearchCondition, setadvSearchCondition] = useState("");
  const [searchWorkitemData, setsearchWorkitemData] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [advSearchDetails, setadvSearchDetails] = useState([]);
  const [advSearchMapToFieldDetails, setadvSearchMapToFieldDetails] = useState(
    []
  );
  const [WorKFlowStates, setWorkFlowStates] = useState([]);
  const [CurrentStateID_Client, setCurrentStateID_Client] = useState("");
  const [WorkFlowWorkItemFields, setWorkFlowWorkItemFields] = useState([]);
  const [WorkFlowStatuses, setWorkFlowStatuses] = useState([]);
  const [isLoadingFigma, setisLoadingFigma] = useState(false);
  const [employeesToAssign, setemployeesToAssign] = useState([]);
  const [numericOperator, setnumericOperator] = useState(false);
  const [isDateField, setisDateField] = useState(false);
  const [notEqual, SetnotEqual] = useState("<>");
  const [lessThan, SetlessThan] = useState("<");
  const [lessThanEqual, SetlessThanEqual] = useState("<=");
  const [greaterThen, SetgreaterThen] = useState(">");
  const [greaterThenEqual, SetgreaterThenEqual] = useState(">=");
  const [viewSearchModal, setviewSearchModal] = useState(false);
  const [viewMyPrefrenceModal, setviewMyPrefrenceModal] = useState(false);
  const [viewDocumentModal, setviewDocumentModal] = useState(false);
  const [checkedValue, setcheckedValue] = useState("3");
  const [numberOfRecord, setnumberOfRecord] = useState(10);
  const [searchDivVisible, setsearchDivVisible] = useState(false);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  //Added  by Durgansh
  const [AttachmentDetails, setAttachmentDetails] = useState([]);
  //Added By Durgansh
  //Added By Jinnat Ahmed
  const [workItemFieldsModel, setworkItemFieldsModel] = useState([]);
  const [workItemDetailModel, setworkItemDetailModel] = useState([]);
  //Ended By Jinnat Ahmed

  const [CurrentPrefrencesFieldsToShow, setCurrentPrefrencesFieldsToShow] =
    useState([]);
  const [SelectedPrefrencesFieldsToShow, setSelectedPrefrencesFieldsToShow] = useState([]);

  const [SelectedFields, setSelectedFields] = useState([]);
  const [SelectedPreferenceFields, setSelectedPreferenceFields] = useState([]);

  const [SavedPrefrencesFields, setSavedPrefrencesFields] = useState([]);
  //const [SelectedPreferencesFields, setSelectedPreferencesFields] = useState([]);
  //Added by Pradip Rai
  const { REACT_APP_Image_URL } = process.env;
  const { REACT_APP_Image_URL_HUBBELL } = process.env;
  const { REACT_APP_HUBBELL_CLIENTID } = process.env;
  const { REACT_APP_C520_PROGRESS_CLIENTID } = process.env;
  const { REACT_APP_Image_URL_GEC } = process.env;
  const { REACT_APP_GEC_CLIENTID } = process.env;
  //Added by Sachin
  const { REACT_APP_GETCOLUMNPREFRENCEWORKITEMFIELDSBYSUBPROCESSID } =
    process.env;
  const { REACT_APP_SAVEWORKITEMCOLUMNPREFRENCE } = process.env;

  //Ended by Pradip Rai
  //added by Santosh
  const [auditTrail, setbtnAuditTrailVisible] = useState(false);
  //

  let advanceColumns = [
    { title: "Search Field", field: "searchField" },
    { title: "Condition", field: "condition" },
    { title: "Search Text", field: "searchText" }
  ];

  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: "#FB4E0B",
  //     },
  //     secondary: {
  //       main: "#FB4E0B",
  //     },
  //   },
  // });
  //Added By Durgansh
  const UploadAttachmentColumns = [
    {
      title: "AttachmentId",
      field: "workItemAttachmentID",
      hidden: true
    },
    {
      title: "UploadPath",
      field: "uploadedFilePath",
      hidden: true
    },
    {
      title: "Download",
      render: row => {
        let AttachmentUrl =
          getAPIURL(REACT_APP_DOWNLOADWORKITEMATTACHMENT) +
          "?fileName=" +
          row["mainUploadedFileName"] +
          "&folderPath=" +
          row["uploadedFilePath"];
        return (
          <>
            <a href={AttachmentUrl}>
              <AttachmentIcon />
            </a>
          </>
        );
      }
    },
    {
      title: "Uploaded By",
      field: "uploadedBy"
    },
    {
      title: "Uploaded FileName",
      field: "uploadedFileName"
    },
    { title: "Uploaded On", field: "uploadedOn" }
  ];
  //Added By Durgansh
  const addAdvanceSearchConditions = () => {
    //setadvSearchText()
    let condition = {
      searchField: advSearchField,
      condition: advCondition,
      searchText: SearchTextRef.current.value
    };
    let mapToCondition = {
      searchField: advSearchMapToField,
      condition: advCondition,
      searchText: SearchTextRef.current.value
    };
    setadvSearchDetails(advSearchDetails => [...advSearchDetails, condition]);
    setadvSearchMapToFieldDetails(advSearchMapToFieldDetails => [
      ...advSearchMapToFieldDetails,
      mapToCondition
    ]);
  };
  const handleRowDeleteAttachment = (oldData) => {
    if (window.confirm("Are you sure want to delete this record?")) {
      let body = {
        AttachmentID: oldData.workItemAttachmentID,
        mainUploadedFileName: oldData.mainUploadedFileName,
        filePath: oldData.uploadedFilePath,
        personId: sessionStorage.getItem("PersonID")
      };
      let attachmentDeleteUrl = getAPIURL(REACT_APP_DELETEWORKITEMATTACHMENT);
      let req = new Request(`${attachmentDeleteUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            getWorkItemAttachments(workitemId);
            addToast("Delete Sucessful!", {
              appearance: "success",
              autoDismiss: true
            });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Error occured!", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            let Bodydetails = "";
            for (let i = 0; i < Object.keys(body).length; i++) {
              Bodydetails =
                Bodydetails +
                ", " +
                Object.keys(body)[i] +
                ": " +
                Object.values(body)[i];
            }
          }
        });

    }
  };
  const handleRowDelete = (oldData, resolve) => {
    const dataDelete = [...advSearchDetails];
    const mapDataDelete = [...advSearchMapToFieldDetails];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    mapDataDelete.splice(index, 1);
    setadvSearchDetails([...dataDelete]);
    setadvSearchMapToFieldDetails([...mapDataDelete]);
    resolve();
  };
  const viewDoucmentClose = () => {
    setviewDocumentModal(false);
  };
  // const getWorkitemId = (e) => {
  //   setworkitemId(e.target.value);
  // };
  // const getCurrentState = (e) => {
  //   //setcurrentStateID(+e.target.value.split("/")[0]);
  //   //setcurrentState(e.target.value.split("/")[1]);

  //   if (
  //     e.target.value.split("/")[1] == "Complete" ||
  //     e.target.value.split("/")[1] == "Exceptions" ||
  //     e.target.value.split("/")[1] == "Exception"
  //   ) {
  //     setcurrentStateID(CurrentStateID_Client);
  //   } else setcurrentStateID(e.target.value.split("/")[0]);

  //   if (
  //     e.target.value.split("/")[1] == "Exceptions" ||
  //     e.target.value.split("/")[1] == "Complete" ||
  //     e.target.value.split("/")[1] == "Client" ||
  //     e.target.value.split("/")[1] == "Exception"
  //   ) {
  //     if (e.target.value.split("/")[1] == "Exceptions")
  //       setcurrentState("Exception");
  //     else setcurrentState(e.target.value.split("/")[1]);
  //   } else {
  //     setcurrentState("");
  //   }
  // };
  // const getCurrentStatus = (e) => {
  //   setsubproseeworkflowConfigid(e.target.value.split("/")[0]);
  //   setcurrentStatus(e.target.value.split("/")[1]);
  // };
  // const getCurrentWith = (e) => {
  //   setcurrentWith(e.target.value);
  // };
  
  //Added By Durgansh
  const getWorkItemAttachments = (workItemIDInputParam) => {
    let body = {
      workItemID: workItemIDInputParam
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMATTACHMENTS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          if (result.data.workItemAttachments != null) {
            result.data.workItemAttachments.map(item => {
              let index = item.uploadedFileName.indexOf("_");
              item.uploadedFileName = item.uploadedFileName.substring(++index);
            });
            setAttachmentDetails(result.data.workItemAttachments);
          } else {
            setAttachmentDetails([]);
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Error occured!", {
            appearance: "error",
            autoDismiss: true
          });
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };
  //Added By Durgansh
  const handleApplyUpload = (event, picker) => {
    setuplodadedDateTimeFrom(picker.startDate.format("MM/DD/YYYY HH:mm"));
    setuplodadedDateTimeTo(picker.endDate.format("MM/DD/YYYY HH:mm"));
  };
  const handleApplyActivity = (event, picker) => {
    setactivityDateTimeFrom(picker.startDate.format("MM/DD/YYYY HH:mm"));
    setactivityDateTimeTo(picker.endDate.format("MM/DD/YYYY HH:mm"));
  };
  const handleApplyScan = (event, picker) => {
    setscanDateTimeFrom(picker.startDate.format("MM/DD/YYYY"));
    setscanDateTimeTo(picker.endDate.format("MM/DD/YYYY"));
  };
  const getArchive = e => {
    setcheckArchive(!checkArchive);
  };
  const getPreference = e => {
    setcheckedValue(e.target.value);
    setprefenceValue(parseInt(e.target.value));
  };
  const addAllFieldValuestoSelectedColumn = () => {
    let finalSelectedArray = SelectedPrefrencesFieldsToShow;
    CurrentPrefrencesFieldsToShow.map((e) => {
      finalSelectedArray = [...finalSelectedArray, e];
    });
    setSelectedPrefrencesFieldsToShow(finalSelectedArray);
    setCurrentPrefrencesFieldsToShow([]);
  };
  const removeAllFieldValuestoSelectedColumn = () => {
    let finalSelectedArray = CurrentPrefrencesFieldsToShow;
    SelectedPrefrencesFieldsToShow.map((e) => {
      finalSelectedArray = [...finalSelectedArray, e];
    });
    setCurrentPrefrencesFieldsToShow(finalSelectedArray);
    setSelectedPrefrencesFieldsToShow([]);
  };
  const addFieldValuestoSelectedColumn = () => {
    // After moving item to another dropdown, next available items get selected automatically. This code will deselect those items
    for(let index = 0; index < ddlSourceFieldsRef.current.options.length; index++)
    {
      ddlSourceFieldsRef.current.options[index].selected = false;
    }

    let selectedFieldId = [];
    let selectedFieldName = [];
    let finalSelectedArray = [];
    SelectedFields.map((e) => {
      const selectedName = e.split("/")[0];
      const selectedId = e.split("/")[1];
      selectedFieldName = [...selectedFieldName, selectedName];
      selectedFieldId = [...selectedFieldId, selectedId];
    });

    const filteredField = CurrentPrefrencesFieldsToShow.filter(
      (item) => !selectedFieldId.includes(item.workitemfieldid)
    );
    const selectedField = CurrentPrefrencesFieldsToShow.filter((item) =>
      selectedFieldId.includes(item.workitemfieldid)
    );
    setCurrentPrefrencesFieldsToShow(filteredField);
    finalSelectedArray = SelectedPrefrencesFieldsToShow;

    selectedField.map((e) => {
      finalSelectedArray = [...finalSelectedArray, e];
    });
    setSelectedPrefrencesFieldsToShow(finalSelectedArray);
    setSelectedFields([]);
  };
  const removeFieldValuestoSelectedColumn = () => {
    // After moving item to another dropdown, next available items get selected automatically. This code will deselect those items
    for(let index = 0; index < ddlPreferredFieldsRef.current.options.length; index++)
    {
      ddlPreferredFieldsRef.current.options[index].selected = false;
    }
    let selectedFieldId = [];
    let selectedFieldName = [];
    let finalSelectedArray = [];
    SelectedPreferenceFields.map((e) => {
      const selectedName = e.split("/")[0];
      const selectedId = e.split("/")[1];
      selectedFieldName = [...selectedFieldName, selectedName];
      selectedFieldId = [...selectedFieldId, selectedId];
    });
    const filteredField = SelectedPrefrencesFieldsToShow.filter(
      (item) => !selectedFieldId.includes(item.workitemfieldid)
    );
    const selectedField = SelectedPrefrencesFieldsToShow.filter((item) =>
      selectedFieldId.includes(item.workitemfieldid)
    );
    setSelectedPrefrencesFieldsToShow(filteredField);
    finalSelectedArray = CurrentPrefrencesFieldsToShow;
    selectedField.map((e) => {
      finalSelectedArray = [...finalSelectedArray, e];
    });
    setCurrentPrefrencesFieldsToShow(finalSelectedArray);
    setSelectedPreferenceFields([]);
  };
  const getCurrentField = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedFields(selectedOptions);
  };
  const getCurrentFieldPrefrence = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedPreferenceFields(selectedOptions);
  };
  const getAdvanceField = (e) => {
    setnumericOperator(false);
    setisDateField(false);
    setadvSearchText("");

    let datetimeValue = e.target.value.split("/")[0].includes("DateTime");
    let intValue = e.target.value.split("/")[0].includes("Int");
    let DecimalValue = e.target.value.split("/")[0].includes("Decimal");
    let SLADueDateValue = e.target.value.split("/")[0].includes("SLADueDate");
    let ScanDateValue = e.target.value.split("/")[0].includes("ScanDate");
    let UploadDateValue = e.target.value.split("/")[0].includes("UploadDate");
    if (
      datetimeValue > 0 ||
      intValue > 0 ||
      DecimalValue > 0 ||
      SLADueDateValue > 0 ||
      ScanDateValue > 0 ||
      UploadDateValue > 0
    ) {
      setnumericOperator(true);
      setadvCondition("=");
    } else {
      setadvCondition("Equals");
    }
    if (datetimeValue > 0) {
      setisDateField(true);
    }

    setadvSearchField(e.target.value.split("/")[1]);
    setadvSearchMapToField(e.target.value.split("/")[0]);
  };
  const getAdvanceCondition = e => {
    setadvCondition(e.target.value);
  };
  const getAdvanceText = e => {
    setadvSearchText(e.target.value);
  };
  const getStatusReasonValue = e => {
    setshowStatusReasonComment(!showStatusReasonComment);
  };
  const resetForm = () => {
    document.getElementById("searchItem-form").reset();
    setadvSearchField("");
    setadvSearchMapToField("");
    setadvSearchMapToFieldDetails([]);
    setadvCondition("");
    setadvSearchText("");
    setadvSearchCondition("");
    setsearchWorkitemData([]);
    setadvSearchDetails([]);
    setworkitemId("");
    setuplodadedDateTimeFrom("");
    setuplodadedDateTimeTo("");
    setscanDateTimeFrom("");
    setscanDateTimeTo("");
    setactivityDateTimeFrom("");
    setactivityDateTimeTo("");
  };
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const updateColumns = keys => {
    console.log(sessionStorage.getItem("EmployeeVersionID"));
    const workflowstateID = sessionStorage.getItem("workflowstateID");
    const activityId = sessionStorage.getItem("PageId");
    let col = [];
    keys.forEach((item, index) => {
      if (index == 0) {
        col.push({
          title: item,
          field: item,
          render: row => (
            <>
              {JSON.parse(
                sessionStorage.getItem("allowedProcesses")
              )?.some(obj =>
                obj.activityName
                  .toLowerCase()
                  .includes(row.CurrentState.toLowerCase())
                  && (row.IsSuperUser=='True' || row.CurrentlyWith== null)
              ) ? (
                <>
                  <span
                    style={{ backgroundColor: row.BGColor }}
                    // class="dot"
                    // title="Why this color? Click to see."
                    // data-bs-toggle="offcanvas"
                    // data-bs-target="#offcanvasColor"
                    // aria-controls="offcanvasColor"
                  ></span>
                  <Link
                    to="/ActivityScreen"
                    state={{
                      row,
                      selectedSubprocess,
                      nav,
                      userRoles,
                      employeeVersionID: sessionStorage.getItem(
                        "EmployeeVersionID"
                      ),
                      workflowstateID: JSON.parse(
                        sessionStorage.getItem("allowedProcesses")
                      )?.find(
                        obj =>
                          obj.activityName.toLowerCase() ===
                          row.CurrentState.toLowerCase()
                      )?.workflowStateID,
                      activityId: JSON.parse(
                        sessionStorage.getItem("allowedProcesses")
                      )?.find(
                        obj =>
                          obj.activityName.toLowerCase() ===
                          row.CurrentState.toLowerCase()
                      )?.activityId,
                      assignedWorkItemDetails: navData,
                      workItemID: row[item],
                      currentAcitvity: JSON.parse(
                        sessionStorage.getItem("allowedProcesses")
                      )?.find(
                        obj =>
                          obj.activityName.toLowerCase() ===
                          row.CurrentState.toLowerCase()
                      )?.activityName
                    }}
                  >
                    {row[item]}
                  </Link>
                </>
              ) : (
                row[item]
              )}
            </>
          )
        });
      } else {
        col.push({
          title: item,
          field: item
        });
      }
    });
    //col.pop();
    setcolumns(col);
  };
  /*****Added by Pradip Rai on 13/12/2022***/
  const [viewWorkitemModal, setviewWorkitemModal] = useState(false);
  const [workitemHistory, setworkitemHistory] = useState([]);
  const [workitemDetails, setworkitemDetails] = useState([]);
  const { REACT_APP_GET_WORKITEMDETAILS } = process.env;
  const { REACT_APP_WORKITEMMARKURGENT } = process.env;

  const viewWorkitemClose = () => {
    setworkitemHistory([]);
    setworkitemDetails([]);
    setworkItemFieldsModel([]);
    setworkItemDetailModel([]);
    setviewWorkitemModal(false);
    setworkitemId("");
  };
  const listWorkItemHistoryColumns = [
    {
      title: "From State",
      field: "fromState"
    },
    { title: "Status Name", field: "statusName" },
    { title: "To State", field: "toState" },
    { title: "Status By", field: "statusedBy" },
    { title: "Status DateTime", field: "statusDateTime" },
    { title: "Status Comment", field: "statusComment" },
    { title: "Status Reason", field: "statusReasons" }
  ];
  const redirectToActivityScreen = row => {
    console.log("enter", +row);
  };
  const viewWorkitemShow = item => {
    //Added By Durgansh
    getWorkItemAttachments(item.WorkItemID);
    setviewWorkitemModal(true);
    setworkitemId(item.WorkItemID);
    getSelectedWorkItem(item.WorkItemID);
  };
  const getSelectedWorkItem = workItemId => {
    let body = {
      workItemID: workItemId,
      //personid: sessionStorage.getItem("PersonID"), //#1
      subprocessid: processingDashboardParam.selectedSubprocess,
      selectedvalue: checkedValue
    };
    let loginUrl = getAPIURL(REACT_APP_GET_SEARCHWORKITEMDETAILS);
    //let loginUrl = getAPIURL(REACT_APP_GET_WORKITEMDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          //DisplayTimeZone(result.data.asignmentdetails);
          setworkitemDetails(result.data.asignmentdetails);
          //DisplayTimeZone(result.data.listWorkItemHistory);
          setworkitemHistory(result.data.listWorkItemHistory);
          setworkItemDetailModel(result.data.workItemDetails);
          setworkItemFieldsModel(result.data.workItemFieldsDetails);

          //Santosh Added
          // for (let i = 0; i < result.data.listWorkItemHistory.length; i++) {
          //   setbtnAuditTrailVisible(
          //     result.data.listWorkItemHistory[i].auditTrail
          //   );
          //   //Endedd
          //   return;
          // }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const asignmentdetailsColumns = [
    {
      title: "Description",
      field: "description"
    },
    { title: "Assigned By", field: "assignedBy" },
    { title: "Assignment Date", field: "assignmentDate" }
  ];

  /*****Ended by Pradip Rai on 13/12/2022***/
  const getWorkFlowstates = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID")      
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWSTATES);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setWorkFlowStates(result.data._listAllStates);
          for (let i = 0; i < result.data._listAllStates.length; i++) {
            if (result.data._listAllStates[i].nextState == "Client") {
              console.log(result.data._listAllStates[i].workflowstateid);
              setCurrentStateID_Client(
                result.data._listAllStates[i].workflowstateid
              );
            }
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getWorkFlowstatus = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID")
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWSTATUS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setWorkFlowStatuses(result.data._listStatus);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const documnetShow = row => {
    console.log(row.CWSOnBaseDocID);
    if (
      row.CWSOnBaseDocID != undefined &&
      row.CWSOnBaseDocID != "" &&
      row.CWSOnBaseDocID != 0
    ) {
      setimageUrl(
        window.open(
          imageUrlTemplate.replace("@onBaseDocumentID", row.CWSOnBaseDocID),
          "_blank",
          "fullscreen=yes"
        )
      );

      //END
      //Below code Commented on 27 Dec 2023 by Sachin-START
      //setviewDocumentModal(true);
      // setimageUrl(
      //   imageUrlTemplate.replace("@onBaseDocumentID", row.CWSOnBaseDocID)
      // );

      //END

      //setimageUrl(imageUrl.replace("@onBaseDocumentID", "122775763"));
    } //setimageId(id)
    else {
      addToast("Document Id not present", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    //setimageKey(key)
    //setimageDocId(docId)
  };
  const savePreferences = () => {
    let workitemArray = [];
    SelectedPrefrencesFieldsToShow.map((e) => {
      workitemArray = [...workitemArray, e.workitemfieldid];
    });
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("PersonID"),
      workItem: workitemArray,
    };
    let loginUrl = getAPIURL(REACT_APP_SAVEWORKITEMCOLUMNPREFRENCE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setSavedPrefrencesFields(SelectedPrefrencesFieldsToShow);
          addToast("Record saved successfully!!", {
            appearance: "success",
            autoDismiss: true,
          });
          setviewMyPrefrenceModal(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getAdvanceSearchField = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
      personID: sessionStorage.getItem("PersonID")
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWITEMFIELDS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setWorkFlowWorkItemFields(result.data._listWorkItemFields);
          setSavedPrefrencesFields(result.data._listGetSavedPrefrenceWIFields);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  useEffect(() => {
    // ;
    //setadvSearchMapToFieldDetails([]);
    setsearchWorkitemData([]);

    setviewDocumentModal(false);
    getWorkFlowstates();
    getWorkFlowstatus();
    getAdvanceSearchField();
    getEmployeesToAssign();
    setadvSearchMapToField("");
    // return () => {
    //   clearInterval(interval);
    //   //   alert('unmount')
    // };
  }, []);

  const getEmployeesToAssign = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_EMPLOYEESFORSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setemployeesToAssign(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const searchWorkitemSubmit = e => {
    if(setviewSearchModal)
      setviewSearchModal(false); //Added by Trupti
    setsearchDivVisible(true);
    let finalCondition = "";
    //setadvSearchCondition(finalCondition);
    setisLoadingFigma(true);
    e.preventDefault();
    let currentStateID = 0;
    let currentState = "";
    let currentStatus = "";
    let subproseeworkflowConfigid = "";
    let currentlyWith = "";
    setsearchWorkitemData([]);
    
    if (CuurentStateRef.current.value !== "Select") {
      if (
        CuurentStateRef.current.value.split("/")[1] == "Complete" ||
        CuurentStateRef.current.value.split("/")[1] == "Exceptions" ||
        CuurentStateRef.current.value.split("/")[1] == "Exception"
      ) {
        currentStateID = CurrentStateID_Client;
      } else currentStateID = CuurentStateRef.current.value.split("/")[0];

      if (
        CuurentStateRef.current.value.split("/")[1] == "Exceptions" ||
        CuurentStateRef.current.value.split("/")[1] == "Complete" ||
        CuurentStateRef.current.value.split("/")[1] == "Client" ||
        CuurentStateRef.current.value.split("/")[1] == "Exception"
      ) {
        if (CuurentStateRef.current.value.split("/")[1] == "Exceptions")
          currentState = "Exception";
        else currentState = CuurentStateRef.current.value.split("/")[1];
      } else {
        currentState = "";
      }
    }
    if (CuurentStatusRef.current.value !== "Select") {
      currentStatus = CuurentStatusRef.current.value.split("/")[1];
      subproseeworkflowConfigid = CuurentStatusRef.current.value.split("/")[0];
    }
    if (CuurentWithRef.current.value !== "Select") {
      currentlyWith = CuurentWithRef.current.value;
    }
    let body = {
      workitmID: WorkitemIdRef.current.value,
      rblstate: prefenceValue,
      currentStatus: currentStatus,
      subprocessId: processingDashboardParam.selectedSubprocess,
      subproseeworkflowConfigid: subproseeworkflowConfigid,
      currentState: currentState,
      currentStateID: currentStateID,
      currentlyWith: currentlyWith,
      doSearchArchive: checkArchive,
      stringFilterValue: "",
      uplodadedDateTimeFrom: uplodadedDateTimeFrom,
      uplodadedDateTimeTo: uplodadedDateTimeTo,
      scanDateTimeFrom: scanDateTimeFrom,
      scanDateTimeTo: scanDateTimeTo,
      activityDateTimeFrom: activityDateTimeFrom,
      activityDateTimeTo: activityDateTimeTo,
      empid: +sessionStorage.getItem("PersonID"),
      searchField: advSearchField,
      condition: advCondition,
      searchText: finalCondition,
      showStatusReasonComment: showStatusReasonComment,
      dashboardPageCondition: advSearchMapToFieldDetails
    };
    let loginUrl = getAPIURL(REACT_APP_SEARCHWORKITEMGRIDDATA);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setnumberOfRecord(10);
          if (result.data._listworkitemgridData.length > 0) {
            // result.data._listworkitemgridData.map(e=>{
            //   e.lastActivityOn=e.lastActivityOn.split(" ")[0]
            // })

            if (result.data._listworkitemgridData.length < 100) {
              setnumberOfRecord(s => {
                return (s = result.data._listworkitemgridData.length);
              });
            }

            //Added By Gajendra

            //DisplayTimeZone(result.data._listworkitemgridData);
            setsearchWorkitemData(result.data._listworkitemgridData);

            updateColumns(Object.keys(result.data._listworkitemgridData[0]));
            //Added by Pradip Rai
            if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation || REACT_APP_C520_PROGRESS_CLIENTID == sessionStorage.selectedLocation) {
              setimageUrlTemplate(
                REACT_APP_Image_URL_HUBBELL.replace(
                  "@onBasePassword",
                  encodeURIComponent(result.data.onBasePassword)
                ).replace(
                  "@onBaseUserID",
                  encodeURIComponent(result.data.onBaseUserID)
                )
              );
            } else if (
              REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation
            ) {
              setimageUrlTemplate(
                REACT_APP_Image_URL_GEC.replace(
                  "@onBasePassword",
                  encodeURIComponent(result.data.onBasePassword)
                ).replace(
                  "@onBaseUserID",
                  encodeURIComponent(result.data.onBaseUserID)
                )
              );
            } else {
              setimageUrlTemplate(
                REACT_APP_Image_URL.replace(
                  "@onBasePassword",
                  encodeURIComponent(result.data.onBasePassword)
                ).replace(
                  "@onBaseUserID",
                  encodeURIComponent(result.data.onBaseUserID)
                )
              );
            }
            // const ImagetempUrl =

            //   "https://unitydocumentviewer.exlservice.com/ImageViewer.aspx?OBXID=" +
            //   result.data.onBaseUserID +
            //   "&OBKey=" +
            //   result.data.onBasePassword +
            //   "&DocID=@DocId&IsCWS=1";
            // setimageUrl(ImagetempUrl);
            //Ended by pradip Rai
          } else {
            setsearchWorkitemData([]);
          }
          //setadvSearchMapToFieldDetails([]);
          setadvSearchField("");
          setadvSearchMapToField("");
          setadvCondition("");
          setadvSearchText("");
          setadvSearchCondition("");
          //setadvSearchDetails([]);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setadvSearchMapToFieldDetails([]);
          addToast("Search Failed!", {
            appearance: "error",
            autoDismiss: true
          });
        }
        setisLoadingFigma(false);
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const showAdvanceSearch = () => {
    setviewSearchModal(true);
  };
  const showMyPrefrence = () => {
    getPrefrenceValues();
  };
  const getPrefrenceValues = () => {
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId"),
      userId: sessionStorage.getItem("PersonID"),
    };
    let loginUrl = getAPIURL(
      REACT_APP_GETCOLUMNPREFRENCEWORKITEMFIELDSBYSUBPROCESSID
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setCurrentPrefrencesFieldsToShow(result.data._listPrefrenceWIFields);
          setSelectedPrefrencesFieldsToShow(
            result.data._listGetSavedPrefrenceWIFields
          );
          setviewMyPrefrenceModal(true);
          //     }
        } else if (result.status === 401) {
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const CloseMyPrefrence = () => {
    setviewMyPrefrenceModal(false);    
    setTimeout(function() { btnApplySearchRef.current.blur() }, 300);
  };
  const viewSearchClose = () => {
    setviewSearchModal(false);
  };
  const viewSearchCloseAndRest = () => {
    setviewSearchModal(false);
    setadvSearchMapToFieldDetails([]);
    setadvSearchField("");
    setadvSearchMapToField("");
    setadvCondition("");
    //setadvSearchText("");
    setadvSearchCondition("");
    //setsearchWorkitemData([]);
    setadvSearchDetails([]);
  };
  const changeState = rowdata => {
    console.log(rowdata);
    settingShow(true);
  };
  const markUrgent = rowdata => {
    //console.log(rowdata);REACT_APP_WORKITEMMARKURGENT
    let WorkItemIDs = "";
    for (let i = 0; i < rowdata.length; i++) {
      WorkItemIDs += rowdata[i].WorkItemID + ",";
      console.log(rowdata[i].WorkItemID);
    }
    markUrgentAPICALL(WorkItemIDs);
  };

  const markUrgentAPICALL = WorkItemIDs => {
    let body = {
      workitmID: WorkItemIDs
    };
    let WorkItemIDs1 = "WorkItemIDs:" + WorkItemIDs + "";
    let loginUrl = getAPIURL(REACT_APP_WORKITEMMARKURGENT);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          addToast("Priority has been set successfully.", {
            appearance: "info",
            autoDismiss: true
          });
        } else if (result.status === 401) {
          addToast(
            "Priority has been not set./Session Expired/UnAuthorized. Please LogIn Again",
            {
              appearance: "error",
              autoDismiss: true
            }
          );
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  // FILTER TOGGLE FUNCTION
  const filterRef = React.useRef(null);
  const onFilterClick = e => {
    filterRef.current.classList.toggle("active");
  };
  // NOTIFY MODAL FUNCTION
  const notifyClose = () => setShow(false);
  const notifyShow = () => setShow(true);
  // SETTING MODAL FUNCTION
  const [settingModal, settingShow] = useState(false);
  const settingClose = () => settingShow(false);
  const settingOpen = () => settingShow(true);
  // DAILY TRANS. MODAL FUNCTION
  const [dailyTransModal, dailyTransShow] = useState(false);
  const dailyTransClose = () => dailyTransShow(false);
  const dailyTransOpen = () => dailyTransShow(true);
  // ACTIVITY SUMMARY MODAL FUNCTION
  const [activitySumModal, activitySumShow] = useState(false);
  const activitySumClose = () => activitySumShow(false);
  const activitySumOpen = () => activitySumShow(true);

  const downloadExcel = () => {
    const fileName = "SearchWorkItem";
    const exportType = "xls";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Remove tableData column from export
    let workItems = cloneDeep(searchWorkitemData);
    workItems.map(function(item) { 
      delete item.tableData; 
      return item; 
    });

    const ws = XLSX.utils.json_to_sheet(workItems);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const NavElements = useMemo(() => {
    return (
      <>
        <Header
          onpreferenceShow={null}
          pageName="Search Work Items"
          timeCaptureActivityName="Welcome"
        />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav selectedMenu="activities" />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  return (
    <>
      {NavElements}

      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <form onSubmit={searchWorkitemSubmit} id="searchItem-form">
              <div className="formWrap">
                <div className="">
                  <h4 className="mb-4">Basic Search Criteria</h4>

                  <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Work Item ID</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          //value={workitemId}
                          ref={WorkitemIdRef}
                          //onChange={getWorkitemId}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Current State</label>
                        <select
                          className="form-control form-control-sm"
                          ref={CuurentStateRef}
                          //onChange={getCurrentState}
                        >
                          <option>Select</option>
                          {WorKFlowStates.map(state => (
                            <option
                              value={
                                state.workflowstateid + "/" + state.nextState
                              }
                            >
                              {state.nextState}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Current Status</label>
                        <select
                          className="form-control form-control-sm"
                          ref={CuurentStatusRef}
                          //onChange={getCurrentStatus}
                        >
                          <option>Select</option>
                          {WorkFlowStatuses.map(status => (
                            <option
                              value={
                                status.subProcessWorkflowConfigID +
                                "/" +
                                status.statusName
                              }
                            >
                              {status.statusName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Current With</label>
                        <select
                          className="form-control form-control-sm"
                          ref={CuurentWithRef}
                          //onChange={getCurrentWith}
                        >
                          <option>Select</option>
                          {employeesToAssign.map(employee => (
                            <option value={employee.employeeID}>
                              {employee.empName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Upload Date & time</label>
                        <DateRangePicker
                          initialSettings={{
                            autoApply: true,
                            autoUpdateInput: false,
                            opens: "right",
                            timePicker: true,
                            locale: { format: "M/DD/YY hh:mm A" }
                          }}
                          onApply={handleApplyUpload}
                        >
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Select Date"
                            value={
                              uplodadedDateTimeFrom + "-" + uplodadedDateTimeTo
                            }
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">
                          Activity Date & time
                        </label>
                        <DateRangePicker
                          initialSettings={{
                            autoApply: true,
                            autoUpdateInput: false,
                            opens: "right",
                            timePicker: true,
                            locale: { format: "M/DD/YY hh:mm A" }
                          }}
                          onApply={handleApplyActivity}
                        >
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Select Date"
                            value={
                              activityDateTimeFrom + "-" + activityDateTimeTo
                            }
                          />
                        </DateRangePicker>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Scan Date & time</label>
                        <DateRangePicker
                          initialSettings={{
                            autoApply: true,
                            autoUpdateInput: false,
                            opens: "right",
                            timePicker: true,
                            locale: { format: "M/DD/YY hh:mm A" }
                          }}
                          onApply={handleApplyScan}
                        >
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Select Date"
                            value={scanDateTimeFrom + "-" + scanDateTimeTo}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <div className="form-check form-check-inline ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                            onChange={getArchive}
                            value={checkArchive}
                          />
                          <label
                            className="form-check-label"
                            for="inlineCheckbox1"
                          >
                            Include Archived Work Items
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="pt-3 pt-1">
                          <div className="form-check form-check-inline mx-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio4"
                              value="3"
                              checked={checkedValue === "3" ? "yes" : null}
                              onChange={getPreference}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio4"
                            >
                              All
                            </label>
                          </div>
                          <div className="form-check form-check-inline ms-4 mx-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="0"
                              checked={checkedValue === "0" ? "yes" : null}
                              onChange={getPreference}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Open
                            </label>
                          </div>
                          <div className="form-check form-check-inline ms-4 mx-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="1"
                              checked={checkedValue === "1" ? "yes" : null}
                              onChange={getPreference}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              Complete
                            </label>
                          </div>
                          <div className="form-check form-check-inline ms-4 mx-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio3"
                              value="2"
                              checked={checkedValue === "2" ? "yes" : null}
                              onChange={getPreference}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio3"
                            >
                              Closed
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-2" style={{ "padding-top": "9px" }}>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary ms-2"
                          onClick={showAdvanceSearch}
                        >
                          <b className="icon-edit"></b> Advance Search
                        </button>
                      </div>
                      <div className="col-2" style={{ "padding-top": "9px" }}>
                        <button
                          type="button"
                          className={SavedPrefrencesFields?.length > 0 ? "btn btn-sm ms-2 btn-primary" : "btn btn-sm ms-2 btn-outline-primary"}
                          onClick={showMyPrefrence}
                          ref={btnApplySearchRef}
                        >
                          <b className="icon-edit"></b> Preference
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <hr className="mb-4" /> */}
                <hr className="mb-4" />
                <div className="fieldSet">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2">
                        <div className="form-check form-switch mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={getStatusReasonValue}
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Show Status Reason & Comment
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"                          
                        >
                          Apply Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                      <div className="d-inline">
                        <div className="input-group input-group-sm">
                          <label
                            className="input-group-text"
                            for="inputGroupSelect01"
                          >
                            Show
                          </label>
                          <select
                            className="form-select"
                            id="inputGroupSelect01"
                          >
                            <option selected value="1">
                              All
                            </option>
                            <option value="2">Open</option>
                            <option value="3">Complete</option>
                            <option value="4">Closed</option>
                          </select>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary ms-2"
                        onClick={() => {
                          downloadExcel();
                        }}
                      >
                        <b></b>Export
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-12 text-right mt-2"></div> */}
                </div>
              </div>
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: ""
                          }
                        }}
                        actions={[
                          {
                            icon: () => <FcSearch />,
                            tooltip: "View workitem's detail",
                            onClick: (event, rowData) =>
                              viewWorkitemShow(rowData),
                            position: "row"
                          },
                          {
                            icon: () => <FcImageFile />,
                            tooltip: "View document",
                            onClick: (event, rowData) => documnetShow(rowData),
                            position: "row"
                          },
                          {
                            tooltip: "Actions",
                            icon: () => (
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-primary ms-2"
                                onClick={settingShow}
                              >
                                <b className="icon-settings"></b> Change State
                              </button>
                            ),
                            onClick: (event, rowData) => changeState(rowData)
                          },
                          {
                            tooltip: "Actions",
                            icon: () => (
                              <button
                                type="button"
                                className="btn btn-sm btn-primary ms-2"
                              >
                                {" "}
                                Marked Urgent
                              </button>
                            ),
                            onClick: (event, rowData) => markUrgent(rowData)
                          }
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px"
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [100, 200, 500, 1000],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: true,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            height: 30
                          },
                          rowStyle: {
                            // fontFamily: '"Yantramanav", sans-serif',
                            // fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            // fontFamily: '"Yantramanav", sans-serif',
                            // fontWeight: "inherit",
                            // fontSize: "14px",
                            // textAlign: "left !Important",
                          }
                        }}
                        columns={columns}
                        icons={tableIcons}
                        data={searchWorkitemData}
                        components={{
                          Container: props => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: props => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em"
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px"
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
        {/* <a onClick={dailyTransShow} className="btn btn-outline-primary mb-3">
          Show Sub Process Summary
        </a>
        <a onClick={activitySumShow} className="btn btn-outline-primary mb-3">
          Employee Activity Summary
        </a> */}
      </div>
      <Footer />
      {/** VIEW WORKITEM MODAL **/}
      <Modal
        show={viewWorkitemModal}
        dialogClassName="modal-100w"
        onHide={viewWorkitemClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Workitem Details</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  {workItemDetailModel.length > 0 ? (
                    <SearchWorkItemDetails
                      state={{ workItemDetailModel, isDynamic: false }}
                    />
                  ) : null}
                </div>
              </ul>
            </div>
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Workitem Fields</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  {workItemFieldsModel.length > 0 ? (
                    <SearchWorkItemDetails
                      state={{ workItemFieldsModel, isDynamic: true }}
                    />
                  ) : null}
                </div>
              </ul>
            </div>

            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>WorkItem History</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table MatTable">
                      <MaterialTable
                        title="WorkItems History"
                        options={{
                          search: false,
                          overflowY: false,
                          showTitle: false,
                          cellStyle: {
                            // width: 100,
                            // minWidth: 100,
                            whiteSpace: "Normal"
                          },
                          headerStyle: {
                            position: "sticky"
                            // top: "-5px",
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif'
                          }
                        }}
                        columns={listWorkItemHistoryColumns}
                        icons={tableIcons}
                        data={workitemHistory}
                        components={{
                          Container: props => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: props => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em"
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{ fontSize: "5px" }}
                              />
                            </div>
                          )
                        }}
                      />
                    </table>
                  </div>
                </div>
              </ul>
            </div>
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Assignment Details</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table MatTable">
                      <MaterialTable
                        title="Asignment Details"
                        options={{
                          search: false,
                          overflowY: false,
                          showTitle: false,
                          cellStyle: {
                            // width: 100,
                            // minWidth: 100,
                            whiteSpace: "Normal"
                          },
                          headerStyle: {
                            position: "sticky"
                            // top: "-5px",
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif'
                          }
                        }}
                        columns={asignmentdetailsColumns}
                        icons={tableIcons}
                        data={workitemDetails}
                        components={{
                          Container: props => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: props => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em"
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px"
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                    </table>
                  </div>
                </div>
              </ul>
            </div>
              {/*Added By Durgansh */}
              <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Attachment Details</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table MatTable">
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                          },
                          header: {
                            actions: ""
                          }
                        }}
                        actions={[
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete",
                            onClick: (event, rowData) =>
                              handleRowDeleteAttachment(rowData),
                            position: "row"
                          }
                        ]}
                        options={{
                          search: false,
                          // selection: true,
                          tableLayout: "auto",
                          debounceInterval: 700,
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          paging: false,
                          toolbar: false,
                          headerStyle: {
                            height: 30
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "14px"
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",

                            textAlign: "left !Important"
                          }
                        }}
                        columns={UploadAttachmentColumns}
                        icons={tableIcons}
                        data={AttachmentDetails}
                      />
                    </table>
                  </div>
                </div>
              </ul>
            </div>
            {/*Added By Durgansh */}
          </div>
        </Modal.Body>
      </Modal>
      {/** VIEW DOCUMENT MODAL **/}
      <Modal
        show={viewDocumentModal}
        dialogClassName="modal-100w"
        onHide={viewDoucmentClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>WORKITEM'S DOCUMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body">
                  <table className="table brandTable">
                    <div>
                      <Iframe
                        url={imageUrl}
                        width="1150px"
                        height="450px"
                        id="myId"
                        display="initial"
                        position="relative"
                      />
                    </div>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/** ASSIGN & CHANGE STATUS MODAL **/}
      <Modal
        show={settingModal}
        dialogClassName="modal-25w"
        onHide={settingClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change State</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <li>
                <label className="form-label">Change State</label>
                <select className="form-control form-control-sm">
                  <option>Select</option>
                </select>
              </li>
              <li>
                <label className="form-label">State Comment</label>
                <textarea
                  className="form-control"
                  placeholder="Type here...."
                ></textarea>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-ntv mx-2" onClick={settingClose}>
            Cancel
          </button>
          <button className="btn btn-primary">Submit</button>
        </Modal.Footer>
      </Modal>

      {/** DAILY TRANSACTION STATISTICS **/}
      <Modal
        show={dailyTransModal}
        dialogClassName="modal-25w"
        onHide={dailyTransClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Daily Transaction Statistics</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tableWrap">
            <div className="tableWrap-body mb-3">
              <table className="table table-borderless brandInfoTable">
                <thead>
                  <tr>
                    <th colSpan="2">Start of the day</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>With Exl</td>
                    <td className="text-center">21</td>
                  </tr>
                  <tr>
                    <td>With Client</td>
                    <td className="text-center">0</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="tableWrap-body mb-3">
              <table className="table table-borderless brandInfoTable">
                <thead>
                  <tr>
                    <th colSpan="2">During the day</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Recevied by Exl</td>
                    <td className="text-center">0</td>
                  </tr>
                  <tr>
                    <td>Complete by Exl</td>
                    <td className="text-center">0</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="tableWrap-body">
              <table className="table table-borderless brandInfoTable">
                <thead>
                  <tr>
                    <th colSpan="2">Current Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>With Exl</td>
                    <td className="text-center">21</td>
                  </tr>
                  <tr>
                    <td>With Client</td>
                    <td className="text-center">0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-ntv mx-2"
            onClick={dailyTransClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/** EMPLOYEE ACTIVITY SUMMARY **/}
      <Modal
        show={activitySumModal}
        dialogClassName="modal-80w"
        onHide={activitySumClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Employee Activity Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pt-2 pb-2">
            <div className="mb-5">
              <h2 className="modalSubTitle mb-3">Employee Time Utilization</h2>
              <div>
                <table className="table table-borderless brandInfoTable">
                  <thead>
                    <tr>
                      <th>Employee ID</th>
                      <th>Employee Name</th>
                      <th>Activity Name</th>
                      <th>Previous Day</th>
                      <th>Current Day</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>123805</td>
                      <td>Santosh Kumar</td>
                      <td>Home</td>
                      <td>00:00:27 Hrs</td>
                      <td>02:03:50 Hrs</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <h2 className="modalSubTitle mb-3">Work Item Count</h2>
              <div>
                <table className="table table-borderless brandInfoTable">
                  <thead>
                    <tr>
                      <th>Employee ID</th>
                      <th>Employee Name</th>
                      <th>Activity Name</th>
                      <th>Previous Day</th>
                      <th>Current Day</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>123805</td>
                      <td>Santosh Kumar</td>
                      <td>Home</td>
                      <td>00:00:27 Hrs</td>
                      <td>02:03:50 Hrs</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-ntv mx-2"
            onClick={activitySumClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewSearchModal}
        dialogClassName="modal-100w"
        onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search Criteria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body">
                  <table className="table brandTable">
                    <div>
                      <div className="fieldSet pt-1">
                        {/* <h4 className="mb-4">Advanced Search Criteria</h4> */}
                        <div className="row align-items-end">
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">
                                Search field{" "}
                              </label>
                              <select
                                className="form-control form-control-sm"
                                title="Select a field to apply filter"
                                onChange={getAdvanceField}
                              >
                                <option>Select</option>
                                {WorkFlowWorkItemFields.map(status => (
                                  <option
                                    value={
                                      status.maptoField + "/" + status.labelText
                                    }
                                  >
                                    {status.labelText}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">Condition </label>
                              {numericOperator ? (
                                <select
                                  className="form-control form-control-sm"
                                  title="Select a field to apply filter"
                                  onChange={getAdvanceCondition}
                                >
                                  <option value="Select">Select</option>
                                  <option value="=">=</option>
                                  <option value="<>">{notEqual}</option>
                                  <option value="<">{lessThan}</option>
                                  <option value="<=">{lessThanEqual}</option>
                                  <option value=">">{greaterThen}</option>
                                  <option value=">=">{greaterThenEqual}</option>
                                </select>
                              ) : (
                                <select
                                  className="form-control form-control-sm"
                                  title="Select a field to apply filter"
                                  onChange={getAdvanceCondition}
                                >
                                  <option value="Select">Select</option>
                                  <option value="Equals">Equals</option>
                                  <option value="Begin with">Begin with</option>
                                  <option value="Contains">Contains</option>
                                  <option value="End with">End with</option>
                                  <option value="Does Not Contain">
                                    Does Not Contain
                                  </option>
                                </select>
                              )}
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">Search Text</label>
                              {isDateField ? (
                                <input
                                  type="date"
                                  className="form-control form-control-sm"
                                  //onChange={getAdvanceText}
                                  //value={advSearchText}
                                />
                              ) : (
                                <input
                                  className="form-control form-control-sm"
                                  ref={SearchTextRef}
                                  // onChange={getAdvanceText}
                                  // value={advSearchText}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-primary w-100 text-uppercase"
                                onClick={addAdvanceSearchConditions}
                              >
                                {" "}
                                Add
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="pt-2 pb-2">
                          <div className="mb-5">
                            <div>
                              <table className="table table-borderless MatTable">
                                {/* <ThemeProvider theme={theme}> */}
                                <MaterialTable
                                  localization={{
                                    pagination: {
                                      //labelDisplayedRows: '{from}-{to} of {count}'
                                    },
                                    toolbar: {
                                      // nRowsSelected: '{0} row(s) selected'
                                    },
                                    header: {
                                      actions: ""
                                    }
                                  }}
                                  options={{
                                    search: false,
                                    // selection: true,
                                    tableLayout: "auto",
                                    overflowY: false,
                                    showTitle: false,
                                    toolbarButtonAlignment: "left",
                                    paging: false,
                                    toolbar: false,
                                    headerStyle: {
                                      height: 30
                                    },
                                    rowStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontSize: "14px"
                                    },
                                    tableLayout: "auto",
                                    headerStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontWeight: "inherit",
                                      fontSize: "14px",

                                      textAlign: "left !Important"
                                    }
                                  }}
                                  editable={{
                                    onRowDelete: oldData =>
                                      new Promise(resolve => {
                                        handleRowDelete(oldData, resolve);
                                      })
                                  }}
                                  columns={advanceColumns}
                                  icons={tableIcons}
                                  data={advSearchDetails}
                                />
                                {/* </ThemeProvider> */}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <button
            className="btn btn-outline-ntv mx-2"
            onClick={viewSearchClose}
          >
            Save
          </button>
        </Modal.Footer> */}
        <Modal.Footer>
          <button
          //  className="btn btn-outline-ntv mx-2"
          //  onClick={viewSearchClose}
          className="btn btn-sm btn-primary ms-3"
          onClick={searchWorkitemSubmit} //Added by Trupti
          >
            Apply Search
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={viewMyPrefrenceModal}
        dialogClassName="modal-50w"
        onHide={CloseMyPrefrence}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Preferences</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="pt-1">
                {/* <h4 className="mb-4">Advanced Search Criteria</h4> */}
                <div className="row " style={{ width: "115%" }}>
                  <div className="col-5">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label mb-3 fs-6 ">
                        Source Fields
                      </label>
                      <select
                        multiple
                        size="10"
                        className="form-select"
                        title="Select a field to apply filter"
                        onChange={getCurrentField}
                        ref={ddlSourceFieldsRef}
                      >
                        {/* <option>Select</option> */}
                        {CurrentPrefrencesFieldsToShow.map((field) => (
                          <option
                            value={
                              field.labeltext + "/" + field.workitemfieldid
                            }
                          >
                            {field.labeltext}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-1 pt-3 ">
                    <div className="mb-3 d-flex flex-column align-items-center flex-column mt-5">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={addFieldValuestoSelectedColumn}
                      >
                        {">"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={addAllFieldValuestoSelectedColumn}
                      >
                        {">>"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={removeFieldValuestoSelectedColumn}
                      >
                        {"<"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={removeAllFieldValuestoSelectedColumn}
                      >
                        {"<<"}
                      </button>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label mb-3 fs-6 ">
                        Preferred Fields
                      </label>

                      <select
                        multiple
                        size="10"
                        className="form-select"
                        title="Select a field to apply filter"
                        //onChange={getSelectedFields}
                        onChange={getCurrentFieldPrefrence}
                        ref={ddlPreferredFieldsRef}
                      >
                        {/* <option>Select</option> */}
                        {SelectedPrefrencesFieldsToShow.map((field) => (
                          <option
                            value={
                              field.labeltext + "/" + field.workitemfieldid
                            }
                          >
                            {field.labeltext}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-ntv mx-2"
            onClick={savePreferences}
          >
            Save Preference
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(SearchWorkItem);
