import { createSlice } from "@reduxjs/toolkit";

const initialState = "true";

export const AppGlobalSlice = createSlice({
  name: "BrowserOnlineStatus",
  initialState,
  reducers: {
    GetBrowserOnlineStatus: (state, action) => {
      // debugger;
      console.log("browserstatus", action.payload);

      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { GetBrowserOnlineStatus } = AppGlobalSlice.actions;

export default AppGlobalSlice.reducer;
