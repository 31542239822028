import React, { useState, useEffect } from "react";
const { REACT_APP_LOGOUT } = process.env;


function getAPIURL(api) {
  const { REACT_APP_API_URL } = process.env;

  let apiURL = REACT_APP_API_URL;
  return `${apiURL}${api}`;
}
function SaveTimerOnTick_Worker() {

  let completebody = null;
  let activityId =
    JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
      .timerValue == 2
      ? sessionStorage.getItem("breakactivity")
      : sessionStorage.getItem("PageId");
  let UserName = sessionStorage.getItem("UserName");
  let mandateRequired = sessionStorage.getItem("authorizationToken");

  if (
    activityId != "null" &&
    activityId != null &&
    activityId != 0 &&
    activityId != "0"
  ) 
    {
    console.log("savetimer at ", new Date().toLocaleString());
    const { REACT_APP_SAVETIMEONTICK } = process.env;
    console.log("entered in savetimeontick");
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;
    let empTimeUtilizationvalues = sessionStorage.getItem("empTimeUtilization");
    // sessionStorage.setItem("empTimeUtilizationPrev", empTimeUtilizationvalues);
    
    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakactivity")
          : sessionStorage.getItem("PageId") == null
          ? 0
          : sessionStorage.getItem("PageId"), //for home page
      workItemID:
        sessionStorage.getItem("workItemIDInputParam") == "null" ||
        sessionStorage.getItem("workItemIDInputParam") == "undefined" ||
        sessionStorage.getItem("workItemIDInputParam") == null
          ? 0
          : JSON.parse(sessionStorage.getItem("workItemIDInputParam")),
      employeeTimeUtilization:
        empTimeUtilizationvalues == "null" ||
        empTimeUtilizationvalues == "undefined" ||
        empTimeUtilizationvalues == null
          ? []
          : JSON.parse(empTimeUtilizationvalues),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
        sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
        sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime:
        sessionStorage.getItem("breakStartTime") == "" ||
        sessionStorage.getItem("breakStartTime") == "undefined" ||
        sessionStorage.getItem("breakStartTime") == null
          ? ""
          : JSON.parse(sessionStorage.getItem("breakStartTime")),
      txtBreakComments:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakcomment") != null
            ? sessionStorage.getItem("breakcomment")
            : ""
          : "",
      timerValue: JSON.parse(
        sessionStorage.getItem("FetchBreakTimerOnLoadData")
      ).timerValue,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000,
      ignoreCapture:
        sessionStorage.getItem("IgnoreTimeCapture") == null
          ? false
          : sessionStorage.getItem("IgnoreTimeCapture").toLowerCase() == "true"
          ? true
          : false,
          HRISEmployeeID:sessionStorage.getItem("HRISEmployeeID")
    };
    completebody = {
      body: body,
      UserName: UserName,
      Token: mandateRequired,
      message: "Success"
    }
    
  } else {
    debugger
    

    completebody = {
      UserName: UserName,
      Token: mandateRequired,
      message: "Due to unsable network connection, data sync encoured an issue. Reloading your page."
    }

    
    //window.location.reload(true);
  }
  return completebody;
}

function SaveTimerOnTick() {
  let activityId =
    JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
      .timerValue == 2
      ? sessionStorage.getItem("breakactivity")
      : sessionStorage.getItem("PageId");
 
  if (
    activityId != "null" &&
    activityId != null &&
    activityId != 0 &&
    activityId != "0"
  ) {
    console.log("savetimer at ", new Date().toLocaleString());
    const { REACT_APP_SAVETIMEONTICK } = process.env;
    console.log("entered in savetimeontick");
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;
    let empTimeUtilizationvalues = sessionStorage.getItem("empTimeUtilization");
    // sessionStorage.setItem("empTimeUtilizationPrev", empTimeUtilizationvalues);
    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakactivity")
          : sessionStorage.getItem("PageId") == null
          ? 0
          : sessionStorage.getItem("PageId"), //for home page
      workItemID:
        sessionStorage.getItem("workItemIDInputParam") == "null" ||
        sessionStorage.getItem("workItemIDInputParam") == "undefined" ||
        sessionStorage.getItem("workItemIDInputParam") == null
          ? 0
          : JSON.parse(sessionStorage.getItem("workItemIDInputParam")),
      employeeTimeUtilization:
        empTimeUtilizationvalues == "null" ||
        empTimeUtilizationvalues == "undefined" ||
        empTimeUtilizationvalues == null
          ? []
          : JSON.parse(empTimeUtilizationvalues),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
        sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
        sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime:
        sessionStorage.getItem("breakStartTime") == "" ||
        sessionStorage.getItem("breakStartTime") == "undefined" ||
        sessionStorage.getItem("breakStartTime") == null
          ? ""
          : JSON.parse(sessionStorage.getItem("breakStartTime")),
      txtBreakComments:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakcomment") != null
            ? sessionStorage.getItem("breakcomment")
            : ""
          : "",
      timerValue: JSON.parse(
        sessionStorage.getItem("FetchBreakTimerOnLoadData")
      ).timerValue,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000,
      ignoreCapture:
        sessionStorage.getItem("IgnoreTimeCapture") == null
          ? false
          : sessionStorage.getItem("IgnoreTimeCapture").toLowerCase() == "true"
          ? true
          : false,
          HRISEmployeeID:sessionStorage.getItem("HRISEmployeeID")
    };
 
    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_SAVETIMEONTICK);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result != null) {
          if (result.status === 401) {
            sessionStorage.clear();
            sessionStorage.clear();
          } else {
            let FetchBreakTimerOnLoadData = JSON.parse(
              sessionStorage.getItem("FetchBreakTimerOnLoadData")
            );
            FetchBreakTimerOnLoadData.timerStart = result.timerStart;
            FetchBreakTimerOnLoadData.timerValue = result.timerValue;
            FetchBreakTimerOnLoadData.tSaveTimerEnabled =
              result.tSaveTimerEnabled;
            FetchBreakTimerOnLoadData.btnStartWorkTimerDisabled =
              result.btnStartWorkTimerDisabled;
            // FetchBreakTimerOnLoadData.txtBreakCommentsEnabled =
            //   result.txtBreakCommentsEnabled;
            // FetchBreakTimerOnLoadData.ddlActivityEnabled =
            //   result.ddlActivityEnabled;
            FetchBreakTimerOnLoadData.btnShowBreakPanelDisabled =
              result.btnShowBreakPanelDisabled;
            // sessionStorage.setItem(
            //   "objdataview",
            //   JSON.stringify(result.data[0].objdataview)
            // );
            sessionStorage.setItem(
              "FetchBreakTimerOnLoadData",
              JSON.stringify(FetchBreakTimerOnLoadData)
            );
            sessionStorage.setItem(
              "empTimeUtilization",
              JSON.stringify(result.employeeTimeUtilization)
            );
            sessionStorage.setItem(
              "timerlastsync",
              result.employeeTimeUtilization[0].timeFrom
            );
          }
 
      //#Added by Santosh
       if(result.autoLogOutResult==1) {
        //console.log(result.autoLogOutResult);
        sessionStorage.setItem(
          "autoLogOutResult",
          result.autoLogOutResult
        );
 
        autoLogOut();
 
        }
 
 
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
      });
  } else {
    alert(
      "Due to unsable network connection, data sync encoured an issue. Reloading your page."
    );
    window.location.reload(true);
  }
}

function updateEmpUtilzSession(employeeTimeUtilization) {
  let lastsynctime = new Date(sessionStorage.getItem("timerlastsync"));
  console.log("in update time ", lastsynctime);
  // debugger;
  if (lastsynctime != null) {
    let filteredvalues = employeeTimeUtilization.filter((item) => {
      return new Date(item.timeFrom) >= lastsynctime;
    });
    console.log(filteredvalues);
    if (filteredvalues.length > 0) {
      sessionStorage.setItem(
        "empTimeUtilization",
        JSON.stringify(filteredvalues)
      );
    }
  } else {
    sessionStorage.setItem(
      "empTimeUtilization",
      JSON.stringify(employeeTimeUtilization)
    );
  }
}


// //#Santosh
const autoLogOut = () => {
  
  //console.log(0);
  let body = {
    username: sessionStorage.getItem("UserName"),
    personId: sessionStorage.getItem("HRISEmployeeID"),
    personType: sessionStorage.getItem("PersonType"),
    etuList:
      sessionStorage.getItem("empTimeUtilization") == "null" ||
      sessionStorage.getItem("empTimeUtilization") == "undefined" ||
      sessionStorage.getItem("empTimeUtilization") == null
        ? []
        : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
  };

  let logOutUrl = getAPIURL(REACT_APP_LOGOUT);
  let req = new Request(`${logOutUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  fetch(req)
    .then((res) => res.json())
    .then((result) => {
      if (result.status === 200) {
      }

      alert('you have already reached the allocated work timings,you cannot login to the application');
      //addToast("you have already reached the allocated work timings,you cannot login to the application", {
     //   appearance: "error",
     //   autoDismiss: true,
    //  });

      sessionStorage.clear();
      //localStorage.clear();
      window.location.href = "/";
    })
    .catch((error) => {
      console.log("ERROR: ", error);
      sessionStorage.clear();
      //localStorage.clear();
    });
};

export { SaveTimerOnTick, updateEmpUtilzSession,autoLogOut, SaveTimerOnTick_Worker };
