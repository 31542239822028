import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-table-filter/lib/styles.css";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import GlobalStyles from "@mui/material/GlobalStyles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FcSearch } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ManageWorkItemDetails from "./ManageWorkItemDetails";
import WorkActivityScreenDetailsPage from "../WorkActivityScreen/WorkActivityScreenDetailsPage";
import MaterialTableCore, {
  MTableToolbar as MTableToolbarCore,
} from "@material-table/core";
import FormContext from "../../../../contexts/FormContext";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import { debuggerStatement } from "@babel/types";
import Alert from "@material-ui/lab/Alert";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  DownloadExcel,
  ArrayWithoutExtraColumn,
} from "../../Comman/ExcelFileDownload";
const ManageWorkitem = () => {
  const SearchTextRef = useRef(null);
  const MarkRef = useRef(null);
  const { addToast } = useToasts();
  const { REACT_APP_GETCOMPREHENSIVESEARCH } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GET_WORKITEMDETAILS } = process.env;
  const { REACT_APP_GETWORKFLOWSTATES } = process.env;
  const { REACT_APP_GET_STATUSESFORCURRENTWORKFLOWSTATEMANEGEWORKITEM } =
    process.env;
  const { REACT_APP_GETCONFIGUREDACTIVITIESFORSUBPROCESS } = process.env;
  const { REACT_APP_GETCONFIGUREDVIEWTYPEFORSUBPROCESS } = process.env;
  const { REACT_APP_FETCH_EMPLOYEESFORSUBPROCESS } = process.env;
  const { REACT_APP_GET_STATUSESFORCURRENTWORKFLOWSTATE } = process.env;
  const { REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY } = process.env;
  const { REACT_APP_GET_GETSTATUSREASONSBYSUBPROCESSID } = process.env;
  const { REACT_APP_UPDATEWORKITEMSTATUSANDSTATE } = process.env;
  const { REACT_APP_SAVEMANAGEWORKITEMREASSIGNMENT } = process.env;
  const { REACT_APP_GETMANAGEWORKITEMREASSIGNCONFIG } = process.env;
  const { REACT_APP_FETCHPREVIOUSACTIVITYFORWORKITEM } = process.env;
  const { REACT_APP_UPDATEWORKITEMTOPREVIOUSACTIVITY } = process.env;
  const { REACT_APP_GETSCREENDATAFORMULITPLEWORKITEMS } = process.env;
  const { REACT_APP_SAVEDATAFORMULTIPLEWORKITEMS } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_FETCHDURATION } = process.env;
  const location = useLocation();

  const processingDashboardParam = location.state;
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  const selectedWorkItemDetails =
    processingDashboardParam.selectedWorkItemDetails;
  let currentAcitvity = "Processing";
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const selectedFromDate = processingDashboardParam.fromDate;
  const selectedToDate = processingDashboardParam.toDate;
  const selectedViewType = processingDashboardParam.viewType;

  const selectedTask = processingDashboardParam.selectedTask;
  console.log(selectedTask);

  const currentactivityName = processingDashboardParam.currentAcitvity;
  const WorkItemCurrentStatus = processingDashboardParam.currentWorkItemStatus;

  let interval = null;
  const navigate = useNavigate();

  const [uplodadedDateTimeFrom, setuplodadedDateTimeFrom] = useState(
    moment(new Date()).subtract(1, "month").format("MM/DD/YYYY")
  );
  const [uplodadedDateTimeTo, setuplodadedDateTimeTo] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );
  const [applysearch, setapplysearch] = useState(true);
  const [applyReset, setapplyReset] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [markItems, setmarkItems] = useState(0);
  const handleApplyUpload = (event, picker) => {
    processingDashboardParam.fromDate = null;
    processingDashboardParam.toDate = null;
    setuplodadedDateTimeFrom(picker.startDate.format("MM/DD/YYYY"));
    setuplodadedDateTimeTo(picker.endDate.format("MM/DD/YYYY"));
    var dateDiff = Math.floor(
      (picker.endDate - picker.startDate) / (1000 * 60 * 60 * 24)
    );
    console.log(dateDiff + "--days");
    console.log(REACT_APP_FETCHDURATION + "from config");
    if (dateDiff > REACT_APP_FETCHDURATION) {
      alert(
        "The maximum number of days between selected dates should not be greater than " +
          REACT_APP_FETCHDURATION +
          ". Please select valid date and search."
      );
      setapplysearch(false);
      setuplodadedDateTimeFrom("");
      setuplodadedDateTimeTo("");
    } else setapplysearch(true);
    console.log(uplodadedDateTimeFrom);
    console.log(uplodadedDateTimeTo);
    setDashboardGridDataModels([]);
    setExportExcelDisable(true);
  };
  const handleReset = (event, picker) => {
    picker.startDate._d = new Date();
    picker.endDate._d = new Date();
    setuplodadedDateTimeFrom("");
    setuplodadedDateTimeTo("");
  };
  const handleEvent = (event, picker) => {
    if (applyReset) {
      setuplodadedDateTimeFrom("");
      setuplodadedDateTimeTo("");
      picker.startDate = moment();
      picker.endDate = moment();

      // picker.startDate = picker.oldStartDate;
      // picker.endDate = picker.oldEndDate;
      // //picker.hide();
      // picker.element.click('cancel.daterangepicker')

      // picker.oldStartDate._d = new Date();
      // picker.oldEndDate._d = new Date();
      handleReset(event, picker);
      setapplyReset(false);
    }
  };

  const [isLoading, set_IsLoading] = useState(false);
  const [isLoadingDashboard, set_isLoadingDashboard] = useState(false);
  const [taskchangestatus, settaskchangestatus] = useState(false);
  const [filterModal, filterShow] = useState(false);
  // SETTING MODAL FUNCTION
  const [settingModal, settingShow] = useState(false);
  const [settingassignModal, settingassignShow] = useState(false);
  const [selectedCheckboxDetails, setselectedCheckboxDetails] = useState([]);
  const [viewWorkitemModal, setviewWorkitemModal] = useState(false);
  const [showonlyassigned, setshowonlyassigned] = useState(false);
  const [TableSelection, setTableSelection] = useState(true);
  const [viewSearchModal, setviewSearchModal] = useState(false);
  const [columns, setcolumns] = useState([]);
  const [dashboardGridDataModels, setDashboardGridDataModels] = useState([]);
  const [workitemHistory, setworkitemHistory] = useState([]);
  const [workitemDetails, setworkitemDetails] = useState([]);
  const [advSearchField, setadvSearchField] = useState("");
  const [advSearchMapToField, setadvSearchMapToField] = useState("");
  const [advSearchDetails, setadvSearchDetails] = useState([]);
  const [advSearchMapToFieldDetails, setadvSearchMapToFieldDetails] = useState(
    []
  );
  const [WorKFlowStates, setWorkFlowStates] = useState([]);
  const [CurrentStateID_Client, setCurrentStateID_Client] = useState("");
  const [selectedActivityName, setselectedActivityName] = useState("");
  const [allowAssignment, setallowAssignment] = useState(false);
  const [workitemId, setworkitemId] = useState("");
  const [currentState, setcurrentState] = useState("");
  const [task, settask] = useState("");
  const [ShowMark, setShowMark] = useState(false);
  const [currentStateID, setcurrentStateID] = useState(0);
  const [WorKFlowStatus, setWorKFlowStatus] = useState([]);
  const [currentStatus, setcurrentStatus] = useState("");
  const [currentStatusID, setcurrentStatusID] = useState("");
  const [ActivitiesSelected, setActivitiesSelected] = useState("");
  const [ViewTypeSelected, setViewTypeSelected] = useState("");
  const [workitemMode, setworkitemMode] = useState("0");
  const [ConfiguredActivities, setConfiguredActivities] = useState([]);
  const [ConfiguredViewType, setConfiguredViewType] = useState([]);
  const [statusAssignedTo, setstatusAssignedTo] = useState("");
  const [statusReason, setstatusReason] = useState([]);
  const [assignmentdict, setassignmentdict] = useState([]);
  const [isassignmentdict, setisassignmentdict] = useState(false);
  const [statusComment, setstatusComment] = useState("");
  const [allowStatusChange, setAllowStatusChange] = useState(false);
  const [disableStatusChange, setdisableStatusChange] = useState(true);
  const [isComment, setisComment] = useState(false);
  const [isReasonshow, setisReasonshow] = useState(false);
  const [IsAssignedToRequired, setIsAssignedToRequired] = useState(false);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [statusChange, setstatusChange] = useState("");
  const [workItemStatus, setworkItemStatus] = useState([]);
  const [employeesToAssign, setemployeesToAssign] = useState([]);
  const [reassignconfig, setreassignconfig] = useState([]);
  const [filterReassignConfig, setFilterReassignConfig] = useState([]); //Added by Trupti
  const [workAllocationMode, setworkAllocationMode] = useState(false);
  const [isReason, setReason] = useState(false);
  const [assignTo, setAssignTo] = useState(false);
  const [workItemAllocationMode, setWorkItemAllocationMode] = useState("");
  const [filterValue, setfilterValue] = useState(0);
  const [employeesForSubprocessActivity, setemployeesForSubprocessActivity] =
    useState([]);
  const [statusReasonForSubprocess, setstatusReasonForSubprocess] = useState(
    []
  );
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [ViewUndoChangesModal, setViewUndoChangesModal] = useState(false);
  const [LastActivityColumns, setLastActivityColumns] = useState([]);
  const [LastActivityDetails, setLastActivityDetails] = useState([]);
  const [LastWorkitemStatus, setLastWorkitemStatus] = useState("");
  const [LastActivityName, setLastActivityName] = useState("");
  const [UndoWorkitemStatus, setUndoWorkitemStatus] = useState("");
  const [UndoActivityName, setUndoActivityName] = useState("");
  const [LastWorkitemStatusID, setLastWorkitemStatusID] = useState("");
  const [LastActivityNameID, setLastActivityNameID] = useState("");
  const [LastActivityStateID, setLastActivityStateID] = useState("");
  const [WorkitemIdForUndoActivity, setWorkitemIdForUndoActivity] =
    useState("");
  const [UndoStatusComment, setUndoStatusComment] = useState("");
  const [regGroup, setregGroup] = useState([]);
  const [JsonValues, setJsonValues] = useState([]);
  const [fieldClassName, setfieldClassName] = useState("col-4");
  const [SubmitAndContinueDisabled, setSubmitAndContinueDisabled] =
    useState(false);
  const [BulkEditDisable, setBulkEditDisable] = useState(true);
  const [ShowBulkEdit, setShowBulkEdit] = useState(false);
  const [BulkWorkItemsToEdit, setBulkWorkItemsToEdit] = useState([]);
  const [ExportExcelDisable, setExportExcelDisable] = useState(true);

  const [workItemFieldsModel, setworkItemFieldsModel] = useState([]);
  const [workItemDetailModel, setworkItemDetailModel] = useState([]);

  const handleChange = useContext(FormContext);
  const [ActivityCurrent, setActivityCurrent] = useState("");
  const [employee, setEmployee] = useState("");
  const [performedBy, setPerformedBy] = useState("");
  const [selectedCurrentStatus, setselectedCurrentStatus] = useState("");
  const [taskselected, settaskselected] = useState("");

  const asignmentdetailsColumns = [
    {
      title: "Description",
      field: "description",
    },
    { title: "Assigned By", field: "assignedBy" },
    { title: "Assignment Date", field: "assignmentDate" },
  ];
  const listWorkItemHistoryColumns = [
    {
      title: "From State",
      field: "fromState",
    },
    { title: "Status Name", field: "statusName" },
    { title: "To State", field: "toState" },
    { title: "Status By", field: "statusedBy" },
    { title: "Status DateTime", field: "statusDateTime" },
    { title: "Status Comment", field: "statusComment" },
    { title: "Status Reason", field: "statusReasons" },
    { title: "Time Zone", field: "timeZone" },
  ];
  // const workItemDetailsColumns = [
  //   { title: "Workitem ID",field: "workItemID",render: rowData => (
  //     <div style={{display: "inline-block !important",width: "30%"}}>
  //       <div>{rowData.workItemID}</div>
  //     </div>
  //   ),},
  //   { title: "SLA Start Date", field: "slaStartDate" ,render: rowData => (
  //     <div className="column-wrapper">
  //       <div>{rowData.slaStartDate}</div>
  //     </div>
  //   ),},
  //   { title: "SLA Due Date", field: "slaDueDate" ,render: rowData => (
  //     <div className="column-wrapper">
  //       <div>{rowData.slaDueDate}</div>
  //     </div>
  //   ),},
  //   { title: "SLA Completion Date", field: "slaCompletionDate" ,render: rowData => (
  //     <div className="column-wrapper">
  //       <div>{rowData.slaCompletionDate}</div>
  //     </div>
  //   ),},
  //   { title: "Current State", field: "currentState" ,render: rowData => (
  //     <div className="column-wrapper">
  //       <div>{rowData.currentState}</div>
  //     </div>
  //   ),},
  //   { title: "Last Worked By", field: "lastWorkedBy" ,render: rowData => (
  //     <div className="column-wrapper">
  //       <div>{rowData.lastWorkedBy}</div>
  //     </div>
  //   ),},
  //   { title: "Last Worked On", field: "lastWorkedOn" ,render: rowData => (
  //     <div className="column-wrapper">
  //       <div>{rowData.lastWorkedOn}</div>
  //     </div>
  //   ),},
  // ];

  const devRef = React.useRef();
  const intEmployeeIDRef = React.useRef();
  const performedByEmployeeIDRef = React.useRef();
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const editworkItemDetails = () => {
    let multipleWorkItemIDs = "";
    let singleWorkitemID = "";
    if (BulkWorkItemsToEdit.length > 0) {
      if (selectedItems.length > BulkWorkItemsToEdit.length) {
        for (let i = 0; i < selectedItems.length; i++) {
          if (i == 0) {
            singleWorkitemID = selectedItems[i];
            multipleWorkItemIDs = selectedItems[i];
          } else {
            multipleWorkItemIDs = multipleWorkItemIDs + "," + selectedItems[i];
          }
        }
      } else {
        for (let i = 0; i < BulkWorkItemsToEdit.length; i++) {
          if (i == 0) {
            singleWorkitemID = BulkWorkItemsToEdit[i].WorkItemID;
            multipleWorkItemIDs = BulkWorkItemsToEdit[i].WorkItemID;
          } else {
            multipleWorkItemIDs =
              multipleWorkItemIDs + "," + BulkWorkItemsToEdit[i].WorkItemID;
          }
        }
      }
    } else if (selectedItems.length > 0) {
      for (let i = 0; i < selectedItems.length; i++) {
        if (i == 0) {
          singleWorkitemID = selectedItems[i];
          multipleWorkItemIDs = selectedItems[i];
        } else {
          multipleWorkItemIDs = multipleWorkItemIDs + "," + selectedItems[i];
        }
      }
    }
    navigate("/BulkEdit", {
      state: {
        workItemID: singleWorkitemID,
        subprocessID: processingDashboardParam.selectedSubprocess,
        personID: sessionStorage.getItem("PersonID"),
        multipleWorkItemIDs: multipleWorkItemIDs,
        fromDate: uplodadedDateTimeFrom,
        toDate: uplodadedDateTimeTo,
        viewType: ViewTypeSelected,
        selectedTask:
          processingDashboardParam.selectedTask != null &&
          processingDashboardParam.selectedTask != "" &&
          processingDashboardParam.selectedTask != undefined
            ? processingDashboardParam.selectedTask
            : TaskRef.current.value,
        activityName: CurrentActivityRef.current.value,
        selectedSubprocess,
        nav,
        userRoles,
      },
    });
  };

  const reasonForStatusChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setstatusReason(value);
  };
  const commentForStatusChange = (e) => {
    setstatusComment(e.target.value);
  };
  const HandleReassignEmployee = (e, assignedactivitid) => {
    e.preventDefault();
    var dict = []; // create an empty array

    if (Object.keys(assignmentdict).length > 0) {
      dict = assignmentdict;
    }
    var dict2 = [];
    Object.keys(assignmentdict).map((item) => {
      if (dict[item].key != assignedactivitid) {
        dict2.push({
          key: dict[item].key,
          value: dict[item].value,
        });
      }
    });
    dict = dict2;

    dict.push({
      key: assignedactivitid,
      value: e.target.value,
    });

    setassignmentdict(dict);
  };
  const statusChangeSubmit = (e) => {
    e.preventDefault();
    let isSubmit = true;
    if (
      statusChange === 0 ||
      statusChange === "Select" ||
      statusChange === "0"
    ) {
      addToast("Status is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    if (isComment && statusComment == "") {
      addToast("Status Comment is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    if (isSubmit) {
      setstatusComment("");

      let body = {
        workItemIDs: selectedCheckboxDetails
          .map((e) => e["WorkItemID"])
          .join(","),
        subProcessID: processingDashboardParam.selectedSubprocess,
        currentStateID: +currentStateID,
        newStatus: +statusChange,
        statusComment: statusComment,
        performedBy: +sessionStorage.getItem("PersonID"),
        isForcedStateChange: true,
      };

      let loginUrl = getAPIURL(REACT_APP_UPDATEWORKITEMSTATUSANDSTATE);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            alert(result.data);
            FetchSearchData(
              sessionStorage.getItem("EmployeeVersionID"),
              filterValue
            );
            settingClose();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something went wrong!", {
              appearance: "error",
              autoDismiss: true,
            });
            //settingClose()
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    } else {
      // setisReasonshow(false)
      // setisComment(false)
      //settingClose();
    }
  };
  const AssignWorkitems = () => {
    const gridModel = dashboardGridDataModels;
    setBulkWorkItemsToEdit([]);
    setselectedItems([]);
    let selecteditemArray = [];
    if (TaskRef.current.value === "adhocediting" && MarkRef.current.value > 1) {
      setShowBulkEdit(true);
    } else {
      setShowBulkEdit(false);
    }
    if (
      selectedTask === "adhocediting" ||
      task === "adhocediting" ||
      task === "reassignment" ||
      task === "workflow state change" ||
      selectedTask === "reassignment" ||
      selectedTask === "workflow state change"
    ) {
      if (
        (TaskRef.current.value === "adhocediting" ||
          TaskRef.current.value === "workflow state change" ||
          TaskRef.current.value === "reassignment") &&
        MarkRef.current.value > 1
      ) {
        if (MarkRef.current.value > gridModel.length) {
          // if (TaskRef.current.value === "adhocediting") {
          //   setShowBulkEdit(true);
          // }
          // else
          // {
          //   setShowBulkEdit(false);
          // }
          addToast(
            "The number of workitems to be marked should be less than or equal to the number of workitems displayed in the grid.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          MarkRef.current.value = 0;
        } else {
          setBulkEditDisable(false);
        }

        //selectionProps();
      } else setBulkEditDisable(true);
    }
    for (let i = 0; i < MarkRef.current.value; i++) {
      selecteditemArray = [...selecteditemArray, "" + gridModel[i].WorkItemID];
    }
    setselectedItems(selecteditemArray);
    SelectionPros(selecteditemArray);
  };
  const toggleRowChecked = (row, selecteditemArray) => {
    if (selecteditemArray.includes(row.WorkItemID)) {
      return true;
    } else {
      return false;
    }
  };
  const SelectionPros = (selecteditemArray) => {
    const newData = dashboardGridDataModels.map((row) => ({
      ...row,
      tableData: {
        checked: toggleRowChecked(row, selecteditemArray),
      },
    }));
    setDashboardGridDataModels(newData);
  };
  const updateColumns = (keys) => {
    const workflowstateID = sessionStorage.getItem("workflowstateID");
    const activityId = sessionStorage.getItem("PageId");
    let col = [];

    keys.forEach((item) => {
      //console.log(item)
      if (
        item.toString().toLowerCase() === "workitemid" &&
        (task === "adhocediting" || selectedTask === "adhocediting")
      ) {
        col.push({
          title: item,
          field: item,
          render: (row) => (
            <Link
              to="/BulkEdit"
              state={{
                row,
                selectedSubprocess,
                nav,
                userRoles,
                workItemID: row[item],
                subprocessID: processingDashboardParam.selectedSubprocess,
                personID: sessionStorage.getItem("PersonID"),
                multipleWorkItemIDs: row[item],
                fromDate:
                  processingDashboardParam.fromDate != null &&
                  processingDashboardParam.fromDate != undefined
                    ? processingDashboardParam.fromDate
                    : uplodadedDateTimeFrom,
                toDate:
                  processingDashboardParam.toDate != null &&
                  processingDashboardParam.toDate != undefined
                    ? processingDashboardParam.toDate
                    : uplodadedDateTimeTo,
                viewType:
                  ViewTypeSelected === "" ? selectedViewType : ViewTypeSelected,
                selectedTask: task === "" ? selectedTask : task,
                currentWorkItemStatus: WorkFlowStatusRef.current.value,
                activityName:
                  processingDashboardParam.currentAcitvity != null &&
                  processingDashboardParam.currentAcitvity != undefined
                    ? processingDashboardParam.currentAcitvity
                    : ActivityCurrent,
                currentlyWith:
                  processingDashboardParam.currentlyWith != null &&
                  processingDashboardParam.currentlyWith != undefined
                    ? processingDashboardParam.currentlyWith
                    : employee,
                performedBy:
                  processingDashboardParam.performedBy != null &&
                  processingDashboardParam.performedBy != undefined
                    ? processingDashboardParam.performedBy
                    : performedBy,
              }}
            >
              {row[item]}
            </Link>
          ),
          editable: false,
        });
      } else if (
        item.toString().toLowerCase() === "workitemid" &&
        task === "undo activity"
      ) {
        col.push({
          title: item,
          field: item,
          render: (row) => (
            <a onClick={() => showUndoChangesModal(row)}>{row[item]}</a>
          ),
          editable: false,
        });
      } else {
        col.push({ title: item, field: item });
      }
    });
    //col.pop();
    setcolumns(col);
  };

  const commentForUndoStatusChange = (e) => {
    setUndoStatusComment(e.target.value);
  };

  const updateLastActivityColumns = (keys) => {
    let col = [];
    keys.forEach((item) => {
      col.push({ title: item, field: item });
    });
    //col.pop();
    setLastActivityColumns(col);
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });
  const handletaskchange = (e) => {
    //MarkRef.current.value=0;
    setShowBulkEdit(false);
    setDashboardGridDataModels([]);
    setBulkEditDisable(true);
    setExportExcelDisable(true);
    //setdisableStatusChange(true);
    let taskselected = e.target.value;
    settask(taskselected);

    if (
      taskselected === "adhocediting" ||
      taskselected === "workflow state change" ||
      taskselected === "reassignment"
    ) {
      setShowMark(true);
    } else {
      setShowMark(false);
    }
    //if(taskselected !="adhocediting")
    if (taskselected !== "adhocediting") {
      setBulkEditDisable(true);
    }
    if (taskselected === "workflow state change") {
      setAllowStatusChange(true);
      setdisableStatusChange(false);
      //  workItemStatuses(processingDashboardParam.selectedSubprocess);
      getEmployeesToAssign();
    }
    if (taskselected === "reassignment") {
      setallowAssignment(true);
      setdisableStatusChange(false);
      getReAssignConfig();
      setTableSelection(true);
    }
    if (taskselected != "activities") {
      setTableSelection(true);
    }
  };
  const assignEmployee = (e) => {
    e.preventDefault();
    let workItemIds = selectedCheckboxDetails
      .map((item) => item.WorkItemID)
      .join(",");
    let body = {
      workItemIDs: workItemIds,
      BatchItemIDs: workItemIds,
      AssignmentXml: selectedEmployee,
      personId: sessionStorage.getItem("PersonID"),
      details: assignmentdict,
    };

    let loginUrl = getAPIURL(REACT_APP_SAVEMANAGEWORKITEMREASSIGNMENT);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          alert(result.data);
          FetchSearchData(
            sessionStorage.getItem("EmployeeVersionID"),
            filterValue
          );
          settingassignClose();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Reassigment Failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const settingClose = () => {
    settingShow(false);
  };
  const settingassignClose = () => {
    settingassignShow(false);
  };
  const { REACT_APP_GETWORKFLOWITEMFIELDS } = process.env;
  const [advCondition, setadvCondition] = useState("");
  const [advSearchCondition, setadvSearchCondition] = useState("");
  // const [numericOperator, setnumericOperator] = useState(false);
  const [isDateField, setisDateField] = useState(false);
  const [advSearchText, setadvSearchText] = useState("");
  const [WorkFlowWorkItemFields, setWorkFlowWorkItemFields] = useState([]);
  let advanceColumns = [
    { title: "Search Field", field: "searchField" },
    { title: "Condition", field: "condition" },
    { title: "Search Text", field: "searchText" },
  ];
  const showAdvanceSearch = () => {
    setviewSearchModal(true);
  };

  const showUndoChangesModal = (rowData) => {
    setViewUndoChangesModal(true);
    setWorkitemIdForUndoActivity(rowData.WorkItemID);

    let body = {
      workItemID: rowData.WorkItemID,
      subprocessID: processingDashboardParam.selectedSubprocess,
      personID: sessionStorage.getItem("PersonID"),
      ActivityID: ViewTypeSelected,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHPREVIOUSACTIVITYFORWORKITEM);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          updateLastActivityColumns(
            Object.keys(result.data._listworkitemgridData[0])
          );
          setLastActivityDetails(result.data._listworkitemgridData);
          setLastWorkitemStatus(
            result.data.lstCurrentWorkFlowState[0].currentStatus
          );
          setLastActivityName(
            result.data.lstCurrentWorkFlowState[0].currentActivity
          );

          setLastWorkitemStatusID(
            result.data.lstPreviousWorkFlowState[0].previousStatusID
          );
          setLastActivityNameID(
            result.data.lstPreviousWorkFlowState[0].previousActivityID
          );
          setLastActivityStateID(
            result.data.lstPreviousWorkFlowState[0].previousWorkFlowStateID
          );

          setUndoWorkitemStatus(
            result.data.lstPreviousWorkFlowState[0].previousStatus
          );
          setUndoActivityName(
            result.data.lstPreviousWorkFlowState[0].previousActivity
          );
        } else if (result.status === 401) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const handelSubmitAndContinue = (e) => {
    set_IsLoading(true);
    e.preventDefault();
    let inputalues = [];
    let isSubmit = true;
    setstatusComment("");
    if (JsonValues.length >= 0) {
      JsonValues.forEach((element) => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            element.screenFields[i].value === ""
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true,
            });
            isSubmit = false;
          } else if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          }
        }
      });
    } else {
      JsonValues.state.regGroup.forEach((element) => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            element.screenFields[i].value === ""
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true,
            });
            isSubmit = false;
          } else if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          }
        }
      });
    }
    try {
      if (isSubmit) {
        let body = {
          selectedMultipleWorkitems: "13217567",
          From_Date: "11/1/2018 12:00:00 AM",
          personID: +sessionStorage.getItem("PersonID"),
          inputValues: inputalues,
        };

        let loginUrl = getAPIURL(REACT_APP_SAVEDATAFORMULTIPLEWORKITEMS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status === 200) {
              addToast("Something went wrong!", {
                appearance: "error",
                autoDismiss: true,
              });

              // Alert("Data saved successfully");
              set_IsLoading(false);
            } else if (result.status === 500) {
              set_IsLoading(false);
              let errorMessage = result.Message;
              addToast(errorMessage, {
                appearance: "info",
                autoDismiss: true,
              });
            } else if (result.status === 401) {
              //console.log("ERROR: ", error);
              navigate("/");
            } else {
              addToast("Error occured!", {
                appearance: "error",
                autoDismiss: true,
              });
              set_IsLoading(false);
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //navigate("/");
            // }
          });
      } else {
        set_IsLoading(false);
      }
    } catch (ex) {
      set_IsLoading(false);
      addToast("Error occured!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const updateWorkitemToPreviousActivity = () => {
    const confirmBox = window.confirm(
      "Are you sure you want to undo workitem -" +
        WorkitemIdForUndoActivity +
        " to its last activity?"
    );
    if (confirmBox === true) {
      let body = {
        workItemID: WorkitemIdForUndoActivity,
        subprocessID: processingDashboardParam.selectedSubprocess,
        personID: sessionStorage.getItem("PersonID"),
        ActivityID: ViewTypeSelected,
        newStateID: LastActivityStateID,
        newStatus: LastWorkitemStatusID,
        newActivityId: LastActivityNameID,
        isForcedStateChange: true,
        statusComment: UndoStatusComment,
      };

      let loginUrl = getAPIURL(REACT_APP_UPDATEWORKITEMTOPREVIOUSACTIVITY);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          UserName: sessionStorage.getItem("UserName"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            alert("Successful");
            CloseUndoChangesModal();
          } else if (result.status === 401) {
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  const CloseUndoChangesModal = () => {
    setViewUndoChangesModal(false);
  };

  const viewSearchCloseAndRest = () => {
    setviewSearchModal(false);
    setadvSearchMapToFieldDetails([]);
    setadvSearchField("");
    setadvSearchMapToField("");
    setadvCondition("");
    //setadvSearchText("");
    setadvSearchCondition("");
    //setsearchWorkitemData([]);
    setadvSearchDetails([]);
  };
  const getAdvanceField = (e) => {
    // setnumericOperator(false);
    setisDateField(false);
    setadvSearchText("");

    let datetimeValue = e.target.value.split("/")[0].includes("DateTime");
    let intValue = e.target.value.split("/")[0].includes("Int");
    let DecimalValue = e.target.value.split("/")[0].includes("Decimal");
    let SLADueDateValue = e.target.value.split("/")[0].includes("SLADueDate");
    let ScanDateValue = e.target.value.split("/")[0].includes("ScanDate");
    let UploadDateValue = e.target.value.split("/")[0].includes("UploadDate");
    if (
      datetimeValue > 0 ||
      intValue > 0 ||
      DecimalValue > 0 ||
      SLADueDateValue > 0 ||
      ScanDateValue > 0 ||
      UploadDateValue > 0
    ) {
      // setnumericOperator(true);
      setadvCondition("=");
    } else {
      setadvCondition("Equals");
    }
    if (datetimeValue > 0) {
      setisDateField(true);
    }

    setadvSearchField(e.target.value.split("/")[1]);
    setadvSearchMapToField(e.target.value.split("/")[0]);
  };
  const getAdvanceCondition = (e) => {
    setadvCondition(e.target.value);
  };
  const addAdvanceSearchConditions = () => {
    //setadvSearchText()
    // alert(advSearchField+' ' +advCondition+' '+SearchTextRef.current.value);
    // alert(advSearchMapToField+' ' +advCondition+' '+SearchTextRef.current.value);
    if (
      advSearchField != "" &&
      advCondition != "" &&
      SearchTextRef.current.value != ""
    ) {
      let condition = {
        searchField: advSearchField,
        condition: advCondition,
        searchText: SearchTextRef.current.value,
      };
      let mapToCondition = {
        searchField: advSearchMapToField,
        condition: advCondition,
        searchText: SearchTextRef.current.value,
      };
      setadvSearchDetails((advSearchDetails) => [
        ...advSearchDetails,
        condition,
      ]);
      setadvSearchMapToFieldDetails((advSearchMapToFieldDetails) => [
        ...advSearchMapToFieldDetails,
        mapToCondition,
      ]);
    } else {
      alert("Enter proper details.");
    }
  };
  const handleRowDelete = (oldData, resolve) => {
    const dataDelete = [...advSearchDetails];
    const mapDataDelete = [...advSearchMapToFieldDetails];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    mapDataDelete.splice(index, 1);
    setadvSearchDetails([...dataDelete]);
    setadvSearchMapToFieldDetails([...mapDataDelete]);
    resolve();
  };
  const viewSearchClose = () => {
    setviewSearchModal(false);
  };
  const getAdvanceSearchField = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWITEMFIELDS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setWorkFlowWorkItemFields(result.data._listWorkItemFields);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const assignToEmployee = (data) => {
    if (task === "workflow state change") {
      settingShow(true);
      workItemStatusesForModal();
    }
    if (task === "reassignment") {
      settingassignShow(true);
    }

    setselectedCheckboxDetails(data);
  };
  const viewWorkitemShow = (item) => {
    setviewWorkitemModal(true);
    getSelectedWorkItem(item.WorkItemID);
  };
  const MarkDemo = (e) => {
    return e;
  };
  // const markChanged=()=>{
  //   handleSetSelectedRows(e);
  // }
  const handleSetSelectedRows = (e) => {
    //setSelectedRows(e);

    setselectedItems([]);
    if (e.length > 1) {
      if (selectedTask === "adhocediting" || task === "adhocediting") {
        if (TaskRef.current.value === "adhocediting") {
          setShowBulkEdit(true);
          setBulkEditDisable(false);
        } else {
          setShowBulkEdit(false);
          setBulkEditDisable(true);
        }
      } else {
        setShowBulkEdit(false);
        setBulkEditDisable(true);
      }
    } else {
      setShowBulkEdit(false);
      setBulkEditDisable(true);
    }
    setBulkWorkItemsToEdit(e);
  };
  const viewWorkitemClose = () => {
    setworkitemHistory([]);
    setworkitemDetails([]);
    setworkItemFieldsModel([]);
    setworkItemDetailModel([]);
    setviewWorkitemModal(false);
  };

  const getCurrentState = (e) => {
    processingDashboardParam.currentAcitvity = null;
    setActivityCurrent(e.target.value);
    setDashboardGridDataModels([]);
    setBulkEditDisable(true);
    setExportExcelDisable(true);

    if (
      e.target.value.split("/")[1] == "Complete" ||
      e.target.value.split("/")[1] == "Exceptions" ||
      e.target.value.split("/")[1] == "Exception"
    ) {
      setcurrentStateID(CurrentStateID_Client);
      getWorkFlowstatus(CurrentStateID_Client);
      sessionStorage.setItem(
        "currentStateIDForEditWorkItem",
        CurrentStateID_Client
      );
    } else {
      setcurrentStateID(e.target.value.split("/")[0]);
      getWorkFlowstatus(e.target.value.split("/")[0]);
      sessionStorage.setItem(
        "currentStateIDForEditWorkItem",
        e.target.value.split("/")[0]
      );
    }

    if (
      e.target.value.split("/")[1] == "Exceptions" ||
      e.target.value.split("/")[1] == "Complete" ||
      e.target.value.split("/")[1] == "Client" ||
      e.target.value.split("/")[1] == "Exception"
    ) {
      if (e.target.value.split("/")[1] == "Exceptions") {
        setcurrentState("Exception");
        sessionStorage.setItem("currentStateForEditWorkItem", "Exception");
      } else {
        setcurrentState(e.target.value.split("/")[1]);
        sessionStorage.setItem(
          "currentStateForEditWorkItem",
          e.target.value.split("/")[1]
        );
      }
    } else {
      setcurrentState("");
      sessionStorage.setItem("currentStateForEditWorkItem", "");
    }
    //getCurrentStatus(currentStateID);
  };
  const handleunassignedcheckbox = (e) => {
    setshowonlyassigned(e.target.checked);
    sessionStorage.setItem("showonlyassignedForEditWorkItem", e.target.checked);
  };
  const getCurrentStatus = (e) => {
    setDashboardGridDataModels([]);
    setBulkEditDisable(true);
    setExportExcelDisable(true);
    //setcurrentStatusID(+e.target.value.split("/")[0]);
    //setcurrentStatus(e.target.value.split("/")[1]);
    sessionStorage.setItem(
      "currentStatusIDForEditWorkItem",
      +e.target.value.split("/")[0]
    );
    if (+e.target.value.split("/")[0] === 0) {
      sessionStorage.setItem(
        "currentStatusForEditWorkItem",
        "-- Select Current Status --"
      );
    } else
      sessionStorage.setItem(
        "currentStatusForEditWorkItem",
        e.target.value.split("/")[1]
      );
  };
  const getSelectedEmployee = (e) => {
    processingDashboardParam.currentlyWith = null;
    setEmployee(intEmployeeIDRef.current.value);
    setDashboardGridDataModels([]);
    setBulkEditDisable(true);
    setExportExcelDisable(true);
  };
  const getSelectedPerformedBy = (e) => {
    processingDashboardParam.performedBy = null;
    setPerformedBy(e.target.value);
    setDashboardGridDataModels([]);
    setBulkEditDisable(true);
    setExportExcelDisable(true);
  };
  const getConfiguredActivities = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(REACT_APP_GETCONFIGUREDACTIVITIESFORSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setConfiguredActivities(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getConfiguredViewType = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(REACT_APP_GETCONFIGUREDVIEWTYPEFORSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setConfiguredViewType(result.data);
        } else if (result.status === 401) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getWorkFlowstates = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWSTATES);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setWorkFlowStates(result.data._listAllStates);
          for (let i = 0; i < result.data._listAllStates.length; i++) {
            if (result.data._listAllStates[i].nextState == "Client") {
              setCurrentStateID_Client(
                result.data._listAllStates[i].workflowstateid
              );
            }
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const statusForStatusChange = (e) => {
    setisComment(false);

    let statusselected = e.target.value.split("|")[0];
    let StatusCommentMandatory = 0;

    if (e.target.value !== "Select") {
      let activityselected = e.target.value
        .split("(")[1]
        .replace(")", "")
        .trim();
      setselectedActivityName(activityselected);
    } else {
      //alert("Select Status");
    }

    if (StatusCommentMandatory === "1") {
      setisComment(true);
    }

    setstatusChange(statusselected);
  };
  const getWorkFlowstatus = (selectedStateId) => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      CurrentStateID:
        ActivityCurrent.split("/")[0] != null &&
        ActivityCurrent.split("/")[0] === undefined
          ? ActivityCurrent.split("/")[0]
          : selectedStateId,
    };
    let loginUrl = getAPIURL(
      REACT_APP_GET_STATUSESFORCURRENTWORKFLOWSTATEMANEGEWORKITEM
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setWorKFlowStatus(result.data._listStatus);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const workItemStatusesForModal = () => {
    let body = {
      workflowStateID: currentStateID,
      subProcessID: processingDashboardParam.selectedSubprocess,
    };

    let loginUrl = getAPIURL(REACT_APP_GET_STATUSESFORCURRENTWORKFLOWSTATE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setworkItemStatus(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getEmployeesToAssign = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_EMPLOYEESFORSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setemployeesToAssign(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getReAssignConfig = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userID: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_GETMANAGEWORKITEMREASSIGNCONFIG);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setreassignconfig(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const FetchSearchDataForEdit = (employeeVersionID, filterValue) => {
    updateColumns([]);
    set_isLoadingDashboard(true);
    let mode = "";
    //TaskRef
    //getWorkFlowstates();

    console.log(processingDashboardParam.selectedTask + "~SelectedTask!");
    console.log(intEmployeeIDRef.current.value);
    //CurrentActivityRef
    console.log(processingDashboardParam.currentAcitvity + "~CurrentActivity!");
    //CurrentActivityRef.current.value = processingDashboardParam.currentAcitvity.split("/")[0];
    //console.log(CurrentActivityRef.current.value);

    let currentStateForEditWorkItem =
      sessionStorage.getItem("currentStateForEditWorkItem") != null
        ? sessionStorage.getItem("currentStateForEditWorkItem")
        : "";

    let currentStateIDForEditWorkItem = "0";
    currentStateIDForEditWorkItem =
      sessionStorage.getItem("currentStateIDForEditWorkItem") != null
        ? sessionStorage.getItem("currentStateIDForEditWorkItem")
        : "0";

    let currentStatusForEditWorkItem = "";
    currentStatusForEditWorkItem =
      sessionStorage.getItem("currentStatusForEditWorkItem") != null
        ? sessionStorage.getItem("currentStatusForEditWorkItem")
        : "";

    let currentStatusIDForEditWorkItem = "";
    currentStatusIDForEditWorkItem =
      sessionStorage.getItem("currentStatusIDForEditWorkItem") != null
        ? sessionStorage.getItem("currentStatusIDForEditWorkItem")
        : "";

    let showonlyassignedForEditWorkItem = false;
    showonlyassignedForEditWorkItem =
      sessionStorage.getItem("showonlyassignedForEditWorkItem") != null
        ? sessionStorage.getItem("showonlyassignedForEditWorkItem")
        : false;

    let ActivitiesSelectedForEditWorkItem = "";
    ActivitiesSelectedForEditWorkItem =
      sessionStorage.getItem("ActivitiesSelectedForEditWorkItem") != null
        ? sessionStorage.getItem("ActivitiesSelectedForEditWorkItem")
        : "";

    if (currentStateForEditWorkItem == "Client") mode = "Client";
    else if (currentStateForEditWorkItem == "Complete") mode = "Complete";
    else if (currentStateForEditWorkItem == "Exceptions") mode = "Exception";
    let filtercriteria = "";

    if (filterValue > 0) {
      filtercriteria =
        "wi.UploadDate between dateadd(day," +
        filterValue +
        ",getdate()) and getdate()";
    } else {
      filtercriteria = "";
    }

    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    let condition = sessionStorage.getItem(
      "" + workflowactivityName + "_mapToCondition"
    );
    let mapToCondition = sessionStorage.getItem(
      "" + workflowactivityName + "_condition"
    );
    let sortColumn = sessionStorage.getItem(
      "" + workflowactivityName + "_sortColumn"
    );

    if (mapToCondition != null) {
      setadvSearchDetails(JSON.parse(mapToCondition));
    } else {
      setadvSearchDetails([]);
    }

    if (condition != null) {
      setadvSearchMapToFieldDetails(JSON.parse(condition));
    } else {
      setadvSearchMapToFieldDetails([]);
    }
    //if(applysearch){
    let body = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      activityID: selectedViewType,
      currentStateID: currentStateIDForEditWorkItem,
      subProcessWorkflowConfigID: currentStatusID.toString(),
      //employeeID: intEmployeeIDRef.current.value,
      employeeID:
        processingDashboardParam.currentlyWith != null &&
        processingDashboardParam.currentlyWith != undefined
          ? processingDashboardParam.currentlyWith
          : employee,
      filterCriteria: advSearchMapToFieldDetails,
      subProcessID: processingDashboardParam.selectedSubprocess,
      isUnassigned: showonlyassignedForEditWorkItem,
      task: taskselected === "" ? "" : taskselected,
      activity: ActivitiesSelectedForEditWorkItem,
      //processEmployeeID: performedByEmployeeIDRef.current.value,
      processEmployeeID:
        processingDashboardParam.performedBy != null &&
        processingDashboardParam.performedBy != undefined
          ? processingDashboardParam.performedBy
          : performedBy,
      mode: mode,
      userDefinedStatus: currentStatusForEditWorkItem,
    };

    let loginUrl = getAPIURL(REACT_APP_GETCOMPREHENSIVESEARCH);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          filterShow(false);
          if (result.data.length > 0) {
            // Commented by Mahinder due to loop doesn't have any working
            // result.data.map((item) => {
            //   // if()
            // });
            updateColumns(Object.keys(result.data[0]));
            setShowMark(true);
            setDashboardGridDataModels(result.data);
            setExportExcelDisable(false);
          } else {
            updateColumns([]);
            setDashboardGridDataModels([]);
            setExportExcelDisable(true);
          }

          set_isLoadingDashboard(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          navigate("/");
        } else {
          set_isLoadingDashboard(false);
          addToast("Something went wrong!", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
    //  }
    //else setDashboardGridDataModels([])
  };
  const FetchSearchData = (employeeVersionID, filterValue) => {
    setDashboardGridDataModels([]);
    if (task == "0" || task == "" || task === undefined) {
      alert("Please Select Task.");
      return false;
    }
    if (
      uplodadedDateTimeFrom == "" ||
      uplodadedDateTimeFrom === undefined ||
      uplodadedDateTimeTo == "" ||
      uplodadedDateTimeTo === undefined
    ) {
      alert("Please Select From.");
      return false;
    }
    if (ViewTypeSelected == "" || ViewTypeSelected === undefined) {
      alert("Please Select Type.");
      return false;
    }
    devRef.current.focus();
    set_isLoadingDashboard(true);
    let activity = "";
    let mode = "";
    if (
      task === "workflow state change" &&
      (currentStateID === 0 || currentStateID === "0")
    ) {
      alert("Current Activity is required for this task");
      setDashboardGridDataModels([]);
      //  updateColumns([]);
      set_isLoadingDashboard(false);
    } else if (
      task === "reassignment" &&
      (currentStateID === 0 || currentStateID === "0")
    ) {
      alert("Current Activity is required for this task");
      setDashboardGridDataModels([]);
      set_isLoadingDashboard(false);
    } else {
      if (currentState == "Client") mode = "Client";
      else if (currentState == "Complete") mode = "Complete";
      else if (currentState == "Exceptions") mode = "Exception";
      let filtercriteria = "";

      if (filterValue > 0) {
        filtercriteria =
          "wi.UploadDate between dateadd(day," +
          filterValue +
          ",getdate()) and getdate()";
      } else {
        filtercriteria = "";
      }

      let workflowactivityName = sessionStorage.getItem("workflowactivityName");
      let condition = sessionStorage.getItem(
        "" + workflowactivityName + "_mapToCondition"
      );
      let mapToCondition = sessionStorage.getItem(
        "" + workflowactivityName + "_condition"
      );
      let sortColumn = sessionStorage.getItem(
        "" + workflowactivityName + "_sortColumn"
      );

      if (mapToCondition != null) {
        setadvSearchDetails(JSON.parse(mapToCondition));
      } else {
        setadvSearchDetails([]);
      }

      let currentworkflowStateIDForEditWorkItem = "0";
      currentworkflowStateIDForEditWorkItem =
        sessionStorage.getItem("currentStateIDForEditWorkItem") != null
          ? sessionStorage.getItem("currentStateIDForEditWorkItem")
          : "0";

      if (condition != null) {
        setadvSearchMapToFieldDetails(JSON.parse(condition));
      } else {
        setadvSearchMapToFieldDetails([]);
      }
      //setSortColumn(sortColumn);
      //if(applysearch){
      let body = {
        fromDate: uplodadedDateTimeFrom,
        toDate: uplodadedDateTimeTo,
        // fromDate: selectedFromDate,
        // toDate: selectedToDate,
        activityID: ViewTypeSelected,
        currentStateID:
          currentStateID.toString() == "0"
            ? currentworkflowStateIDForEditWorkItem
            : currentStateID.toString(),
        subProcessWorkflowConfigID: currentStatusID.toString(),
        employeeID: intEmployeeIDRef.current.value,
        filterCriteria: advSearchMapToFieldDetails,
        subProcessID: processingDashboardParam.selectedSubprocess,
        isUnassigned: showonlyassigned,
        task: task === "" ? "activities" : task,
        activity: ActivitiesSelected,
        processEmployeeID: performedByEmployeeIDRef.current.value,
        mode: mode,
        userDefinedStatus:
          currentStatus != null ? currentStatus : "-- Select Current Status --",
      };

      let loginUrl = getAPIURL(REACT_APP_GETCOMPREHENSIVESEARCH);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            filterShow(false);
            if (result.data.length > 0) {
              result.data.map((item) => {
                // if()
              });
              debugger;
              updateColumns(Object.keys(result.data[0]));
              setDashboardGridDataModels(result.data);
              //   const arrayWithoutExtraColumn = result.data.filter(function (data) {
              //     debugger;
              //     return data !== 'tableData';
              // });
              //this.dashboardGridDataForExcel.push(result.data);

              setExportExcelDisable(false);
            } else {
              updateColumns([]);
              setDashboardGridDataModels([]);
              setExportExcelDisable(true);
            }

            set_isLoadingDashboard(false);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something went wrong!", {
              appearance: "error",
              autoDismiss: true,
            });
            set_isLoadingDashboard(false);
          }
        })
        .catch((error) => {
          addToast("Something went wrong!", {
            appearance: "error",
            autoDismiss: true,
          });
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
    //else setDashboardGridDataModels([])}
  };
  const getSelectedWorkItem = (workItemId) => {
    let _dateWithTime = uplodadedDateTimeFrom + " 12:00:00 AM";
    let body = {
      workItemID: workItemId,
      subprocessid: processingDashboardParam.selectedSubprocess,
      personid: sessionStorage.getItem("PersonID"),
      dateFrom: _dateWithTime,
    };
    let loginUrl = getAPIURL(REACT_APP_GET_WORKITEMDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setworkitemDetails(result.data.asignmentdetails);
          setworkitemHistory(result.data.listWorkItemHistory);
          setworkItemDetailModel(result.data.workItemDetails);
          setworkItemFieldsModel(result.data.workItemFieldsDetails);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const TaskRef = React.useRef();
  const DateFromRef = React.useRef();
  const CurrentActivityRef = React.useRef();
  const WorkFlowStatusRef = React.useRef();
  const ActivitiesRef = React.useRef();
  const CheckboxRef = React.useRef(false);

  const resetForm = () => {
    // Reset value in state
    processingDashboardParam.selectedTask = null;
    processingDashboardParam.fromDate = null;
    processingDashboardParam.toDate = null;
    processingDashboardParam.viewType = null;
    processingDashboardParam.selectedTask = null;
    processingDashboardParam.currentAcitvity = null;
    processingDashboardParam.currentWorkItemStatus = null;
    processingDashboardParam.currentlyWith = null;
    processingDashboardParam.performedBy = null;

    sessionStorage.setItem(
      "currentStateIDForEditWorkItem",
      '0'
    );

    setDashboardGridDataModels([]);

    setshowonlyassigned(false);
    //handleReset();
    setuplodadedDateTimeFrom("");
    setuplodadedDateTimeTo("");
    settask("");
    setViewTypeSelected("");
    setselectedCurrentStatus("");
    setShowMark(false);
    setShowBulkEdit(false);
    setExportExcelDisable(true);
    setapplyReset(true);

    TaskRef.current.value = 0;
    CurrentActivityRef.current.value = 0;
    ActivitiesRef.current = 0;
    WorkFlowStatusRef.current.value = 0;
    //DateFromRef.current.value = "";
    DateFromRef.current.setStartDate({});
    DateFromRef.current.setEndDate({});
    intEmployeeIDRef.current.value = 0;
    performedByEmployeeIDRef.current.value = 0;
    CheckboxRef.current.checked = false;
    //WorkFlowStatusRef.current.value =0;
  };
  const downloadExcel = () => {
    const colToRemove = new Set(["tableData"]);
    const sheetData = ArrayWithoutExtraColumn(
      dashboardGridDataModels,
      colToRemove
    );
    DownloadExcel(sheetData, "ManageWorkItem");
  };
  const TaskDropdown = [
    { value: "workflow state change", title: "Change workitem(s) status" },
    { value: "adhocediting", title: "Edit workitem field values" },
    { value: "reassignment", title: "Reassign workitem(s)" },
    { value: "undo activity", title: "Undo Last Activity" },
    { value: "activities", title: "View workitem(s)" },
  ];
  useEffect(() => {
    console.log("in manage");
    console.log("processingDashboardParam", processingDashboardParam);
    settask("");
    getWorkFlowstates();
    getConfiguredActivities();
    getConfiguredViewType();
    setTableSelection(false);
    getWorkFlowstatus();
    getAdvanceSearchField();
    getEmployeesToAssign();

    if (currentactivityName != undefined) {
      sessionStorage.setItem("CurrentActivityName", currentactivityName);
      setActivityCurrent(sessionStorage.getItem("CurrentActivityName"));

      let currentActivityID = currentactivityName.split("/")[0]
      getWorkFlowstatus(currentActivityID);
    }

    sessionStorage.setItem("SelectedEmployee", employee);
    sessionStorage.setItem("TaskSelected", selectedTask);
    settaskselected(sessionStorage.getItem("TaskSelected"));
    if (
      processingDashboardParam.currentlyWith != null &&
      processingDashboardParam.currentlyWith != undefined
    ) {
      setEmployee(processingDashboardParam.currentlyWith);
    }
    if (
      processingDashboardParam.performedBy != null &&
      processingDashboardParam.performedBy != undefined
    ) {
      setPerformedBy(processingDashboardParam.performedBy);
    }

    //sessionStorage.setItem("WorkItemCurrentStatus",WorkItemCurrentStatus);
    setselectedCurrentStatus(WorkItemCurrentStatus);
    setViewTypeSelected(
      selectedViewType == ""
        ? processingDashboardParam.ViewType
        : selectedViewType
    );
    settask(selectedTask);
    setuplodadedDateTimeFrom(processingDashboardParam.fromDate);
    setuplodadedDateTimeTo(processingDashboardParam.toDate);
    if (
      processingDashboardParam.fromDate != null &&
      processingDashboardParam.toDate != null
    ) {
      //DateFromRef.current.setStartDate(processingDashboardParam.fromDate);
      //DateFromRef.current.setEndDate(processingDashboardParam.toyDate);
    }

    if (
      selectedFromDate != undefined &&
      selectedToDate != undefined &&
      selectedViewType != undefined &&
      selectedTask != undefined
    ) {
      FetchSearchDataForEdit(sessionStorage.getItem("EmployeeVersionID"), 0);
    }
  }, []);
  const NavElements = useMemo(() => {
    return (
      <>
        <Header
          onpreferenceShow={null}
          pageName="Manage Work Items"
          timeCaptureActivityName="Work Item Management"
        />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav selectedMenu="activities" />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  return (
    <>
      {NavElements}
      <div className="centerSection">
        <div className="pageBody ">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Task</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={handletaskchange}
                      //defaultValue={taskselected != "" ? taskselected : ""}
                      ref={TaskRef}
                    >
                      <option value={0}>Select Task</option>
                      {TaskDropdown.map((task) => (
                        <option
                          value={task.value}
                          // selected = {sessionStorage.getItem("CurrentActivityName")!= null?
                          // sessionStorage.getItem("CurrentActivityName").split("/")[1] :""}
                          //selected = {taskselected!=""?taskselected:""}
                          selected={
                            task.value === processingDashboardParam.selectedTask
                              ? true
                              : false
                          }
                        >
                          {task.title}
                        </option>
                      ))}

                      {/* <option value="workflow state change">
                        Change workitem(s) status
                      </option>
                      <option value="adhocediting">
                        Edit workitem field values
                      </option>
                      <option value="reassignment">Reassign workitem(s)</option>
                      <option value="undo activity">Undo Last Activity</option>
                      <option selected="selected" value="activities">
                        View workitem(s)
                      </option> */}
                    </select>
                  </div>
                </div>
                {/** <div className="col-12 pb-2"></div> **/}

                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label"> From</label>
                    <DateRangePicker
                      initialSettings={{
                        autoApply: false,
                        autoUpdateInput: false,
                        opens: "right",
                        timePicker: false,
                        showDropdowns: true,
                        locale: { format: "M/DD/YY" },
                        editable: false,
                      }}
                      ref={DateFromRef}
                      onApply={handleApplyUpload}
                      onCancel={handleReset}
                      onShow={handleEvent}
                      //onEvent={handleEvent}
                    >
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Select Date"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        //ref={DateFromRef}
                        // defaultValue={selectedFromDate +"-"+ selectedToDate}
                        Value={
                          uplodadedDateTimeFrom !== undefined ||
                          uplodadedDateTimeTo !== undefined
                            ? uplodadedDateTimeFrom + "-" + uplodadedDateTimeTo
                            : "" + "-" + ""

                          // ((selectedFromDate !== uplodadedDateTimeFrom ||
                          // selectedToDate !== uplodadedDateTimeTo)&&(selectedFromDate != undefined ||
                          //   selectedToDate != undefined) )
                          //   ? selectedFromDate + "-" + selectedToDate
                          //   : uplodadedDateTimeFrom + "-" + uplodadedDateTimeTo
                        }
                      />
                    </DateRangePicker>
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Current Activity</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={getCurrentState}
                      ref={CurrentActivityRef}
                    >
                      <option value={0}>Select</option>
                      {WorKFlowStates.map((state) => (
                        <option
                          value={state.workflowstateid + "/" + state.nextState}
                          // selected = {sessionStorage.getItem("CurrentActivityName")!= null?
                          // sessionStorage.getItem("CurrentActivityName").split("/")[1] :""}
                          selected={
                            state.workflowstateid + "/" + state.nextState ==
                            ActivityCurrent
                          }
                        >
                          {state.nextState}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">
                      Current Workitem Status
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={getCurrentStatus}
                      ref={WorkFlowStatusRef}
                    >
                      <option value={0}>Select</option>
                      {WorKFlowStatus.map((status) => (
                        <option
                          value={
                            status.subProcessWorkflowConfigID +
                            "/" +
                            status.statusName
                          }
                          // selected = {(status.subProcessWorkflowConfigID+
                          //   "/" +
                          //   status.statusName) == selectedCurrentStatus}
                          selected={
                            status.subProcessWorkflowConfigID + "/" + status.statusName == WorkItemCurrentStatus
                          }
                        >
                          {status.statusName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Currently With</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={getSelectedEmployee}
                      ref={intEmployeeIDRef}
                    >
                      <option value={0}>--Select Employee--</option>
                      {employeesToAssign.map((e) => (
                        <option
                          value={e.employeeID}
                          selected={e.employeeID == employee}
                        >
                          {e.empName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Activities</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        setActivitiesSelected(e.target.value);
                        sessionStorage.setItem(
                          "ActivitiesSelectedForEditWorkItem",
                          e.target.value
                        );
                      }}
                      ref={ActivitiesRef}
                    >
                      <option value ={0}>Select</option>
                      {ConfiguredActivities.map((activity) => (
                        <option value={activity.activityID}>
                          {activity.activityName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Performed By</label>
                    <select
                      className="form-control form-control-sm"
                      ref={performedByEmployeeIDRef}
                      onChange={getSelectedPerformedBy}
                    >
                      <option value={0}>--Select Employee--</option>
                      {employeesToAssign.map((e) => (
                        <option
                          value={e.employeeID}
                          selected={e.employeeID == performedBy}
                        >
                          {e.empName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <div className="form-check form-check-inline ms-0 mx-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        ref={CheckboxRef}
                        onChange={handleunassignedcheckbox}
                      />
                      <label className="form-check-label" for="inlineCheckbox1">
                        Show only unassigned workitems
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary ms-2"
                      onClick={showAdvanceSearch}
                    >
                      <b className="icon-edit"></b> Advance Search
                    </button>
                  </div>
                </div>
              </div>

              {/* <hr className="mb-1" /> */}
              {/* <div className="fieldSet pt-1">
                <h4 className="mb-4">Advanced Search Criteria</h4>
                <div className="row align-items-end">
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Search field </label>
                      <select
                        className="form-control form-control-sm"
                        title="Select a field to apply filter"
                      >
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Condition </label>
                      <select
                        className="form-control form-control-sm"
                        title="Select a field to apply filter"
                      >
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Search Text</label>
                      <input className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="mb-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary w-100 text-uppercase"
                      >
                        {" "}
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}

              <hr className="mb-1" />
              <div className="fieldSet pt-1">
                <div className="mb-2 text-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    onClick={resetForm}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      FetchSearchData(
                        sessionStorage.getItem("EmployeeVersionID"),
                        0
                      );
                    }}
                    className="btn btn-sm btn-primary ms-3"
                  >
                    Apply Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="SearchResult" ref={devRef} className="dashBoxs">
            <div className="dashBoxs-header" style={{ maxHeight: "45px" }}>
              <div className="row align-items-center">
                <div className="col-3"></div>
                <div className="col-9">
                  <div
                    className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                    style={{ marginTop: "-8px" }}
                  >
                    <div className="d-inline" style={{ paddingBottom: "4px" }}>
                      <div className="input-group input-group-sm">
                        <label
                          className="input-group-text"
                          for="inputGroupSelect02"
                        >
                          Type
                        </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            setViewTypeSelected(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {ConfiguredViewType.map((ViewType) => (
                            <option
                              value={ViewType.activityID}
                              selected={
                                ViewType.activityID == "" + ViewTypeSelected
                              }
                            >
                              {ViewType.column1}
                            </option>
                          ))}
                        </select>
                        {!ExportExcelDisable && ShowMark ? (
                          <>
                            <input
                              type="text"
                              className="form-control form-control-sm ms-2"
                              placeholder="Row Count"
                              ref={MarkRef}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-primary ms-2"
                              onClick={AssignWorkitems}
                            >
                              <b></b>Mark
                            </button>
                          </>
                        ) : null}
                        {ShowBulkEdit ? (
                          <button
                            //disabled={BulkEditDisable}
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                              editworkItemDetails();
                            }}
                          >
                            Bulk Edit
                          </button>
                        ) : null}
                        {!ExportExcelDisable ? (
                          <button
                            type="button"
                            disabled={ExportExcelDisable}
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                              downloadExcel();
                            }}
                          >
                            <b></b>Export
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoadingDashboard ? (
              <LoadingSpinnerFigma />
            ) : (
              <>
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table
                      className="table table-borderless MatTable"
                      style={{ zIndex: "1", position: "relative" }}
                    >
                      <ThemeProvider theme={theme}>
                        <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {
                              // nRowsSelected: '{0} row(s) selected'
                            },
                            header: {
                              actions: "",
                            },
                          }}
                          title={""}
                          actions={[
                            {
                              icon: () => <FcSearch />,
                              tooltip: "View workitem's detail",
                              onClick: (event, rowData) =>
                                viewWorkitemShow(rowData),
                              position: "row",
                            },

                            {
                              tooltip: "Actions",
                              icon: () => (
                                <PendingActionsIcon className="btn btn-sm btn-outline-primary" />
                              ),
                              disabled: disableStatusChange,
                              onClick: (event, rowData) =>
                                assignToEmployee(rowData),
                            },
                          ]}
                          options={{
                            headerStyle: {
                              position: "sticky",
                              //top: "-5px",
                            },
                            //maxBodyHeight: "420px",
                            exportButton: false,
                            //sorting: true,
                            filtering: true,
                            search: false,
                            selection: true,
                            emptyRowsWhenPaging: false,
                            // selectionProps:
                            //   selectedItems.length > 0
                            //     ? (rowData) => ({
                            //       tableData: {
                            //       checked: selectionProps(rowData),
                            //        } //onChange
                            //       })
                            //     : null,
                            //selectionProps,
                            tableLayout: "auto",
                            ooverflowY: false,
                            showTitle: false,
                            toolbarButtonAlignment: "left",
                            exportAllData: true,
                            pageSize: 50,
                            paging: true,
                            pageSizeOptions: [50, 100, 200, 500, 1000],
                            debounceInterval: 700,
                            headerStyle: {
                              //height: 30,
                            },
                            rowStyle: {
                              // fontFamily: '"Yantramanav", sans-serif',
                              // fontSize: "14px",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              //fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              //fontSize: "14px",

                              //textAlign: "left !Important",
                            },
                          }}
                          columns={columns}
                          icons={tableIcons}
                          data={dashboardGridDataModels}
                          onSelectionChange={(e, a) => {
                            handleSetSelectedRows(e);
                          }}
                          components={{
                            OverlayLoading: (props) => <LoadingSpinnerFigma />,
                            Container: (props) => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: (props) => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em",
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{ fontSize: "5px" }}
                                />
                              </div>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {/** VIEW WORKITEM MODAL **/}
      <Modal
        show={viewWorkitemModal}
        dialogClassName="modal-100w"
        onHide={viewWorkitemClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Workitem Details</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  {workItemDetailModel.length > 0 ? (
                    <ManageWorkItemDetails
                      state={{ workItemDetailModel, isDynamic: false }}
                    />
                  ) : null}
                </div>
              </ul>
            </div>
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Workitem Fields</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  {workItemFieldsModel.length > 0 ? (
                    <ManageWorkItemDetails
                      state={{ workItemFieldsModel, isDynamic: true }}
                    />
                  ) : null}
                </div>
              </ul>
            </div>
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Workitem Audit Trail</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body MatTable">
                    <MaterialTable
                      title="Workitem Audit Trail"
                      options={{
                        search: false,
                        exportButton: false,
                        showTitle: false,
                        cellStyle: {
                          // width: 100,
                          // minWidth: 100,
                        },
                        headerStyle: {
                          // width: 100,
                          // minWidth: 100,
                          // fontFamily: '"Yantramanav", sans-serif',
                          // fontWeight: "inherit",
                          // fontSize: "12px",
                          // height: 5,
                          // textAlign: "left",
                        },
                        rowStyle: {
                          // fontFamily: '"Yantramanav", sans-serif',
                        },
                      }}
                      columns={listWorkItemHistoryColumns}
                      icons={tableIcons}
                      data={workitemHistory}
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                        Toolbar: (props) => (
                          <div
                            style={{
                              backgroundColor: "rgb(206 206 206)",
                              fontSize: "0.75em",
                            }}
                          >
                            <MTableToolbar
                              {...props}
                              style={{ fontSize: "5px" }}
                            />
                          </div>
                        ),
                      }}
                    />
                  </div>
                </div>
              </ul>
            </div>
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Asignment Details</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body MatTable">
                    <MaterialTable
                      title="Asignment Details"
                      options={{
                        search: false,
                        showTitle: false,
                        exportButton: false,
                        cellStyle: {
                          // width: 100,
                          // minWidth: 100,
                        },
                        headerStyle: {
                          // width: 100,
                          // minWidth: 100,
                          // fontFamily: '"Yantramanav", sans-serif',
                          // fontWeight: "inherit",
                          // fontSize: "12px",
                          // height: 5,
                          // textAlign: "left",
                        },
                        rowStyle: {
                          // fontFamily: '"Yantramanav", sans-serif',
                        },
                      }}
                      columns={asignmentdetailsColumns}
                      icons={tableIcons}
                      data={workitemDetails}
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                        Toolbar: (props) => (
                          <div
                            style={{
                              backgroundColor: "rgb(206 206 206)",
                              fontSize: "0.75em",
                            }}
                          >
                            <MTableToolbar
                              {...props}
                              style={{
                                fontSize: "5px",
                              }}
                            />
                          </div>
                        ),
                      }}
                    />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/** CHANGE STATUS MODAL **/}
      <Modal
        show={settingModal}
        dialogClassName="modal-25w"
        onHide={settingClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="splitfull b_0">
            <form onSubmit={statusChangeSubmit}>
              <div className="fieldSet mb-4">
                {allowStatusChange ? (
                  <ul className="timeBreak-form">
                    <li>
                      <label className="form-label">Change Status</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={statusForStatusChange}
                      >
                        <option>Select</option>
                        {workItemStatus.map((status) => (
                          <option
                            value={
                              status.workItemStatusID + "|" + status.statusName
                            }
                          >
                            {status.statusName}
                          </option>
                        ))}
                      </select>
                    </li>

                    {/* {isComment ? ( */}
                    <li>
                      <label className="form-label">Status Comment</label>
                      {isComment ? (
                        <span
                          id=""
                          className="mandatory"
                          style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                      ) : null}
                      <textarea
                        className="form-control"
                        placeholder="Type here...."
                        onChange={commentForStatusChange}
                        value={statusComment}
                      ></textarea>
                    </li>
                    {/* ) : null} */}
                  </ul>
                ) : null}
              </div>
              <div className="col-12 text-end">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/** Re-ASSIGN **/}
      <Modal
        show={settingassignModal}
        dialogClassName="modal-25w"
        onHide={settingassignClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Workitem Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="splitfull b_0">
            <form onSubmit={assignEmployee}>
              <div className="fieldSet mb-4">
                {allowAssignment ? (
                  <ul className="timeBreak-form">
                    {reassignconfig
                      .filter(
                        (e) =>
                          e.activityID ==
                          (CurrentActivityRef.current.value.split("/")[0] !=
                          "-100"
                            ? CurrentActivityRef.current.value.split("/")[0]
                            : 19)
                      )
                      .map((data) => (
                        <li>
                          {data.activityName +
                            " (" +
                            data.workItemAllocationMode +
                            ")"}
                          <select
                            className="form-control form-control-sm"
                            onChange={(e) =>
                              HandleReassignEmployee(e, data.activityID)
                            }
                          >
                            <option selected={true} value={0}>
                              --Select Employee--
                            </option>
                            <option value={1}>-- Unassign --</option>
                            {data._EmpDetails.map((employee) => (
                              <option value={employee.employeeVersionID}>
                                {employee.name}
                              </option>
                            ))}
                          </select>
                        </li>
                      ))}
                  </ul>
                ) : null}
              </div>
              <div className="col-12 text-end">
                <button className="btn btn-primary" type="submit">
                  Assign
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={viewSearchModal}
        dialogClassName="modal-100w"
        onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search Criteria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body">
                  <table className="table brandTable">
                    <div>
                      <div className="fieldSet pt-1">
                        {/* <h4 className="mb-4">Advanced Search Criteria</h4> */}
                        <div className="row align-items-end">
                          <div className="col-3">
                            {/* <div className="mb-3"> */}
                            <label className="form-label">Search field </label>
                            <select
                              className="form-control form-control-sm"
                              title="Select a field to apply filter"
                              onChange={getAdvanceField}
                            >
                              <option>Select</option>
                              {WorkFlowWorkItemFields.map((status) => (
                                <option
                                  value={
                                    status.maptoField + "/" + status.labelText
                                  }
                                >
                                  {status.labelText}
                                </option>
                              ))}
                            </select>
                            {/* </div> */}
                          </div>
                          <div className="col-3">
                            {/* <div className="mb-3"> */}
                            <label className="form-label">Condition </label>
                            {
                              <select
                                className="form-control form-control-sm"
                                title="Select a field to apply filter"
                                onChange={getAdvanceCondition}
                              >
                                <option value="">Select</option>
                                <option value="=">Equals</option>
                                <option value="<>">Not Equals</option>
                                <option value="<">Less Than</option>
                                <option value="<=">Less Than Or Equals</option>
                                <option value=">">Greater Than</option>
                                <option value=">=">
                                  Greater Than Or Equals
                                </option>
                                <option value="0">Starts With</option>
                                <option value="1">Ends With</option>
                                <option value="2">Contains</option>
                                <option value="3">Does Not Contain</option>
                              </select>
                            }
                            {/* </div> */}
                          </div>
                          <div className="col-3">
                            {/* <div className="mb-3"> */}
                            <label className="form-label">Search Text</label>
                            {isDateField ? (
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                //onChange={getAdvanceText}
                                //value={advSearchText}
                              />
                            ) : (
                              <input
                                className="form-control form-control-sm"
                                ref={SearchTextRef}
                                // onChange={getAdvanceText}
                                // value={advSearchText}
                              />
                            )}
                            {/* </div> */}
                          </div>
                          <div className="col-2">
                            {/* <div className="mb-3"> */}
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary w-100 text-uppercase"
                              onClick={addAdvanceSearchConditions}
                            >
                              {" "}
                              Add
                            </button>
                            {/* </div> */}
                          </div>
                        </div>

                        <div className="pt-2 pb-2">
                          <div className="mb-5">
                            <div>
                              <table className="table table-borderless MatTable">
                                {/* <ThemeProvider theme={theme}> */}
                                <MaterialTable
                                  localization={{
                                    pagination: {
                                      //labelDisplayedRows: '{from}-{to} of {count}'
                                    },
                                    toolbar: {
                                      // nRowsSelected: '{0} row(s) selected'
                                    },
                                    header: {
                                      actions: "",
                                    },
                                  }}
                                  options={{
                                    search: false,
                                    exportButton: false,
                                    tableLayout: "auto",
                                    overflowY: false,
                                    showTitle: false,
                                    toolbarButtonAlignment: "left",
                                    paging: false,
                                    toolbar: false,
                                    headerStyle: {
                                      height: 30,
                                    },
                                    rowStyle: {
                                      // fontFamily: '"Yantramanav", sans-serif',
                                      // fontSize: "14px",
                                    },
                                    tableLayout: "auto",
                                    headerStyle: {
                                      // fontFamily: '"Yantramanav", sans-serif',
                                      // fontWeight: "inherit",
                                      // fontSize: "14px",
                                      // textAlign: "left !Important",
                                    },
                                  }}
                                  editable={{
                                    onRowDelete: (oldData) =>
                                      new Promise((resolve) => {
                                        handleRowDelete(oldData, resolve);
                                      }),
                                  }}
                                  columns={advanceColumns}
                                  icons={tableIcons}
                                  data={advSearchDetails}
                                />
                                {/* </ThemeProvider> */}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-ntv mx-2"
            onClick={viewSearchClose}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={ViewUndoChangesModal}
        dialogClassName="modal-100w"
        onHide={CloseUndoChangesModal}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Undo Last Activity - Work</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body">
                  <table className="table brandTable">
                    <div className="pageBody">
                      <div className="tableWrap-body">
                        <table className="table table-borderless MatTable">
                          <MaterialTable
                            title="Workitem Details"
                            options={{
                              headerStyle: {
                                position: "sticky",
                                top: "-5px",
                              },
                              maxBodyHeight: "100px",
                              exportButton: false,
                              sorting: true,
                              filtering: true,
                              search: false,
                              selection: false,
                              tableLayout: "auto",
                              overflowY: false,
                              showTitle: false,
                              toolbarButtonAlignment: "left",
                              headerStyle: {
                                height: 30,
                              },
                              rowStyle: {
                                fontFamily: '"Yantramanav", sans-serif',
                                fontSize: "13px",
                                // whiteSpace: "nowrap",
                              },
                              tableLayout: "auto",
                              headerStyle: {
                                fontFamily: '"Yantramanav", sans-serif',
                                fontWeight: "inherit",
                                fontSize: "14px",
                                textAlign: "left !Important",
                              },
                            }}
                            columns={LastActivityColumns}
                            icons={tableIcons}
                            data={LastActivityDetails}
                            components={{
                              Container: (props) => (
                                <Paper {...props} elevation={0} />
                              ),
                              Toolbar: (props) => (
                                <div
                                  style={{
                                    backgroundColor: "rgb(206 206 206)",
                                    fontSize: "0.75em",
                                  }}
                                >
                                  <MTableToolbar
                                    {...props}
                                    style={{
                                      fontSize: "5px",
                                    }}
                                  />
                                </div>
                              ),
                            }}
                          />
                          {/* </ThemeProvider> */}
                        </table>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </ul>
          </div>
          <div className="row align-items-end">
            <div className="col-4">
              <div className="mb-3">
                <div>
                  <h5>Last Workflow State Details:</h5>          {" "}
                  <div>Workitem ID - {WorkitemIdForUndoActivity}</div>     
                           {" "}
                  <div>Last Workitem Status: {LastWorkitemStatus}</div>  
                               <div>Last Activity: {LastActivityName}</div>     
                      {" "}
                </div>
                  
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <div>
                                
                  <h5>Undo To:</h5>               {" "}
                  <div>Workitem ID - {WorkitemIdForUndoActivity}</div>        
                  <div>Workitem Status: {UndoWorkitemStatus}</div>          
                       <div>Activity: {UndoActivityName}</div>                
                    
                </div>
                   
              </div>
            </div>
          </div>
                           
          <div>
            <div>Status Comment</div>      {" "}
            <textarea
              className="form-control"
              placeholder="Type here...."
              onChange={commentForUndoStatusChange}
              value={UndoStatusComment}
            ></textarea>
              
          </div>
            
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-primary ms-3"
              onClick={updateWorkitemToPreviousActivity}
            >
              Undo
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={CloseUndoChangesModal}
            >
              Back
            </button>
            <span>
              Note: Click on Undo Button to change the Last Workflow State
              details of the selected workitemID to the Workflow State details
              shown under Undo To.
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(ManageWorkitem);
