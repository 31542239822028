import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn : sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING")
};

export const updateTimeCapturingSlice = createSlice({
  name: "updateTimeCapturingSlice",
  initialState,
  reducers: {
    GetUpdatedTimeCapturing: (state, action) => {
       debugger;
      console.log("browserstatus", action.payload);
      sessionStorage.setItem(
        "FetchBreakTimerOnLoadData", JSON.stringify(action.payload)
      );
      //sessionStorage.setItem("FetchBreakTimerOnLoadData",)
      state.allowCientTimeCapturing = sessionStorage.getItem("FetchBreakTimerOnLoadData") ;

      //return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { GetUpdatedTimeCapturing } = updateTimeCapturingSlice.actions;

export default updateTimeCapturingSlice.reducer;
