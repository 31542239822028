import { createSlice } from '@reduxjs/toolkit'

const initialState = [];

export const selectedEmployeeIDSlice = createSlice({
  name: 'selectedEmployeeID',
  initialState,
  reducers: {
    addSelectedEmployeeID: (state,action) => {
        console.log("slice",action.payload);
        
      state.push(action.payload); 
       }
    
  },
})

// Action creators are generated for each case reducer function
export const { addSelectedEmployeeID} = selectedEmployeeIDSlice.actions

export default selectedEmployeeIDSlice.reducer