import "./App.scss";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router";
import PrivateRoute from "../src/PrivateRoute/index";
import { useDispatch, useSelector } from "react-redux";
import Home from "./cws/containers/pages/home/Home";
import ResetPassword from "./cws/containers/pages/Client/ResetPassword";
import Reset from "./cws/containers/pages/forgot/Reset";
import Login from "./cws/containers/pages/login/Login";
import SSOLogin from "./cws/containers/pages/login/SSOLogin";
import SSORedirect from "./cws/containers/pages/login/SSORedirect";
import WorkActivityScreen from "./cws/containers/pages/WorkActivityScreen/WorkActivityScreen";
import WorkActivityDashboard from "./cws/containers/pages/WorkActivityDashboard/WorkActivityDashboard";
import UploadAssignment from "./cws/containers/pages/uploadassignment/UploadAssignment";
import CreateWorkItem from "./cws/containers/pages/CreateWorkItem/CreateWorkItem";
import SearchWorkItem from "./cws/containers/pages/searchworkitem/SearchWorkItem";
import ManageWorkitem from "./cws/containers/pages/manageworkitem/ManageWorkitem";
import ViewUploadHistroy from "./cws/containers/pages/viewuploadhistroy/ViewUploadHistroy";
import Workitem from "./cws/containers/pages/workitem/Workitem";
import UnlockWorkitems from "./cws/containers/pages/unlockworkitems/UnlockWorkitems";
//import TimeUtilizationDetails from "./cws/containers/pages/timeutilizationdetails/TimeUtilizationDetails";
import ExportConfiguration from "./cws/containers/pages/exportconfiguration/ExportConfiguration";
import ExportConfig from "./cws/containers/pages/activities/ExportConfig";
import WorkitemUpdation from "./cws/containers/pages/workitemupdation/WorkitemUpdation";
import OutofOffice from "./cws/containers/pages/outofoffice/OutofOffice";
import ActivityScreen from "./cws/containers/pages/ActivityScreen/ActivityScreen";
import BulkEdit from "./cws/containers/pages/manageworkitem/BulkEdit";
import Reports from "./cws/containers/pages/Reports/Reports";
import BucketManagementDashboard from "./cws/containers/pages/Reports/BucketManagementDashboard";
import QueueManagementDashboard from "./cws/containers/pages/Reports/QueueManagementDashboard";
import PowerBiDashboards from "./cws/containers/pages/Reports/PowerBiDashboards";
import EmbeddedReports from "./cws/containers/pages/Reports/EmbeddedReports";
import RealTimeDashboard from "./cws/containers/pages/Reports/RealtimeDashboard";
import SubprocessUserClassificationMapping from "./cws/containers/pages/BusinessRules/SubprocessUserClassificationMapping";
import { useEffect } from "react";
import EmployeeActivitySummary from "./cws/containers/pages/activities/EmployeeActivitySummary";
import ClientDailyInfo from "./cws/containers/pages/activities/ClientDailyInfo";
import SubProcessUserClassificationList from "./cws/containers/pages/BusinessRules/SubProcessUserClassificationList";
import EmployeeActivityDetails from "./cws/containers/pages/activities/EmployeeActivityDetails";
import ActivityScreenWorkItem from "./cws/containers/pages/ActivityScreen/ActivityScreenWorkItem";
import Process from "./cws/containers/pages/process/Process";
import ErrorHandler from "./ErrorHandler";
import ErrorPage from "./ErrorPage";
import { ErrorBoundary } from "react-error-boundary";
import UploadClientLogo from "./cws/containers/pages/Admin/UploadClientLogo";
import ReportConfig from "./cws/containers/pages/Admin/ReportConfig";
import SubProcessBulkUpdateFileConfig from "./cws/containers/pages/Admin/SubProcessBulkUpdateFileConfig";
import { useNavigate } from "react-router-dom";
import ErrorCategory from "./cws/containers/pages/Admin/ErrorCategory/ViewErrorCategory";
import { MsalProvider } from "@azure/msal-react";
import FormBuilderConfig from "./cws/containers/pages/WorkflowConfiguration/FormBuilderConfig";
import WorkflowWizard from "./cws/containers/pages/WorkflowConfiguration/WorkflowWizard";
import GlobalStyles from "@mui/material/GlobalStyles";
import BpmnEditor from "./cws/containers/pages/WorkflowConfiguration/WorkFlowConfig";
import WorkItemStatus from "./cws/containers/pages/Admin/WorkItemStatus";
import SubProcessFieldValueGroupConfig from "./cws/containers/pages/Admin/SubProcessGroupApprovalConfig";

//import BPMNFlowPoc from "./cws/containers/pages/WorkflowConfiguration/BPMNFlowPoc";
import About from "./About";
import { SaveTimerOnTick, SaveTimerOnTick_Worker } from "./cws/containers/Comman/TimeCapture";
//added by Santosh
import ONDCommonFieldMapping from "./cws/containers/pages/Admin/ONDCommonFieldMapping";
import UploadWorkitemFieldDropdownValues from "./cws/containers/pages/Admin/UploadDropdownValues"
//added by Abhishek Roy
import EmailConfiguration from "./cws/containers/pages/Admin/EmailConfiguration";
import ManageNews from "./cws/containers/pages/Admin/ManageNews"; //Added by Abhishek
import AddEditNews from "./cws/containers/pages/Admin/AddEditNews";  //Added by Abhishek


//Added By Sahil
import SubProcessUserGroupConfiguration from "./cws/containers/pages/Admin/SubProcessUserGroupConfiguration";

//Added By Sahil
import SubProcessSummary from "./cws/containers/pages/activities/SubProcessSummary";
import WorkItemBulkUpdation from "./cws/containers/pages/activities/WorkItemBulkUpdation";


import WorkitemFieldsRules from "./cws/containers/pages/BusinessRules/WorkitemFieldsRules";
import OneandDoneDashboard from "./cws/containers/pages/OneandDone/OneandDoneDashboard";
import SubProcessInstructionFieldConfig from "./cws/containers/pages/Admin/SubProcessInstructionFieldConfig";
import ImportSubProcessConfiguration from "./cws/containers/pages/Admin/ImportSubProcessConfiguration";
import SubProcessMoveWorkitem from "./cws/containers/pages/Admin/SubProcessMoveWorkitem";
import AddNewMoveWorkitem from "./cws/containers/pages/Admin/AddNewMoveWorkitem";
import SubProcessAutoAssignment from "./cws/containers/pages/Admin/SubProcessAutoAssignment";
import SubProcessStatusHistoryMapping from "./cws/containers/pages/Admin/SubProcessStatusHistoryMapping"; //added by Roy
import SubProcessInstructionListConfig from "./cws/containers/pages/Admin/SubProcessInstructionListConfig";
import SubProcessRandomReviewConfig from "./cws/containers/pages/Admin/SubProcessRandomReviewConfig";
import { useState } from "react";
import { GetBrowserOnlineStatus } from "./redux/AppGlobalSlice";
import WorkFlowState from "./cws/containers/pages/Admin/WorkFlowState";
import ValidationRule from "./cws/containers/pages/Admin/ValidationRule";
import EmailDomain from "./cws/containers/pages/Admin/EmailDomain";

import StatusReason from "./cws/containers/pages/Admin/StatusReason/StatusReason";
import ErrorFields from "./cws/containers/pages/Admin/ErrorFields/ViewErrorFields";
import Activities from "./cws/containers/pages/Admin/Activities/Activities";
import HierarchyReportConfig from "./cws/containers/pages/Admin/HierarchyReportConfig"
import CapacityScheduler from "./cws/containers/pages/CapacityPlanner/CapacityScheduler";
import CapacityPlanner from "./cws/containers/pages/CapacityPlanner/CapacityPlanner";
import SubProcessTaxonomyMappingConfig from "./cws/containers/pages/Admin/SubProcessTaxonomyMappingConfig";
import VendorPortalConfig from "./cws/containers/pages/Admin/VendorPortalConfig";

import { RefreshToken } from "./components/common/functions";
function App({ instance }) {
  let navigate = useNavigate();
  //const { addToast } = useToasts();
  const { REACT_APP_RefreshToken } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_NAME } = process.env;
  const { REACT_APP_WRITETOLOG } = process.env;
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [timecapturing, settimecapturing] = useState(sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING"));


  
  const allowCientTimeCapturing = useSelector(state => state);
  const dispatch = useDispatch();
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  // const RefreshToken = () => {
  //   let body = {
  //     jwtToken: sessionStorage.getItem("authorizationToken"),
  //     refreshToken: sessionStorage.getItem("RefreshToken"),
  //     userName: sessionStorage.getItem("UserName"),
  //   };
  //   let refreshTokenUrl = getAPIURL(REACT_APP_RefreshToken);
  //   let req = new Request(`${refreshTokenUrl}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       UserName: sessionStorage.getItem("UserName"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       //  // debugger;
  //       if (result.status == 200) {
  //         sessionStorage.setItem("authorizationToken", result.data.jwtToken);
  //         sessionStorage.setItem("RefreshToken", result.data.refreshToken);
  //       } else if (result.status === 401) {
  //         // addToast("Session Expired/UnAuthorized. Please LogIn Again", {
  //         //   appearance: "error",
  //         //   autoDismiss: true,
  //         // })
  //         toast.error("Session Expired/UnAuthorized. Please LogIn Again !", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         sessionStorage.clear();
  //         sessionStorage.clear();
  //         navigate("/");
  //       } else if (
  //         result.StatusCode == 500 &&
  //         result.Message == "Invalid Token Passed!!"
  //       ) {
  //         // addToast("Session Expired/UnAuthorized. Please LogIn Again", {
  //         //   appearance: "error",
  //         //   autoDismiss: true,
  //         // })
  //         toast.error("Session Expired/UnAuthorized. Please LogIn Again !", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         sessionStorage.clear();
  //         sessionStorage.clear();
  //         navigate("/");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("ERROR: ", error);
  //       //navigate("/");
  //     });
  // };
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
      dispatch(GetBrowserOnlineStatus(navigator.onLine));
      //if user offline clear session
      if (!navigator.onLine) {
        // sessionStorage.clear();
        // sessionStorage.clear();
        toast.error("You are offline. Kindly check your connection!", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    };
    handleOnlineStatusChange();
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);
  
  let interval = null;
  useEffect(() => {
    // debugger;
    if (
      sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == null ||
      sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1"
    ) {
      interval = setInterval(() => {
        if (
          sessionStorage.getItem("UserName") != null &&
          sessionStorage.getItem("UserName") != undefined
        ) {
          //SaveTimerOnTick();
        } else {
          console.log("Nouser data to capture time");
        }
      }, 600000);

      return () => {
        //  console.log("ending dashboard 1");
        clearInterval(interval);
      };
    }

    // const intervalId = setInterval(() => {
      
    //   if (sessionStorage.getItem("RefreshToken") != null) {
    //     RefreshToken();
    //   }
    // }, 300 * 1000);

    // return () => clearInterval(intervalId);
  }, []);
  

  useEffect(()=>{
    
    const newWorker = new Worker(new URL('./cws/containers/pages/WorkerInterval.js', import.meta.url));

    let result = null;

    if ((
      sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == null ||
      sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") 
      && sessionStorage.getItem("FetchBreakTimerOnLoadData") != null
    ) {
      
      let FetchBreakTimerOnLoadData = JSON.parse(
        sessionStorage.getItem("FetchBreakTimerOnLoadData")
      );
       // var body = SaveTimerOnTick();
      //  console.log(body);
      
      newWorker.onmessage = (event) =>{
        console.log("starting Webworker",new Date().toISOString());
        if(event.data.type === 'requestData'){
          const body = SaveTimerOnTick_Worker();
          if(body.message === "Success")
          newWorker.postMessage({action: 'start', interval: 1*60*1000, data: body});
          else if (body.message.includes("Reloading"))
          {
            alert(
              "Due to unstable network connection, data sync encoured an issue. Reloading your page."
            );

            //newWorker.postMessage({action: 'reload'});
            window.location.reload(true);
          }
        }

        else if(event.data.action === 'sync'){
          console.log(event.data.data);
          result = event.data.data;
          
                    FetchBreakTimerOnLoadData.timerStart = result.timerStart;
                    FetchBreakTimerOnLoadData.timerValue = result.timerValue;
                    FetchBreakTimerOnLoadData.tSaveTimerEnabled =
                      result.tSaveTimerEnabled;
                    FetchBreakTimerOnLoadData.btnStartWorkTimerDisabled =
                      result.btnStartWorkTimerDisabled;
                    // FetchBreakTimerOnLoadData.txtBreakCommentsEnabled =
                    //   result.txtBreakCommentsEnabled;
                    // FetchBreakTimerOnLoadData.ddlActivityEnabled =
                    //   result.ddlActivityEnabled;
                    FetchBreakTimerOnLoadData.btnShowBreakPanelDisabled =
                      result.btnShowBreakPanelDisabled;
                    // sessionStorage.setItem(
                    //   "objdataview",
                    //   JSON.stringify(result.data[0].objdataview)
                    // );
                    
                    sessionStorage.setItem(
                      "FetchBreakTimerOnLoadData",
                      JSON.stringify(FetchBreakTimerOnLoadData)
                    );
                    sessionStorage.setItem(
                      "empTimeUtilization",
                      JSON.stringify(result.employeeTimeUtilization)
                    );
                    sessionStorage.setItem(
                      "timerlastsync",
                      result.employeeTimeUtilization[0].timeFrom
                    );
        }
      }
    }
      
    else {
      console.log("Nouser data to capture time");
    }
  
  
    return()=>{
      newWorker.terminate();
    }
    //window.addEventListener('sessionStorageChange',handleStorageChange);

  
  //},[allowCientTimeCapturing, sessionStorage.getItem("FetchBreakTimerOnLoadData")]);
},[allowCientTimeCapturing]);

  return (
    <>
      <MsalProvider instance={instance}>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <ToastProvider placement="bottom-right">
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/Process" element={<Process />} />
              <Route path="Client/ResetPassword" element={<ResetPassword />} />
              <Route path="/Reset" element={<Reset />}></Route>
              <Route exact path="/About" element={<About />} />
              <Route
                exact
                path="/Home"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/WorkActivityScreen"
                element={<WorkActivityScreen />}
              />
              <Route
                exact
                path="/EmbeddedReports"
                element={<EmbeddedReports />}
              />

              <Route
                exact
                path="/FormBuilderConfig"
                element={<FormBuilderConfig />}
              />
              <Route
                exact
                path="/WorkflowWizard"
                element={<WorkflowWizard />}
              />

              <Route exact path="/BpmnEditor" element={<BpmnEditor />} />
              {/* <Route exact path="/BPMNFlowPoc" element={<BPMNFlowPoc />} /> */}
              {/* <Route
              exact
              path="/PowerBiDashboards"
              element={<PowerBiDashboards />}
            /> */}

              <Route
                exact
                path="/ActivityScreen"
                element={
                  <PrivateRoute>
                    <ActivityScreen />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/UploadWorkitemFieldDropdownValues"
                element={<UploadWorkitemFieldDropdownValues />}
              />

              <Route
                exact
                path="/BulkEdit"
                element={
                  <PrivateRoute>
                    <BulkEdit />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/WorkActivityDashboard"
                element={
                  <PrivateRoute>
                    <WorkActivityDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/OneandDoneDashboard"
                element={
                  <PrivateRoute>
                    <OneandDoneDashboard />
                  </PrivateRoute>
                }
              />



              <Route exact path="/ErrorPage" element={<ErrorPage />} />
              {/* <Route exact path="/Processing" element={<Processing />} /> */}
              <Route
                exact
                path="/UploadAssignment"
                element={
                  <PrivateRoute>
                    <UploadAssignment />
                  </PrivateRoute>
                }
              />
              <Route exact path="/Reports" element={<Reports />} />
              <Route
                exact
                path="/BucketManagementDashboard"
                element={<BucketManagementDashboard />}
              />
               <Route
                exact
                path="/QueueManagementDashboard"
                element={<QueueManagementDashboard />}
              />
              <Route
                exact
                path="/RealTimeDashboard"
                element={<RealTimeDashboard />}
              />
              <Route
                exact
                path="/CreateWorkItem"
                element={<CreateWorkItem />}
              />


              <Route
                exact
                path="/SearchWorkItem"
                element={<SearchWorkItem />}
              />
              <Route
                exact
                path="/ViewUploadHistroy"
                element={<ViewUploadHistroy />}
              />
              <Route
                exact
                path="/ManageWorkitem"
                element={<ManageWorkitem />}
              />
              <Route exact path="/Workitem" element={<Workitem />} />
              <Route
                exact
                path="/UnlockWorkitems"
                element={<UnlockWorkitems />}
              />
              <Route
                exact
                path="/ManageWorkitem"
                element={<ManageWorkitem />}
              />
              {/* <Route
                exact
                path="/TimeUtilizationDetails"
                element={<TimeUtilizationDetails />}
              /> */}

              {/*Added By Sahil */}
              <Route
                exact
                path="/SubProcessUserGroupConfiguration"
                element={<SubProcessUserGroupConfiguration />}
              />

              <Route
                exact
                path="/WorkItemBulkUpdation"
                element={<WorkItemBulkUpdation />}
              />



              <Route
                exact
                path="/ExportConfiguration"
                element={<ExportConfiguration />}
              />
              <Route
                exact
                path="/EmailConfiguration"
                element={<EmailConfiguration />}
              />
              <Route
                exact
                path="/WorkitemUpdation"
                element={<WorkitemUpdation />}
              />

              <Route
                exact
                path="/WorkItemStatus"
                element={<WorkItemStatus />}
              />
              <Route exact path="/WorkFlowState" element={<WorkFlowState />} />
              <Route
                exact
                path="/ValidationRule"
                element={<ValidationRule />}
              />
              <Route exact path="/EmailDomain" element={<EmailDomain />} />

              <Route exact path="/OutofOffice" element={<OutofOffice />} />
              <Route
                exact
                path="/SubprocessUserClassificationMapping"
                element={<SubprocessUserClassificationMapping />}
              />
              <Route
                exact
                path="/SubProcessUserClassificationList"
                element={<SubProcessUserClassificationList />}
              />
              <Route
                exact
                path="/SubProcessSummary"
                element={<SubProcessSummary />}
              />
              <Route
                exact
                path="/EmployeeActivitySummary"
                element={<EmployeeActivitySummary />}
              />
              <Route
                exact
                path="/EmployeeActivityDetails"
                element={<EmployeeActivityDetails />}
              />
              <Route
                exact
                path="/ExportConfig"
                element={<ExportConfig />}
              />
              <Route
                exact
                path="/ClientDailyInfo"
                element={<ClientDailyInfo />}
              />
              <Route
                exact
                path="/ActivityScreenWorkItem"
                element={<ActivityScreenWorkItem />}
              />
              <Route
                exact
                path="/UploadClientLogo"
                element={<UploadClientLogo />}
              />
              <Route  // Added by Harshita
                exact
                path="/ReportConfig"
                element={<ReportConfig />}
              />
              <Route
                exact
                path="/SubProcessBulkUpdateFileConfig"
                element={<SubProcessBulkUpdateFileConfig />}
              />

              <Route exact path="/ErrorCategory" element={<ErrorCategory />} />

              <Route
                exact
                path="/StatusReason"
                element={<StatusReason />}
              />
              <Route
                exact
                path="/ErrorFields"
                element={<ErrorFields />}
              />
              <Route
                exact
                path="/Activities"
                element={<Activities />}
              />

              {/* added by Santosh */}
              <Route
                exact
                path="/ONDCommonFieldMapping"
                element={<ONDCommonFieldMapping />}
              />
              {/* added by Roy */}
              <Route
                exact
                path="/SubProcessStatusHistoryMapping"
                element={<SubProcessStatusHistoryMapping />}
              />
              {/* Added by Abhishek */}
              <Route
                exact
                path="/ManageNews"
                element={<ManageNews />}
              />

              {/* Added by Abhishek */}
              <Route
                exact
                path="/AddEditNews"
                element={<AddEditNews />}
              />

              <Route exact path="/SSOLogin" element={<SSOLogin />} />
              <Route exact path="/SSORedirect" element={<SSORedirect />} />
              <Route
                exact
                path="/WorkitemFieldsRules"
                element={<WorkitemFieldsRules />}
              />
              <Route
                exact
                path="/SubProcessInstructionFieldConfig"
                element={<SubProcessInstructionFieldConfig />}
              />
              <Route
                exact
                path="/ImportSubProcessConfiguration"
                element={<ImportSubProcessConfiguration />}
              />
              <Route
                exact
                path="/HierarchyReportConfig"
                element={<HierarchyReportConfig />}
              />
              <Route
                exact
                path="/SubProcessMoveWorkitem"
                element={<SubProcessMoveWorkitem />}
              />
              <Route
                exact path="/AddNewMoveWorkitem"
                element={<AddNewMoveWorkitem />}
              />
              <Route
                exact
                path="/CapacityScheduler"
                element={<CapacityScheduler />}
              />
              <Route
                exact
                path="/CapacityPlanner"
                element={<CapacityPlanner />}
              />
              <Route
                exact
                path="/SubProcessAutoAssignment"
                element={<SubProcessAutoAssignment />}
                          />
              <Route
                exact
                path="/SubProcessInstructionListConfig"
                element={<SubProcessInstructionListConfig />}
              />
              
              <Route
                exact
                path="/SubProcessFieldValueGroupConfig"
                element={<SubProcessFieldValueGroupConfig />}
              />
              
              <Route
                exact
                path="/SubProcessRandomReviewConfig"
                element={<SubProcessRandomReviewConfig />}
              />

              <Route
                exact
                path="/SubProcessTaxonomyMappingConfig"
                element={<SubProcessTaxonomyMappingConfig />}
              />

              <Route
                exact
                path="/VendorPortalConfig"
                element={<VendorPortalConfig />}
              />
            </Routes>
          </ToastProvider>
        </ErrorBoundary>
      </MsalProvider>
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important",
            fontSize: "12px !Important",
            lineHeight: "3px !Important",
            textTransform: "none"
          },
          "MuiTableCell-body": {
            lineHeight: "3px !Important"
          },
          ".MuiTableFooter-root": {
            position: "absolute !Important"
          },
          ".MuiTypography-root": {
            "font-size": "0.8rem !important"
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",

            "font-size": "1.5rem !important"
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important",
            padding: "5px !Important"
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important"
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important",
            padding: "0 !important",
            paddingLeft: "1rem !important"
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#ddd !Important",
            padding: "0px !important"
          }
        }}
      />
    </>
  );
}

export default App;
