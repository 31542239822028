import React, { useEffect, useRef, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import BpmnJS from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-font/dist/css/bpmn-embedded.css";
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
  CamundaPlatformPropertiesProviderModule,
} from "bpmn-js-properties-panel";
//import "./buttonedge.css";
import editicon from "../../../../assets/images/icons/edit_black_18dp.svg";
import CamundaBpmnModdle from "camunda-bpmn-moddle/resources/camunda.json";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
//import "./properties-panel.css";
import LoadingSpinner from "../../../../LoadingSpinner";
import { async } from "q";
import Modal from "react-bootstrap/Modal";
import PaletteModule from "bpmn-js/lib/features/palette";
import { useDispatch, useSelector } from "react-redux";
import {
  addselectedFlowId,
  addselectedTaskId,
  addOriginNodes,
  resetFlowIds,
  resetTaskIds,
} from "../../../../redux/addOriginalElementSlice";
import store from "../../../../redux/store";
import { ReactReduxContext } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const InitialXml =
  '<?xml version="1.0" encoding="UTF-8"?>\n<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn"><bpmn:process id="Process_1" isExecutable="false"><bpmn:startEvent id="StartEvent_1"><bpmn:outgoing>Flow_07yodxb</bpmn:outgoing></bpmn:startEvent><bpmn:task id="Activity_0fuj9dl_8" name="Upload"><bpmn:incoming>Flow_07yodxb</bpmn:incoming></bpmn:task><bpmn:sequenceFlow id="Flow_07yodxb" sourceRef="StartEvent_1" targetRef="Activity_0fuj9dl_8" /></bpmn:process><bpmndi:BPMNDiagram id="BPMNDiagram_1"><bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1"><bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1"><dc:Bounds x="173" y="102" width="36" height="36" /></bpmndi:BPMNShape><bpmndi:BPMNShape id="Activity_0fuj9dl_di" bpmnElement="Activity_0fuj9dl_8"><dc:Bounds x="260" y="80" width="100" height="80" /><bpmndi:BPMNLabel /></bpmndi:BPMNShape><bpmndi:BPMNEdge id="Flow_07yodxb_di" bpmnElement="Flow_07yodxb"><di:waypoint x="209" y="120" /><di:waypoint x="260" y="120" /></bpmndi:BPMNEdge></bpmndi:BPMNPlane></bpmndi:BPMNDiagram></bpmn:definitions>';
const WorkFlowConfig = ({ onDataSend, reference }) => {
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_GETWORKFLOWSTATESBYSUBPROCESSID } = process.env;
  const { REACT_APP_FETCHALLWORKFLOWSTATES } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_FETCH_GETWORKITEMSTATUESBYWORKFLOWSTATEIDSUBPROCESSID } =
    process.env;
  const { REACT_APP_FETCHPROCESSSUBPROCESSWORKFLOWSTATUS } = process.env;
  const { REACT_APP_FETCHPROCESSSUBPROCESSWORKACTIVITY } = process.env;
  const { REACT_APP_GETCONFIGUREDACTIVITIESFORSUBPROCESS } = process.env;
  const { REACT_APP_FETCH_SUBPROCESSWORKFLOWCREATE } = process.env;
  const { REACT_APP_FETCH_MILESTONESBYSUBPROCESSID } = process.env;
  const { REACT_APP_GETWORKFLOW } = process.env;
  const { REACT_APP_GETWORKFLOWCONFIG } = process.env;
  const { REACT_APP_SUBPROCESSWORKFLOWUPDATESTATE } = process.env;
  const { REACT_APP_SUBPROCESSWORKFLOWDELETEWORKFLOWCONFIG } = process.env;
  const { REACT_APP_SUBPROCESSWORKFLOWPUBLISH } = process.env;
  const { REACT_APP_SUBPROCESSWORKFLOWFETCHWORKACTIVITYCONFIG } = process.env;
  const { REACT_APP_GETEMPLOYEESWITHPROCESSACCESS } = process.env;
  const { REACT_APP_GETSUBPROCESSACTIVITYESCALATIONCONFIGMOD } = process.env;
  const { REACT_APP_SAVEANDUPDATEACTIVITYCONFIG } = process.env;
  const { REACT_APP_CheckValidWorkActivityConfig } = process.env;
  const { REACT_APP_GetEmailTemplatesAsync } = process.env;

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { originalElements } = useSelector((state) => state);
  const { store } = useContext(ReactReduxContext);

  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const [IsValidWorkActivityConfig, setIsValidWorkActivityConfig] =
    useState(false);
  const [showModalButton, setShowModalButton] = useState(false);
  const [AdvanceConfigModalButton, setAdvanceConfigModalButton] =
    useState(false);
  const [preferenceModal, setpreferenceModalShow] = useState(false);
  const [IsPlublishedClicked, setIsPlublishedClicked] = useState(false);
  const [AdvanceConfigModal, setAdvanceConfigModal] = useState(false);
  const [NotificationModal, setNotificationModal] = useState(false);
  const [RemindernotificationModal, setRemindernotificationModal] =
    useState(false);
  const [EmployeesWithAccess, setEmployeesWithAccess] = useState([]);
  const [IsCleintCompleteExist, setIsCleintCompleteExist] = useState(false);
  const [isallNodesHaveInOutFlows, setisallNodesHaveInOutFlows] =
    useState(false);
  const [ActivityEscalationMod, setActivityEscalationMod] = useState([]);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const [SelectedClientName, setSelectedClientName] = useState("");
  const [SequenceCanvas, setSequenceCanvas] = useState(false);
  const [HideSequenceFlowConfig, setHideSequenceFlowConfig] = useState(false);
  const [TaskCanvas, setTaskCanvas] = useState(false);
  const [WorkflowStates, setWorkflowStates] = useState([]);
  const [IsActivityConfig, setIsActivityConfig] = useState([]);
  const [ActivityId, setActivityId] = useState("");
  const [ActivityConfigIdForDelete, setActivityConfigIdForDelete] =
    useState("");
  //const [WorkflowStatus,setWorkflowStatus] = useState([]);
  const [WorkflowStateID, setWorkflowStateID] = useState("");
  const [WorkflowStateName, setWorkflowStateName] = useState("");
  const [EndEventCreated, setEndEventCreated] = useState(false);
  const [EndConnectionCreated, setEndConnectionCreated] = useState(false);
  const [EndEvent, setEndEvent] = useState(null);
  const [CurrentElement, setCurrentElement] = useState();
  const [CurrentElementNewID, setCurrentElementNewID] = useState();
  const [xmlDiagram, setxmlDiagram] = useState();
  const [show, setShow] = useState(false);
  const [shapeId, setshapeId] = useState("");
  const [CurrentWorkflowState, setCurrentWorkflowState] = useState("");
  const [WorkflowStatuses, setWorkflowStatuses] = useState([]);
  const [WorkflowStatusesConfig, setWorkflowStatusesConfig] = useState([]);
  const [IschangeWorkflowState, setIschangeWorkflowState] = useState(false);
  const [Activities, setActivities] = useState([]);
  const [milestoneList, setMilestoneList] = useState([]);
  const [CurrentStateId, setCurrentStateId] = useState("");
  const [NextStateId, setNextStateId] = useState("");
  const [IsDashboardload, setIsDashboardload] = useState(false);
  const [DeletedNodeId, setDeletedNodeId] = useState("");
  const [SeletedNodes, setSeletedNodes] = useState([]);
  const [RemainingNodes, setRemainingNodes] = useState([]);
  const [IsPropertiesLoad, setIsPropertiesLoad] = useState(false);
  const [ActivityConfigLoading, setActivityConfigLoading] = useState(false);
  const [IsSave, setIsSave] = useState(false);
  const [IsEscalate, setIsEscalate] = useState(true);
  const [IsEscalate1, setIsEscalate1] = useState(false);
  const [IsEscalate2, setIsEscalate2] = useState(false);
  const [IsEscalate3, setIsEscalate3] = useState(false);
  const [IsEscalate4, setIsEscalate4] = useState(false);
  const [IsEscalate5, setIsEscalate5] = useState(false);
  const [IsEscalate6, setIsEscalate6] = useState(false);
  const [IsSubmitworkflow, setIsSubmitworkflow] = useState(false);
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedSubprocessName, setselectedSubprocessName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonDisable, setButtonDisable] = useState(false);
  const [IsWorkflowStatePannel, setIsWorkflowStatePannel] = useState(false);
  const [IsWorkflowStatePannelButton, setIsWorkflowStatePannelButton] =
    useState(false);
  const [IsPublish, setIsPublish] = useState(false);
  const [IssaveWorkflow, setIssaveWorkflow] = useState(false);
  const [OriginalElement, setOriginalElement] = useState();
  const [IdDeleteNode, setIdDeleteNode] = useState(false);
  const [IsConfirmationOnChange, setIsConfirmationOnChange] = useState(true);
  const [IsUserDefinedStatus, setIsUserDefinedStatus] = useState(true);
  const [SelectedWorkflowStateId, setSelectedWorkflowStateId] = useState("");
  const [SelectedWorkflowStateName, setSelectedWorkflowStateName] =
    useState("");
  const [SelectWorkAllocationMode, setSelectWorkAllocationMode] = useState("");
  const [SelectedWorkflowStatusId, setSelectedWorkflowStatusId] = useState("");
  const [SelectedWorkflowStatusName, setSelectedWorkflowStatusName] =
    useState("");
  const [IsDefaultStatusSetforScreen, setIsDefaultStatusSetforScreen] =
    useState(true);
  const [AllSequenceFlow, setAllSequenceFlow] = useState();
  const [AllocationMode, SetAllocationMode] = useState("");
  const [WorkItemstatusIds, setWorkItemstatusIds] = useState([]);
  const [subProcessWorkActivityConfigID, setsubProcessWorkActivityConfigID] =
    useState(0);
  const [ActiveStandardTime, setActiveStandardTime] = useState(0);
  const [autoAssignment, setautoAssignment] = useState(false);
  const [includeErrorCount, setincludeErrorCount] = useState(false);
  const [errorCapture, seterrorCapture] = useState(false);
  const [allocationModeOnRework, setallocationModeOnRework] = useState(false);
  const [sendMail, setsendMail] = useState(false);
  const [ccMailDuration, setccMailDuration] = useState(0);
  const [includeWeekend, setincludeWeekend] = useState(false);
  const [sendMailToUnassignedWorkItems, setsendMailToUnassignedWorkItems] =
    useState(false);
  const [consolidatedMail, setconsolidatedMail] = useState(false);
  const [noOfEscalations, setnoOfEscalations] = useState(0);
  const [consolidatedEmailConfigID, setconsolidatedEmailConfigID] =
    useState("");
  const [nonConsolidatedEmailConfigID, setnonConsolidatedEmailConfigID] =
    useState("");
  const [unassignedEmailConfigID, setunassignedEmailConfigID] = useState("");
  const [activityName, setactivityName] = useState("");
  const [intCCEmployees, setintCCEmployees] = useState([]);
  const [mon, setMon] = useState(false);
  const [tue, setTue] = useState(false);
  const [wed, setWed] = useState(false);
  const [thu, setThu] = useState(false);
  const [fri, setFri] = useState(false);
  const [sat, setSat] = useState(false);
  const [sun, setSun] = useState(false);
  const [sendMail1, setsendMail1] = useState("");
  const [interval1, setinterval1] = useState("");
  const [numberOfMail1, setnumberOfMail1] = useState("");
  const [sendMail2, setsendMail2] = useState("");
  const [interval2, setinterval2] = useState("");
  const [numberOfMail2, setnumberOfMail2] = useState("");
  const [AssignedEmployee1, setAssignedEmployee1] = useState([]);
  const [AssignedEmployee2, setAssignedEmployee2] = useState([]);
  const [AssignedEmployee3, setAssignedEmployee3] = useState([]);
  const [AssignedEmployee4, setAssignedEmployee4] = useState([]);
  const [AssignedEmployee5, setAssignedEmployee5] = useState([]);
  const [AssignedEmployee6, setAssignedEmployee6] = useState([]);
  const [sendMail3, setsendMail3] = useState("");
  const [interval3, setinterval3] = useState("");
  const [numberOfMail3, setnumberOfMail3] = useState("");
  const [sendMail4, setsendMail4] = useState("");
  const [interval4, setinterval4] = useState("");
  const [numberOfMail4, setnumberOfMail4] = useState("");
  const [sendMail5, setsendMail5] = useState("");
  const [interval5, setinterval5] = useState("");
  const [numberOfMail5, setnumberOfMail5] = useState("");
  const [sendMail6, setsendMail6] = useState("");
  const [interval6, setinterval6] = useState("");
  const [numberOfMail6, setnumberOfMail6] = useState("");
  const [emailTemplates, setEmailTemplates] = useState([]);

  const containerRef = useRef(null);
  const bpmnModeler = useRef(null);
  const propertiesPanelRef = useRef(null);
  const EndShapeRef = useRef(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  let isNewTaskAdded = false;
  let isInitialLoad = true;
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const sleep = (milliseconds) => {
    new Promise((resolve) => {
      return setTimeout(resolve, milliseconds);
    });
  };
  useEffect(() => {
    if (IschangeWorkflowState) {
      const element = CurrentElement;
      const CurrentWorkflowStatuses = WorkflowStatuses;
      let InvalidsequenceFlowIds = [];
      let validsequenceFlowIds = [];
      if (CurrentElement.di.stroke === "#ff0000") {
        var modeling = bpmnModeler.current.get("modeling");
        modeling.setColor([CurrentElement], {
          stroke: "black",
          //fill: "yellow",
        });
        //validsequenceFlowIds = [...validsequenceFlowIds, CurrentElement];
        //CurrentElement.di.stroke="black";
      }
      if (CurrentElement.di.bpmnElement.outgoing !== undefined) {
        for (
          let i = 0;
          i < CurrentElement.di.bpmnElement.outgoing.length;
          i++
        ) {
          if (
            CurrentWorkflowStatuses.find(
              (obj) =>
                obj.statusName === element.di.bpmnElement.outgoing[i].name
            ) == undefined
          ) {
            InvalidsequenceFlowIds = [
              ...InvalidsequenceFlowIds,
              CurrentElement.di.bpmnElement.outgoing[i].id,
            ];
          } else {
            validsequenceFlowIds = [
              ...validsequenceFlowIds,
              CurrentElement.di.bpmnElement.outgoing[i].id,
            ];
          }
        }
      }

      var modeling = bpmnModeler.current.get("modeling");
      for (let i = 0; i < InvalidsequenceFlowIds.length; i++) {
        const elementRegistry = bpmnModeler.current.get("elementRegistry");
        const sequenceFlowElement = elementRegistry.filter(function (element) {
          return (
            element.businessObject &&
            element.businessObject.$type === "bpmn:SequenceFlow" &&
            element.id === InvalidsequenceFlowIds[i]
          );
        })[0];
        if (sequenceFlowElement) {
          modeling.setColor([sequenceFlowElement], {
            stroke: "red",
            //fill: "yellow",
          });
        }
      }
      for (let i = 0; i < validsequenceFlowIds.length; i++) {
        //const elementRegistry = bpmnModeler.current.get("elementRegistry");
        // const sequenceFlowElement = elementRegistry.filter(function (element) {
        //   return (
        //     element.businessObject &&
        //     element.businessObject.$type === "bpmn:SequenceFlow" &&
        //     element.id === validsequenceFlowIds[i]
        //   );
        // })[0];

        modeling.setColor([validsequenceFlowIds[i]], {
          stroke: "black",
          //fill: "yellow",
        });
      }
      setIschangeWorkflowState(false);
    }
  }, [IschangeWorkflowState]);
  useEffect(() => {
    sessionStorage.setItem("IssaveWorkflow", false);
    sessionStorage.setItem("IsEscalate1", false);
    sessionStorage.setItem("IsEscalate2", false);
    sessionStorage.setItem("IsEscalate3", false);
    sessionStorage.setItem("IsEscalate4", false);
    sessionStorage.setItem("IsEscalate5", false);
    sessionStorage.setItem("IsEscalate6", false);
    setIsDashboardload(true);
    if (!IsSubmitworkflow) {
      FetchDefaultValueForSelectedSubprocess(
        sessionStorage.getItem("selectedDepartment"),
        sessionStorage.getItem("selectedLocation"),
        sessionStorage.getItem("ProcessId")
      );
      FetchDepartmentbyGUID();
    }
    WorkAcitivity();
    FetchWorkflowStatesBySubProcessID();
    GetActivitiesBySubProcess();
    GetWorkflow();
    GetEmailTemplates();

    sessionStorage.setItem("IsPublished", "false");
    bpmnModeler.current = new BpmnJS({
      container: containerRef.current,
      additionalModules: [
        BpmnPropertiesPanelModule,
        BpmnPropertiesProviderModule,
        CamundaPlatformPropertiesProviderModule,
        PaletteModule,
      ],
      moddleExtensions: {
        camunda: CamundaBpmnModdle,
      },
      palette: {
        parent: "#palette",
        layout: {
          columns: 1,
        },
      },
    });
    bpmnModeler.current.importXML(InitialXml);
    //bpmnModeler.current.createDiagram(InitialXml);
    document.getElementsByClassName(
      "entry bpmn-icon-participant"
    )[0].style.display = "none";
    document.getElementsByClassName("entry bpmn-icon-group")[0].style.display =
      "none";
    document.getElementsByClassName(
      "entry bpmn-icon-data-store"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "entry bpmn-icon-data-object"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "entry bpmn-icon-subprocess-expanded"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "entry bpmn-icon-intermediate-event-none"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "entry bpmn-icon-connection-multi"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "entry bpmn-icon-space-tool"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "entry bpmn-icon-lasso-tool"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "entry bpmn-icon-hand-tool"
    )[0].style.display = "none";
    const eventBus = bpmnModeler.current.get("eventBus");
    const contextPad = bpmnModeler.current.get("contextPad");
    eventBus.on("commandStack.shape.delete.preExecute", (event) => {
      if (event.context.shape.type === "bpmn:Task") {
        sessionStorage.removeItem("DeletedNodeId");
        sessionStorage.setItem(
          "DeletedNodeId",
          event.context.shape.id.split("_")[2]
        );
      }
      // Listen for sequence flow creation
      // eventBus.on("commandStack.connection.create.postExecuted", (event) => {
      //   ;
      //   const context = event.context;
      //   const target = context.target;
      //   const elementRegistry = bpmnModeler.get("elementRegistry");
      //   // Check if the target node is named "Client"
      //   const targetElement = elementRegistry.get(target.id);
      //   const targetBusinessObject = targetElement.businessObject;
      //   ;
      //   if (
      //     targetBusinessObject.name === "Client" &&
      //     targetBusinessObject.incoming.length > 0
      //   ) {
      //     addEndEvent(targetElement);
      //   }
      // });
      // const deletedShapes = event.context.shapes || [event.context.shape]; // Handle single and multiple shapes
      // const deletedIds = deletedShapes.map((shape) => shape.id);
      // //setDeletedElementIds(deletedIds);
      // deletedShapes.forEach((shape) => {
      //   const bpmnElement = shape.businessObject;
      //   const incomingFlows = bpmnElement.incoming || [];
      //   const outgoingFlows = bpmnElement.outgoing || [];
      //   const flowsToDelete = [...incomingFlows, ...outgoingFlows];
      //   const flowDetails=AllSequenceFlow;
      // });
    });
    eventBus.on("commandStack.shape.delete.preExecute", handleElementDeletion);
    eventBus.on("import.render.complete", () => {
      const elementRegistry = bpmnModeler.current.get("elementRegistry");
      const allElements = elementRegistry.getAll();
      // Check if at least one node exists with the name "client" and its incoming flow is named "complete"
      let hasClientWithCompleteIncoming = false;

      for (const element of allElements) {
        const { businessObject } = element;
        if (businessObject.name === "Client") {
          if (
            businessObject.incoming &&
            businessObject.incoming.some(
              (incoming) => (incoming.name === "Complete"||incoming.name === "Completed")
            )
          ) {
            hasClientWithCompleteIncoming = true;
            break;
          }
        }
      }

      if (!hasClientWithCompleteIncoming) {
        setIsCleintCompleteExist(false);
      } else {
        setIsCleintCompleteExist(true);
      }
    });
    eventBus.on("create.end", (event) => {
      const elementRegistry = bpmnModeler.current.get("elementRegistry");
      const newallElements = elementRegistry.getAll();
      //modeling.removeElements([element]);
    });
    eventBus.on("element.changed", (event) => {
      onDataSend(false);

      const element = event.element;
      const modeling = bpmnModeler.current.get("modeling");
      const elementRegistry = bpmnModeler.current.get("elementRegistry");
      const elementFactory = bpmnModeler.current.get("elementFactory");
      const allElements = elementRegistry.getAll();
      //checking for incoming and outgoing
      for (const element of allElements) {
        const { businessObject } = element;
        if (businessObject.name === "Client" && businessObject.incoming) {
          const hasCompleteIncoming = businessObject.incoming.some(
            (incoming) => incoming.name === "Complete"
          );
          if (hasCompleteIncoming) {
            //const endEvent = null;
            //const targetElement = elementRegistry.get(target.id);
            if (EndShapeRef.current == false) {
              const endEvent = addEndEvent(
                element,
                elementFactory,
                modeling,
                elementRegistry
              );
              //setEndEvent(endEvent)
            }
            if (!EndConnectionCreated) {
              //createConnection(element, EndEvent, modeling);
            }
            setIsCleintCompleteExist(true);
            //modeling.removeElements([element]);
            const outgoingConnections = businessObject.outgoing || [];
            outgoingConnections.forEach((outgoing) => {
              const outgoingElement = elementRegistry.get(
                outgoing.targetRef.id
              );
              //modeling.removeElements([outgoingElement]);
              // addToast("Cant add flow after Client(Complete)", {
              //   appearance: "error",
              //   autoDismiss: true,
              // });
            });
            break;
          }
        }
      }
      //Checking if the flow connecting to upload
      const { businessObject } = element;
      if (businessObject.$type === "bpmn:SequenceFlow") {
        const source = element.source;
        const target = element.target;
        if (target && target.businessObject.name === "Upload") {
          modeling.removeElements([element]);
          addToast("Cannot add flows to an upload.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }

      setCurrentElement(element);
      setCurrentWorkflowState(element.businessObject.name);
      if (!element.parent) {
        setIdDeleteNode(true);
        if (sessionStorage.getItem("isDeleteNode")) {
          DeleteNodeOrEdge();
        }
      } else if (
        element &&
        element.type &&
        element.type.startsWith("bpmn:Task") &&
        element.id.indexOf("Process_1") === -1
      ) {
        setshapeId(element.id);
        setCurrentWorkflowState(element.businessObject.name);
        handleNodeClick(element);
      }
      // }
    });
    eventBus.on("element.dblclick", (event) => {
      setIsWorkflowStatePannel(true);
      const element = event.element;
      setCurrentElement(element);
      setCurrentWorkflowState(element.businessObject.name);
      if (element && element.type === "label") {
        // Prevent the default behavior (label editing)
        event.preventDefault();
      }
      // Check if the element is a shape (type should be 'bpmn:Task' or other BPMN shape types)
      if (
        element &&
        element.type &&
        element.type.startsWith("bpmn:Task") &&
        element.id.indexOf("Process_1") === -1
      ) {
        setIsWorkflowStatePannelButton(true);
        setshapeId(element.id);
        sessionStorage.setItem(
          "SeletedBpmnElement",
          element.di.bpmnElement.name
        );
        setactivityName(element.di.bpmnElement.name);
        setCurrentWorkflowState(element.businessObject.name);
        handleNodeClick(element);

        //setIsWorkflowStatePannel(false);
      }
    });
    eventBus.on("element.click", (event) => {
      const element = event.element;
      debugger;
      if (
        element.id == "StartEvent_1" ||
        element.id == "Flow_07yodxb" ||
        (element.id == "Activity_0fuj9dl_8" && element.outgoing.length > 0) ||
        element.type == "bpmn:EndEvent"
      ) {
        if (event.originalEvent) {
          event.originalEvent.stopPropagation();
          event.originalEvent.preventDefault();
          // Clear selection to ensure nothing is selected
          //bpmnModeler.current.get("selection").clear();

          // Close the context pad to hide interaction options
          contextPad.close();
          addToast("Cant access this flow element", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        const { businessObject } = element;
        console.log(businessObject);
        if(businessObject.name == "Upload") {
          alert("Warning! Please don't delete Upload otherwise the rest of the workflow will need to be deleted as well!");
        }
        let sequenceFlow = [];
        // businessObject.incoming.map(e=>{
        //
        //   sequenceFlow=[...sequenceFlow,e];
        // });
        // businessObject.outgoing.map(e=>{
        //
        //   sequenceFlow=[...sequenceFlow,e];
        // });
        //sequenceFlow.push(businessObject.incoming[0].id)
        //sequenceFlow.push(businessObject.outgoing[0].id)
        setAllSequenceFlow(businessObject);
        setIsDefaultStatusSetforScreen(true);
        setCurrentElement(element);
        //setOriginalElement(element);
        setCurrentWorkflowState(element.businessObject.name);
        if (element.businessObject.name !== undefined) {
          setSelectedWorkflowStatusName(element.businessObject.name);
        }
        // Check if the element is a shape (type should be 'bpmn:Task' or other BPMN shape types)
        let allFlowElements = [];
        let allTaskElements = [];
        if (element.type === "bpmn:SequenceFlow") {
          dispatch(resetFlowIds());
          allFlowElements = [...allFlowElements, element.id];
        } else if (element.type === "bpmn:Task") {
          dispatch(resetFlowIds());
          if (element.di.bpmnElement.incoming !== undefined) {
            element.di.bpmnElement.incoming.forEach((element) => {
              allFlowElements = [...allFlowElements, element.id];
            });
          }
          if (element.di.bpmnElement.outgoing !== undefined) {
            element.di.bpmnElement.outgoing.forEach((element) => {
              allFlowElements = [...allFlowElements, element.id];
            });
          }
        }
        allFlowElements.forEach((flowele) => {
          let targetFlowElement =
            element.di.bpmnElement.$parent.flowElements.filter(
              (ele) => ele.id == flowele
            );
          if (targetFlowElement[0].targetRef.id === element.id) {
            let elementToCheck = targetFlowElement[0].sourceRef;
            if (
              elementToCheck.outgoing === undefined ||
              elementToCheck.outgoing.length < 1
            ) {
              let count = 0;
              let incomingElement = elementToCheck.incoming;
              if (incomingElement !== undefined) {
                for (let i = 0; i < incomingElement.length; i++) {
                  if (incomingElement[i].sourceRef.id !== element.id) {
                    count++;
                  }
                }
                if (count == 0) {
                  allTaskElements = [
                    ...allTaskElements,
                    targetFlowElement[0].sourceRef.id,
                  ];
                }
              }
            }
          } else if (targetFlowElement[0].sourceRef.id === element.id) {
            let elementToCheck = targetFlowElement[0].targetRef;
            if (
              elementToCheck.outgoing === undefined ||
              elementToCheck.outgoing.length < 1
            ) {
              let count = 0;
              let incomingElement = elementToCheck.incoming;
              if (incomingElement !== undefined) {
                for (let i = 0; i < incomingElement.length; i++) {
                  if (incomingElement[i].sourceRef.id !== element.id) {
                    count++;
                  }
                }
                if (count == 0) {
                  allTaskElements = [
                    ...allTaskElements,
                    targetFlowElement[0].targetRef.id,
                  ];
                }
              }
            }
          }
        });
        sessionStorage.setItem("isDeleteNode", true);
        dispatch(resetTaskIds());
        dispatch(addselectedTaskId(allTaskElements));
        dispatch(addselectedFlowId(allFlowElements));
        if (
          element &&
          element.type &&
          element.type.startsWith("bpmn:") &&
          !element.type.startsWith("bpmn:Task") &&
          element.id.indexOf("Process_1") === -1
        ) {
          setshapeId(element.id);
          setCurrentWorkflowState(element.businessObject.name);
          handleNodeClick(element);
        }
      }
    });
    setIsDashboardload(false);
    GetEmployeesWithProcessAccess();
    publishWorkflow();
    return () => {
      bpmnModeler.current.destroy();
    };
  }, [IsSubmitworkflow || reference.SubprocessID]);
  // Function to add an end event
  const addEndEvent = (source, elementFactory, modeling, elementRegistry) => {
    EndShapeRef.current = true;
    setEndEventCreated(true);
    const sourceElement = elementRegistry.get(source.id);
    const sourceBounds =
      sourceElement && sourceElement.width && sourceElement.height
        ? {
            x: sourceElement.x,
            y: sourceElement.y,
            width: sourceElement.width,
            height: sourceElement.height,
          }
        : {
            x: source.businessObject.di.bounds.x,
            y: source.businessObject.di.bounds.y,
            width: source.businessObject.di.bounds.width,
            height: source.businessObject.di.bounds.height,
          };

    const position = {
      x: sourceBounds.x + sourceBounds.width + 100, // Adjust position as needed
      y: sourceBounds.y + sourceBounds.height / 2,
    };

    const endEvent = elementFactory.createShape({
      type: "bpmn:EndEvent",
    });
    setEndEvent(endEvent);
    debugger;
    modeling.createShape(endEvent, position, source.parent);
    //setEndEvent(endEvent)
    //Return the newly created end event
    //return endEvent;
  };

  // Function to create a connection between two elements
  useEffect(() => {
    debugger;
    if (EndEvent != null) {
      const modeling = bpmnModeler.current.get("modeling");
      const elementRegistry = bpmnModeler.current.get("elementRegistry");
      const allElements = elementRegistry.getAll();
      for (const element of allElements) {
        const { businessObject } = element;
        if (businessObject.name === "Client" && businessObject.incoming) {
          const hasCompleteIncoming = businessObject.incoming.some(
            (incoming) => incoming.name === "Complete"
          );
          if (hasCompleteIncoming) {
            debugger;

            const source = elementRegistry.get(element.id);
            const target = elementRegistry.get(EndEvent.id);
            modeling.connect(source, target);
            //EndShapeRef.current = false;
            //setEndEvent(null);
          }
        }
      }
    }
  }, [EndEvent]);
  // const createConnection = (source, target, modeling) => {
  //   ;
  //   const endEvent = EndEvent;
  //   modeling.createConnection(source, endEvent, {
  //     type: "bpmn:SequenceFlow",
  //   });
  //   setEndConnectionCreated(true);
  // };
  const handleElementDeletion = (event) => {
    const modeling = bpmnModeler.current.get("modeling");
    const flowDetails = AllSequenceFlow;
    setDeletedNodeId(event.context.shape.id);
    for (let i = 0; i < event.context.shape.incoming.length; i++) {
      modeling.removeElements([event.context.shape.incoming[i]]);
      console.log(event.context.shape.incoming[i]);
    }
    for (let i = 0; i < event.context.shape.outgoing.length; i++) {
      modeling.removeElements([event.context.shape.outgoing[i]]);
      console.log(event.context.shape.incoming[i]);
    }
  };
  const handleElementPostDeletion = (event) => {
    //
    const element = event.context.shape;
  };
  const handleDelete = () => {
    var deletedFlowIds = store.getState().selectedFlowIds[0];
    console.log(deletedFlowIds);
  };
  const handleConfirmationOnChange = () => {
    setIsConfirmationOnChange(!IsConfirmationOnChange);
  };
  const handleUserDefinedStatusOnChange = () => {
    setIsUserDefinedStatus(!IsUserDefinedStatus);
  };
  const DeleteNodeOrEdge = async () => {
    debugger;
    sessionStorage.setItem("isDeleteNode", false);
    let allFlowElements = [];
    const elementRegistry = bpmnModeler.current.get("elementRegistry");
    const allElements = elementRegistry.getAll();
    if (allElements.filter((e) => e.type == "bpmn:EndEvent").length == 0) {
      EndShapeRef.current = false;
      setEndEvent(null);
    }
    const nodes = allElements.filter((element) =>
      element.type.startsWith("bpmn:")
    );
    const RemainingNodes = nodes.filter(
      (element) =>
        element.type == "bpmn:Task" && element.di.bpmnElement.name != "Upload"
    );
    const PreviousNodesBeforDelete = originalElements;
    const ConfigIdForDelete = sessionStorage.getItem(
      "ActivityConfigIdForDelete"
    );
    const element = CurrentElement;
    const elementArray = Array.from(
      document.getElementsByClassName("djs-element djs-shape")
    );
    allFlowElements = store.getState().selectedFlowIds[0];
    allFlowElements = allFlowElements.filter(
      (item, index) => allFlowElements.indexOf(item) === index
    );
    allFlowElements.forEach((element) => {
      const filteredElement = elementArray.filter((ele) => {
        return element === ele.getAttribute("data-element-id");
      });
      var canvas = bpmnModeler.current.get("canvas");
      //canvas.removeShape(filteredElement[0]);
    });
    allFlowElements = store.getState().selectedFlowIds[1];
    const result = await bpmnModeler.current.saveXML();
    const deletedActivityId = sessionStorage.getItem("DeletedNodeId");
    let body = {
      SubProcessID: reference.SubprocessID,
      WorkflowXml: result.xml,
      SubProcessWorkActivityConfigID: +deletedActivityId,
      WorkflowElementID: allFlowElements,
    };

    let loginUrl = getAPIURL(REACT_APP_SUBPROCESSWORKFLOWDELETEWORKFLOWCONFIG);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem("IsPublished", "false");
          setIsPublish(false);
          setIschangeWorkflowState(true);
          addToast("Deleted successfully!!", {
            appearance: "success",
            autoDismiss: true,
          });
          sessionStorage.setItem("isDeleteNode", true);
          //setIdDeleteNode(false)
          setShow(false);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const handleOnchangeWorkflowStates = (e) => {
    setActivityId(e.target.value);
    setactivityName(e.target.options[e.target.selectedIndex].text);
    setCurrentWorkflowState(e.target.options[e.target.selectedIndex].text);
    setSelectedWorkflowStateId(e.target.value);
    setSelectedWorkflowStateName(e.target.options[e.target.selectedIndex].text);
    GetWorkActivityConfigOnWorflowStateChange(e.target.value);
  };
  const handleOnchangeAllocationMode = (e) => {
    setSelectWorkAllocationMode(e.target.value);
  };
  const getActivityStandardTime = (e) => {
    setActiveStandardTime(e.target.value);
  };
  const getErrorCapture = (e) => {
    seterrorCapture(!errorCapture);
  };
  const getAutoAssignmentStatus = (e) => {
    setautoAssignment(!autoAssignment);
  };
  const getErrorCountReport = (e) => {
    setincludeErrorCount(!includeErrorCount);
  };
  const getAllocationmodeonRework = (e) => {
    setallocationModeOnRework(!allocationModeOnRework);
  };
  const modifyWorkflowStatuses = (e) => {
    var isExist = WorkItemstatusIds.filter((ids) => ids == +e.target.value);
    if (isExist.length > 0) {
      var newArray = WorkItemstatusIds.filter((ele) => ele !== +e.target.value);
      setWorkItemstatusIds(newArray);
    } else {
      var newArray = [...WorkItemstatusIds, +e.target.value];
      setWorkItemstatusIds(newArray);
    }
  };
  const handleOnchangeWorkflowStatus = (e) => {
    sessionStorage.setItem("SelectedWorkflowStatusId", e.target.value);
    setSelectedWorkflowStatusId(e.target.value);

    setSelectedWorkflowStatusName(
      e.target.options[e.target.selectedIndex].text
    );
  };
  const saveWorkflowUpdatedXml = async (isClicked) => {
    let workflowStates = [];
    const result = await bpmnModeler.current.saveXML();
    let body = {
      SubProcessID: reference.SubprocessID,
      WorkflowXml: result.xml,
      workflowStateModelList: workflowStates,
      IsUpdateXml: true,
    };
    let loginUrl = getAPIURL(REACT_APP_SUBPROCESSWORKFLOWUPDATESTATE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setIsLoading(false);

          setButtonDisable(false);
          if (isClicked) {
            onDataSend(true);
            setIsPlublishedClicked(false);
          }
          var modeling = bpmnModeler.current.get("modeling");
          const currentNode = CurrentElement;
          modeling.setColor([CurrentElement], {
            stroke: "black",
            ////fill: "yellow",
          });
          setShow(false);
          addToast("Configuration saved successfully.", {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (result.status === 401) {
          setIsLoading(false);
          setShow(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        setShow(false);
        setIsLoading(false);
        console.log("ERROR: ", error);
      });
  };
  const changeWorkflowStatePanel = async () => {
    let preElename = CurrentWorkflowState;
    let isExist =
      WorkflowStates.filter((e) => e.workflowStateName === CurrentWorkflowState)
        .length > 0;
    //CurrentWorkflowState = isExist ? CurrentWorkflowState : activityName;
    if (isExist) {
      if (ActiveStandardTime > 0 && SelectWorkAllocationMode != "") {
        setIsLoading(true);
        setButtonDisable(true);
        setShow(false);
        let finalArray = [];
        const newColor = "#ff0000";
        const element = CurrentElement;

        let selectedWorkflowStateId =
          SelectedWorkflowStateId == ""
            ? element.di.bpmnElement.id.split("_")[
                element.di.bpmnElement.id.split("_").length - 1
              ]
            : SelectedWorkflowStateId;
        let selectedWorkflowStateName =
          SelectedWorkflowStateName == ""
            ? element.di.bpmnElement.name
            : SelectedWorkflowStateName;
        GetWorkItemStatusesByWorkflowStateIDSubProcessID(
          selectedWorkflowStateId
        );
        let workflowStates = [];
        setactivityName(element.di.bpmnElement);
        if (element.di.bpmnElement.incoming !== undefined) {
          element.di.bpmnElement.incoming.forEach((element) => {
            const obj = {
              workflowElementID: element.id,
              currentStateID: 0,
              nextStateID: selectedWorkflowStateId,
            };
            workflowStates = [...workflowStates, obj];
          });
        }

        if (element.di.bpmnElement.outgoing !== undefined) {
          element.di.bpmnElement.outgoing.forEach((element) => {
            const obj = {
              workflowElementID: element.id,
              currentStateID: selectedWorkflowStateId,
              nextStateID: 0,
            };
            workflowStates = [...workflowStates, obj];
          });

          const result = await bpmnModeler.current.saveXML();
          let body = {
            SubProcessID: reference.SubprocessID,
            WorkflowXml: result.xml,
            workflowStateModelList: workflowStates,
            IsUpdateXml: false,
          };
          let loginUrl = getAPIURL(REACT_APP_SUBPROCESSWORKFLOWUPDATESTATE);
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization:
                "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status == 200) {
                setIschangeWorkflowState(true);
                setIsPublish(false);
                sessionStorage.setItem("IsPublished", "false");
                var hidePanel = true;
                var modeling = bpmnModeler.current.get("modeling");
                var newElementID =
                  CurrentElement.id + "_" + selectedWorkflowStateId;
                var elementIDArray = CurrentElement.id.split("_");
                if (elementIDArray.length >= 2) {
                  newElementID =
                    elementIDArray[0] +
                    "_" +
                    elementIDArray[1] +
                    "_" +
                    selectedWorkflowStateId;
                }

                modeling.updateLabel(CurrentElement, selectedWorkflowStateName);
                modeling.updateProperties(CurrentElement, {
                  id: newElementID,
                  name: selectedWorkflowStateName,
                  //ActivityConfigId:subProcessWorkActivityConfigID
                });

                setCurrentElementNewID(newElementID);
                setShow(false);
              } else if (result.status === 401) {
                //unauthorized
                setIsLoading(false);
                setShow(false);
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                navigate("/");
              }
            })
            .catch((error) => {
              //if (result.status === 401) {
              console.log("ERROR: ", error);
              //navigate("/");
              //}
            });
        }
        var modeling = bpmnModeler.current.get("modeling");
        var newElementID = CurrentElement.id + "_" + selectedWorkflowStateId;
        var elementIDArray = CurrentElement.id.split("_");
        if (elementIDArray.length >= 2) {
          newElementID =
            elementIDArray[0] +
            "_" +
            elementIDArray[1] +
            "_" +
            selectedWorkflowStateId;
        }

        modeling.updateLabel(CurrentElement, selectedWorkflowStateName);
        modeling.updateProperties(CurrentElement, {
          id: newElementID,
          name: selectedWorkflowStateName,
          //ActivityConfigId:subProcessWorkActivityConfigID
        });
        setCurrentElementNewID(newElementID);
        saveWorkActivityConfig();
      } else {
        handleClose();
        addToast("Work Allocation Cant be Empty", {
          appearance: "error",
          autoDismiss: true,
        });
        addToast("Activity Standard Time Cant be 0", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      if (!isExist) {
        handleClose();
        addToast(preElename + " is Invald so Kindly select a valid activity", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };
  const saveWorkActivityConfig = () => {
    const currentEle = CurrentElement;
    let reminderNotificationList = [];
    if (mon) {
      reminderNotificationList.push(0);
    }
    if (tue) {
      reminderNotificationList.push(1);
    }
    if (wed) {
      reminderNotificationList.push(2);
    }
    if (thu) {
      reminderNotificationList.push(3);
    }
    if (fri) {
      reminderNotificationList.push(4);
    }
    if (sat) {
      reminderNotificationList.push(5);
    }
    if (sun) {
      reminderNotificationList.push(6);
    }
    let ActivityName =
      activityName == ""
        ? sessionStorage.getItem("SeletedBpmnElement")
        : activityName;
    let body = {
      SubProcessID: reference.SubprocessID,
      ActivityID: +ActivityId.split("_")[ActivityId.split("_").length - 1],
      ActivityName: ActivityName,
      PersonID: sessionStorage.getItem("PersonID"),
      SubProcessWorkActivityConfigID: subProcessWorkActivityConfigID,
      WorkAllocationMode: SelectWorkAllocationMode,
      ActivityStandardTime: ActiveStandardTime,
      AllowErrorCapture: errorCapture,
      AllowAutoAssignment: autoAssignment,
      ErrorCount: includeErrorCount,
      AllocationModeOnRework: allocationModeOnRework,
      WorkitemStatus: WorkItemstatusIds,
      SendReminderNotification: sendMail,
      ReminderotificationDays: ccMailDuration,
      SendReminderNotificationOn: reminderNotificationList,
      CCNotificationTo: intCCEmployees,
      NoOfReminderEscaltaion: "" + noOfEscalations,
      IncludedWeekend: includeWeekend,
      ConsoilidatedMail: consolidatedMail,
      MailForUnassignedWorkitems: sendMailToUnassignedWorkItems,
      ConsolidatedMailTemplate:
        consolidatedEmailConfigID == "" ? "0" : consolidatedEmailConfigID,
      NonConsolidatedMailTemplate:
        nonConsolidatedEmailConfigID == "" ? "0" : nonConsolidatedEmailConfigID,
      UnassignedMailTemplate:
        unassignedEmailConfigID == "" ? "0" : unassignedEmailConfigID,
      Level1Escalation:
        sessionStorage.getItem("IsEscalate1") == "true" ? true : false,
      SendMail1: "" + sendMail1,
      Interval1: "" + interval1,
      NumberOfMail1: "" + numberOfMail1,
      CC1: AssignedEmployee1,
      Level2Escalation:
        sessionStorage.getItem("IsEscalate2") == "true" ? true : false,
      SendMail2: "" + sendMail2,
      Interval2: "" + interval2,
      NumberOfMail2: "" + numberOfMail2,
      CC2: AssignedEmployee2,
      Level3Escalation:
        sessionStorage.getItem("IsEscalate3") == "true" ? true : false,
      SendMail3: "" + sendMail3,
      Interval3: "" + interval3,
      NumberOfMail3: "" + numberOfMail3,
      CC3: AssignedEmployee3,
      Level4Escalation:
        sessionStorage.getItem("IsEscalate4") == "true" ? true : false,
      SendMail4: "" + sendMail4,
      Interval4: "" + interval4,
      NumberOfMail4: "" + numberOfMail4,
      CC4: AssignedEmployee4,
      Level5Escalation:
        sessionStorage.getItem("IsEscalate5") == "true" ? true : false,
      SendMail5: "" + sendMail5,
      Interval5: "" + interval5,
      NumberOfMail5: "" + numberOfMail5,
      CC5: AssignedEmployee5,
      Level6Escalation:
        sessionStorage.getItem("IsEscalate6") == "true" ? true : false,
      SendMail6: "" + sendMail6,
      Interval6: "" + interval6,
      NumberOfMail6: "" + numberOfMail6,
      CC6: AssignedEmployee6,
      ActivityEscalationModDetails: ActivityEscalationMod,
      ExcludedWeeked: false,
    };
    let loginUrl = getAPIURL(REACT_APP_SAVEANDUPDATEACTIVITYCONFIG);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          saveWorkflowUpdatedXml();
        } else if (result.status === 401) {
          setIsLoading(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR: ", error);
        // if (error.name == "TypeError" && error.message == "Failed to fetch") {
        //   BodydetailsinLog(
        //     error.toString() + " FetchLocationClientsByGuid ",
        //     body
        //   );
        // }
      });
  };
  const handleNormalPathChanged = () => {
    setIsDefaultStatusSetforScreen(!IsDefaultStatusSetforScreen);
  };
  const saveWorkflow = async (data) => {
    setIsLoading(true);
    var newSelectedFlowStateId =
      SelectedWorkflowStatusId === ""
        ? sessionStorage.getItem("SelectedWorkflowStatusId")
        : SelectedWorkflowStatusId;
    var newElementID = CurrentElement.id + "_" + newSelectedFlowStateId;
    var elementIDArray = CurrentElement.id.split("_");
    if (elementIDArray.length >= 2) {
      newElementID =
        elementIDArray[0] +
        "_" +
        elementIDArray[1] +
        "_" +
        newSelectedFlowStateId;
    }

    const prevXmlResult = await bpmnModeler.current.saveXML();
    var modeling = bpmnModeler.current.get("modeling");
    modeling.updateLabel(CurrentElement, SelectedWorkflowStatusName);
    modeling.updateProperties(CurrentElement, {
      id: newElementID,
      name: SelectedWorkflowStatusName,
      //activityConfigId: subProcessWorkActivityConfigID,
    });
    setCurrentElementNewID(newElementID);
    const result = await bpmnModeler.current.saveXML();
    data["CurrentStateID"] = CurrentStateId;
    data["NextStateID"] = NextStateId;
    data["WorkflowElementID"] = newElementID;
    data["WorkflowXml"] = result.xml;
    //data["MilestoneList"] = milestoneList;
    data["SubProcessID"] = reference.SubprocessID;
    data["isDefaultStatusSetForScreen"] = true;
    let apiUrl = getAPIURL(REACT_APP_FETCH_SUBPROCESSWORKFLOWCREATE);
    let req = new Request(`${apiUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(data),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        setIsLoading(false);
        setShow(false);
        if (result.status == 200) {
          setShow(false);
          addToast("Configuration saved successfully.", {
            appearance: "success",
            autoDismiss: true,
          });

          setIschangeWorkflowState(true);
          sessionStorage.setItem("IsPublished", "false");
          setIsPublish(false);
          //sessionStorage.setItem("IssaveWorkflow", "false");
          //setIssaveWorkflow(false);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 400) {
          bpmnModeler.current.importXML(prevXmlResult.xml);
          addToast(result.message, {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (result.status === 500) {
          bpmnModeler.current.importXML(prevXmlResult.xml);
          addToast("The record may be already existed!!", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR: ", error);
      });
  };
  const updateElementProperties = (e, hidePanel) => {
    var modeling = bpmnModeler.current.get("modeling");
    var newElementID = CurrentElement.id + "_" + e.target.value;
    var elementIDArray = CurrentElement.id.split("_");
    if (elementIDArray.length >= 2) {
      newElementID =
        elementIDArray[0] + "_" + elementIDArray[1] + "_" + e.target.value;
    }

    modeling.updateLabel(
      CurrentElement,
      e.target.options[e.target.selectedIndex].text
    );
    modeling.updateProperties(CurrentElement, {
      id: newElementID,
      name: e.target.options[e.target.selectedIndex].text,
    });

    setCurrentElementNewID(newElementID);
    if (!IsWorkflowStatePannel) {
      setShow(!hidePanel);
    }
  };
  const WorkAcitivity = () => {
    //let queryString = reference.SubprocessID===undefined?sessionStorage.getItem("ProcessId"): reference.SubprocessID;
    let fetchUrl = getAPIURL(REACT_APP_FETCHPROCESSSUBPROCESSWORKACTIVITY);
    let req = new Request(`${fetchUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          let res = result.data;
          setWorkflowStates(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const FetchWorkflowStatus = () => {
    //let queryString = reference.SubprocessID===undefined?sessionStorage.getItem("ProcessId"): reference.SubprocessID;
    let fetchUrl = getAPIURL(REACT_APP_FETCHPROCESSSUBPROCESSWORKFLOWSTATUS);
    let req = new Request(`${fetchUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          let res = result.data;
          setWorkflowStatusesConfig(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const FetchWorkflowStatesBySubProcessID = () => {
    let queryString =
      reference.SubprocessID === undefined
        ? sessionStorage.getItem("ProcessId")
        : reference.SubprocessID;
    let fetchUrl =
      getAPIURL(REACT_APP_FETCH_GETWORKFLOWSTATESBYSUBPROCESSID) +
      "/" +
      queryString;
    let req = new Request(`${fetchUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          if (result.data.length <= 1) {
            //WorkAcitivity();
            FetchWorkflowStatus();
            setIsActivityConfig(true);
          } else {
            setIsActivityConfig(false);
            FetchWorkflowStatus();
            //setWorkflowStates(result.data);
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const handleNodeClick = (ele) => {
    debugger;
    console.log(ele);
    setActivityId(ele.id);
    setactivityName(ele.di.bpmnElement.name);
    setHideSequenceFlowConfig(false);
    if (ele.type === "bpmn:SequenceFlow") {
      if (ele.di.bpmnElement.sourceRef.name === "Upload") {
        setHideSequenceFlowConfig(true);
        setSequenceCanvas(true);
        setTaskCanvas(false);
        setShow(true);
      } else {
        if (
          ele.di.bpmnElement.sourceRef.id.split("_")[2] === undefined ||
          ele.di.bpmnElement.targetRef.id.split("_")[2] === undefined
        ) {
          setSequenceCanvas(false);
          setTaskCanvas(false);
          setShow(false);
          addToast("Previous Or Next State is Not Selected!!", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          setSequenceCanvas(true);
          setTaskCanvas(false);
          setShow(true);
          setCurrentStateId(ele.di.bpmnElement.sourceRef.id.split("_")[2]);
          setNextStateId(ele.di.bpmnElement.targetRef.id.split("_")[2]);
          GetWorkItemStatusesByWorkflowStateIDSubProcessID(
            ele.di.bpmnElement.sourceRef.id.split("_")[2]
          );
          setCurrentElementNewID(ele.id);
          GetWorkflowConfig(ele.id);
        }
      }
    } else if (ele.type === "bpmn:Task") {
      if (ele.di.bpmnElement.name !== "Upload") {
        ResetActivityConfig();
        GetWorkActivityConfig(ele.id);

        setTaskCanvas(true);
        setSequenceCanvas(false);
        setShow(true);
        setIsWorkflowStatePannelButton(false);
      } else {
        addToast("Upload Can't be modified!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      setSequenceCanvas(false);
      setTaskCanvas(false);
      setShow(false);
    }
  };
  const ResetActivityConfig = () => {
    setsubProcessWorkActivityConfigID(0);
    setSelectWorkAllocationMode("");
    setWorkItemstatusIds([]);
    SetAllocationMode("");
    setActiveStandardTime(0);
    setautoAssignment(false);
    setincludeErrorCount(false);
    seterrorCapture(false);
    setallocationModeOnRework(false);
    setsendMail(false);
    setccMailDuration(0);
    setincludeWeekend(false);
    setsendMailToUnassignedWorkItems(false);
    setconsolidatedMail(false);
    setnoOfEscalations(0);
    setconsolidatedEmailConfigID("");
    setnonConsolidatedEmailConfigID("");
    setunassignedEmailConfigID("");
    setactivityName("");
    setintCCEmployees([]);
    setMon(false);
    setTue(false);
    setWed(false);
    setThu(false);
    setFri(false);
    setSat(false);
    setSun(false);
    setActivityEscalationMod([]);
    setIsEscalate1(false);
    setsendMail1("");
    setAssignedEmployee1([]);
    setIsEscalate2(false);
    setsendMail2("");
    setAssignedEmployee2([]);
    setIsEscalate3(false);
    setsendMail3("");
    setAssignedEmployee3([]);
    setIsEscalate4(false);
    setsendMail5("");
    setAssignedEmployee5([]);
    setIsEscalate6(false);
    setsendMail6("");
    setAssignedEmployee6([]);
  };
  const GetWorkActivityConfigOnWorflowStateChange = (id) => {
    setIsLoading(true);
    setActivityConfigLoading(false);
    const activityId = id;
    let body = {
      SubProcessID: reference.SubprocessID,
      ActivityID: activityId,
      guid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(
      REACT_APP_SUBPROCESSWORKFLOWFETCHWORKACTIVITYCONFIG
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          let arrays = WorkflowStatuses;
          setsubProcessWorkActivityConfigID(
            result.data[0].subProcessWorkActivityConfigID
          );
          setSelectWorkAllocationMode(result.data[0].workItemAllocationMode);
          setWorkItemstatusIds(result.data[0].intWorkItemstatusID);
          SetAllocationMode(result.data[0].workItemAllocationMode);
          setActiveStandardTime(result.data[0].activityStandardTime);
          setautoAssignment(result.data[0].isAllowGroupAutoAssign);
          setincludeErrorCount(result.data[0].includeErrorCountInReport);
          seterrorCapture(result.data[0].allowErrorCapturing);
          setallocationModeOnRework(result.data[0].useAllocationModeOnRework);
          setsendMail(result.data[0].isSendEmailNotification);
          setccMailDuration(result.data[0].ccMailDuration);
          setincludeWeekend(result.data[0].includeWeekend);
          setsendMailToUnassignedWorkItems(
            result.data[0].sendMailToUnassignedWorkItems
          );
          setconsolidatedMail(result.data[0].isGroupByWorkItem);
          setnoOfEscalations(result.data[0].noOfEscalations);
          //
          setconsolidatedEmailConfigID(
            result.data[0].consolidatedEmailConfigID
          );
          setnonConsolidatedEmailConfigID(
            result.data[0].nonConsolidatedEmailConfigID
          );
          setunassignedEmailConfigID(result.data[0].unassignedEmailConfigID);
          setactivityName(result.data[0].activityName);

          //
          setintCCEmployees(result.data[0].intCCEmployees);
          let dayList = result.data[0].dayList;
          dayList.map((day) => {
            if (day == 0) {
              setMon(true);
            }
            if (day == 1) {
              setTue(true);
            }
            if (day == 2) {
              setWed(true);
            }
            if (day == 3) {
              setThu(true);
            }
            if (day == 4) {
              setFri(true);
            }
            if (day == 5) {
              setSat(true);
            }
            if (day == 6) {
              setSun(true);
            }
          });
          setIsLoading(false);
          setActivityConfigLoading(false);
        } else if (result.status === 500) {
          setIsLoading(false);
          setActivityConfigLoading(false);
        } else if (result.status === 401) {
          setIsLoading(false);
          setActivityConfigLoading(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setActivityConfigLoading(false);
        console.log("ERROR: ", error);
      });
  };
  const GetWorkActivityConfig = (id) => {
    setIsLoading(true);
    setActivityConfigLoading(true);
    const activityId = id.split("_")[id.split("_").length - 1];
    let body = {
      SubProcessID: reference.SubprocessID,
      ActivityID: activityId,
      guid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(
      REACT_APP_SUBPROCESSWORKFLOWFETCHWORKACTIVITYCONFIG
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem("IssaveWorkflow", false);
          sessionStorage.setItem("IsEscalate1", false);
          sessionStorage.setItem("IsEscalate2", false);
          sessionStorage.setItem("IsEscalate3", false);
          sessionStorage.setItem("IsEscalate4", false);
          sessionStorage.setItem("IsEscalate5", false);
          sessionStorage.setItem("IsEscalate6", false);
          let arrays = WorkflowStatuses;
          setsubProcessWorkActivityConfigID(
            result.data[0].subProcessWorkActivityConfigID
          );
          setSelectWorkAllocationMode(result.data[0].workItemAllocationMode);
          setWorkItemstatusIds(result.data[0].intWorkItemstatusID);
          SetAllocationMode(result.data[0].workItemAllocationMode);
          setActiveStandardTime(result.data[0].activityStandardTime);
          setautoAssignment(result.data[0].isAllowGroupAutoAssign);
          setincludeErrorCount(result.data[0].includeErrorCountInReport);
          seterrorCapture(result.data[0].allowErrorCapturing);
          setallocationModeOnRework(result.data[0].useAllocationModeOnRework);
          setsendMail(result.data[0].isSendEmailNotification);
          setccMailDuration(result.data[0].ccMailDuration);
          setincludeWeekend(result.data[0].includeWeekend);
          setsendMailToUnassignedWorkItems(
            result.data[0].sendMailToUnassignedWorkItems
          );
          setconsolidatedMail(result.data[0].isGroupByWorkItem);
          setnoOfEscalations(result.data[0].noOfEscalations);
          //
          setconsolidatedEmailConfigID(
            result.data[0].consolidatedEmailConfigID
          );
          setnonConsolidatedEmailConfigID(
            result.data[0].nonConsolidatedEmailConfigID
          );
          setunassignedEmailConfigID(result.data[0].unassignedEmailConfigID);
          setactivityName(result.data[0].activityName);

          //
          setintCCEmployees(result.data[0].intCCEmployees);
          let dayList = result.data[0].dayList;
          dayList.map((day) => {
            if (day == 0) {
              setMon(true);
            }
            if (day == 1) {
              setTue(true);
            }
            if (day == 2) {
              setWed(true);
            }
            if (day == 3) {
              setThu(true);
            }
            if (day == 4) {
              setFri(true);
            }
            if (day == 5) {
              setSat(true);
            }
            if (day == 6) {
              setSun(true);
            }
          });
          setIsLoading(false);
          setActivityConfigLoading(false);
        } else if (result.status === 400) {
          sessionStorage.setItem("IssaveWorkflow", false);
          sessionStorage.setItem("IsEscalate1", false);
          sessionStorage.setItem("IsEscalate2", false);
          sessionStorage.setItem("IsEscalate3", false);
          sessionStorage.setItem("IsEscalate4", false);
          sessionStorage.setItem("IsEscalate5", false);
          sessionStorage.setItem("IsEscalate6", false);
          setIsLoading(false);
          setActivityConfigLoading(false);
        } else if (result.status === 500) {
          sessionStorage.setItem("IssaveWorkflow", false);
          sessionStorage.setItem("IsEscalate1", false);
          sessionStorage.setItem("IsEscalate2", false);
          sessionStorage.setItem("IsEscalate3", false);
          sessionStorage.setItem("IsEscalate4", false);
          sessionStorage.setItem("IsEscalate5", false);
          sessionStorage.setItem("IsEscalate6", false);
          setIsLoading(false);
          setActivityConfigLoading(false);
        } else if (result.status === 401) {
          sessionStorage.setItem("IssaveWorkflow", false);
          sessionStorage.setItem("IsEscalate1", false);
          sessionStorage.setItem("IsEscalate2", false);
          sessionStorage.setItem("IsEscalate3", false);
          sessionStorage.setItem("IsEscalate4", false);
          sessionStorage.setItem("IsEscalate5", false);
          sessionStorage.setItem("IsEscalate6", false);
          setIsLoading(false);
          setActivityConfigLoading(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setActivityConfigLoading(false);
        console.log("ERROR: ", error);
      });
  };
  const GetWorkItemStatusesByWorkflowStateIDSubProcessID = (
    sourceWorkflowStateID
  ) => {
    let subprocessID =
      reference.SubprocessID === undefined
        ? sessionStorage.getItem("ProcessId")
        : reference.SubprocessID;
    const queryString = sourceWorkflowStateID + "/" + subprocessID;
    let fetchUrl =
      getAPIURL(
        REACT_APP_FETCH_GETWORKITEMSTATUESBYWORKFLOWSTATEIDSUBPROCESSID
      ) +
      "/" +
      queryString;
    let req = new Request(`${fetchUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setWorkflowStatuses(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const GetWorkflow = () => {
    setIsLoading(true);
    let apiUrl = getAPIURL(REACT_APP_GETWORKFLOW);
    let body = {
      subProcessID: reference.SubprocessID,
    };
    let req = new Request(`${apiUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setIsLoading(false);
          if (result.data.workflowXML == null) {
            sessionStorage.setItem("IsPublished", "false");
            setxmlDiagram(InitialXml);
            //setIsPublish(result.data.isPublished);
          } else {
            setxmlDiagram(result.data.workflowXML);
            bpmnModeler.current.importXML(result.data.workflowXML);
            sessionStorage.setItem("IsPublished", "true");
            setIsPublish(result.data.isPublished);
          }
          //setIsDashboardload(false);
          setIsSubmitworkflow(false);
          preferenceClose();
        } else if (result.status === 401) {
          setIsLoading(false);
          sessionStorage.setItem("IsPublished", "false");
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 400) {
          setIsLoading(false);
          sessionStorage.setItem("IsPublished", "false");
        }
      })
      .catch((error) => {
        setIsDashboardload(false);
        setIsSubmitworkflow(false);
        console.log("ERROR: ", error);
      });
  };
  const GetWorkflowConfig = (workflowElementID) => {
    //setActivityConfigLoading(true);
    setIsPropertiesLoad(true);
    setIsLoading(true);
    let apiUrl = getAPIURL(REACT_APP_GETWORKFLOWCONFIG);

    let body = {
      WorkflowElementID: workflowElementID,
    };

    let req = new Request(`${apiUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          if (result.data != null) {
            reset(result.data);
            setMilestoneList(result.data.milestoneList);
            if (result.data.noOfDaysToEscalateWorkItem > 0) {
              setIsEscalate(false);
              setValue("isEscalateWorkitems", true);
            } else {
              setIsEscalate(true);
            }
            if (result.data.showMessageOnStatusChange) {
              setIsConfirmationOnChange(false);
            } else {
              setIsConfirmationOnChange(true);
            }
            if (result.data.isUserDefinedStatus) {
              setIsUserDefinedStatus(false);
            } else {
              setIsUserDefinedStatus(true);
            }

            if (result.data.isNormalPath) {
              setIsDefaultStatusSetforScreen(false);
            }
            if (result.data.userDefinedStatus !== "") {
              setIsUserDefinedStatus(false);
              setValue("isUserDefinedStatus", true);
            }
            // if (milestoneList !== null) {
            //   if (!milestoneList.includes(+value.currentTarget.value)) {
            //     setValue("milestoneList", [
            //       ...milestoneList,
            //       +value.currentTarget.value,
            //     ]);
            //   } else {
            //     setValue(
            //       "milestoneList",
            //       milestoneList.filter(
            //         (item) => item !== +value.currentTarget.value
            //       )
            //     );
            //   }
            // }

            // else
            // {
            //   setValue('',true)
            // }
            setIsLoading(false);
            setIsPropertiesLoad(false);
          }
        } else if (result.status === 401) {
          setIsPropertiesLoad(false);
          setIsLoading(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 500) {
          setIsPropertiesLoad(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsPropertiesLoad(false);
        console.log("ERROR: ", error);
      });
  };
  const subprocessSubmitForWorkflow = () => {
    setIsSubmitworkflow(true);
  };
  const handleCommandExecuted = (event) => {
    const command = event.command;

    // Check if the command is related to creating elements
    if (command.type === "shape.create" || command.type === "elements.create") {
      const createdElement = command.context.shape;

      // Check if the created element is a task and it's not during the initial load
      if (
        createdElement &&
        createdElement.type === "bpmn:Task" &&
        !isInitialLoad
      ) {
        isNewTaskAdded = true;

        // Call your method or trigger any other action
        handleNewTask();
      }
    }
  };
  const handleNewTask = () => {
    console.log("Method called as soon as a new task is created...");
    // Add your logic here
  };
  const handleEscalateWorkitems = (e) => {
    setIsEscalate(!IsEscalate);
  };
  const handlesendMail1 = (e) => {
    setsendMail1(e.target.value);
  };
  const handlesendMail2 = (e) => {
    setsendMail2(e.target.value);
  };
  const handlesendMail3 = (e) => {
    setsendMail3(e.target.value);
  };
  const handlesendMail4 = (e) => {
    setsendMail4(e.target.value);
  };
  const handlesendMail5 = (e) => {
    setsendMail5(e.target.value);
  };
  const handlesendMail6 = (e) => {
    setsendMail6(e.target.value);
  };
  const handleEmployeesWithAccess6 = (e) => {
    var isExist = AssignedEmployee6.filter((val) => val == e.target.value);
    if (isExist.length > 0) {
      var newArray = AssignedEmployee6.filter((val) => val != e.target.value);
      setAssignedEmployee6(newArray);
    } else {
      var newArray = [...AssignedEmployee6, e.target.value];
      setAssignedEmployee6(newArray);
    }
  };
  const handleEmployeesWithAccess5 = (e) => {
    var isExist = AssignedEmployee5.filter((val) => val == e.target.value);
    if (isExist.length > 0) {
      var newArray = AssignedEmployee5.filter((val) => val != e.target.value);
      setAssignedEmployee5(newArray);
    } else {
      var newArray = [...AssignedEmployee5, e.target.value];
      setAssignedEmployee5(newArray);
    }
  };
  const handleEmployeesWithAccess4 = (e) => {
    var isExist = AssignedEmployee4.filter((val) => val == e.target.value);
    if (isExist.length > 0) {
      var newArray = AssignedEmployee4.filter((val) => val != e.target.value);
      setAssignedEmployee4(newArray);
    } else {
      var newArray = [...AssignedEmployee4, e.target.value];
      setAssignedEmployee4(newArray);
    }
  };
  const handleEmployeesWithAccess3 = (e) => {
    var isExist = AssignedEmployee3.filter((val) => val == e.target.value);
    if (isExist.length > 0) {
      var newArray = AssignedEmployee3.filter((val) => val != e.target.value);
      setAssignedEmployee3(newArray);
    } else {
      var newArray = [...AssignedEmployee3, e.target.value];
      setAssignedEmployee3(newArray);
    }
  };
  const handleEmployeesWithAccess2 = (e) => {
    var isExist = AssignedEmployee2.filter((val) => val == e.target.value);
    if (isExist.length > 0) {
      var newArray = AssignedEmployee2.filter((val) => val != e.target.value);
      setAssignedEmployee2(newArray);
    } else {
      var newArray = [...AssignedEmployee2, e.target.value];
      setAssignedEmployee2(newArray);
    }
  };
  const handleEmployeesWithAccess1 = (e) => {
    var isExist = AssignedEmployee1.filter((val) => val == e.target.value);
    if (isExist.length > 0) {
      var newArray = AssignedEmployee1.filter((val) => val != e.target.value);
      setAssignedEmployee1(newArray);
    } else {
      var newArray = [...AssignedEmployee1, e.target.value];
      setAssignedEmployee1(newArray);
    }
  };
  const handleEscalateWorkitems1 = (e) => {
    var EscalationDetails = ActivityEscalationMod.filter(
      (e) => e.escLevel == 1
    );
    if (EscalationDetails.length > 0) {
      setsendMail1(EscalationDetails[0].escDays);
      setinterval1(EscalationDetails[0].escInterval);
      setnumberOfMail1(EscalationDetails[0].escNoOfMail);
      setAssignedEmployee1(EscalationDetails[0].escCCmailEmployeeID.split(","));
    }
    setIsEscalate1(!IsEscalate1);
    sessionStorage.setItem("IsEscalate1", !IsEscalate1);
  };
  const handleEscalateWorkitems2 = (e) => {
    var EscalationDetails = ActivityEscalationMod.filter(
      (e) => e.escLevel == 2
    );
    if (EscalationDetails.length > 0) {
      setsendMail2(EscalationDetails[0].escDays);
      setinterval2(EscalationDetails[0].escInterval);
      setnumberOfMail2(EscalationDetails[0].escNoOfMail);
      setAssignedEmployee2(EscalationDetails[0].escCCmailEmployeeID.split(","));
    }
    setIsEscalate2(!IsEscalate2);
    sessionStorage.setItem("IsEscalate2", !IsEscalate2);
  };
  const handleEscalateWorkitems3 = (e) => {
    var EscalationDetails = ActivityEscalationMod.filter(
      (e) => e.escLevel == 3
    );
    if (EscalationDetails.length > 0) {
      setsendMail3(EscalationDetails[0].escDays);
      setinterval3(EscalationDetails[0].escInterval);
      setnumberOfMail3(EscalationDetails[0].escNoOfMail);
      setAssignedEmployee3(EscalationDetails[0].escCCmailEmployeeID.split(","));
    }

    setIsEscalate3(!IsEscalate3);
    sessionStorage.setItem("IsEscalate3", !IsEscalate3);
  };
  const handleEscalateWorkitems4 = (e) => {
    var EscalationDetails = ActivityEscalationMod.filter(
      (e) => e.escLevel == 4
    );
    if (EscalationDetails.length > 0) {
      setsendMail4(EscalationDetails[0].escDays);
      setinterval4(EscalationDetails[0].escInterval);
      setnumberOfMail4(EscalationDetails[0].escNoOfMail);
      setAssignedEmployee4(EscalationDetails[0].escCCmailEmployeeID.split(","));
    }
    setIsEscalate4(!IsEscalate4);
    sessionStorage.setItem("IsEscalate4", !IsEscalate4);
  };
  const handleEscalateWorkitems5 = (e) => {
    var EscalationDetails = ActivityEscalationMod.filter(
      (e) => e.escLevel == 5
    );
    if (EscalationDetails.length > 0) {
      setsendMail5(EscalationDetails[0].escDays);
      setinterval5(EscalationDetails[0].escInterval);
      setnumberOfMail5(EscalationDetails[0].escNoOfMail);
      setAssignedEmployee5(EscalationDetails[0].escCCmailEmployeeID.split(","));
    }
    setIsEscalate5(!IsEscalate5);
    sessionStorage.setItem("IsEscalate5", !IsEscalate5);
  };
  const handleEscalateWorkitems6 = (e) => {
    var EscalationDetails = ActivityEscalationMod.filter(
      (e) => e.escLevel == 6
    );
    if (EscalationDetails.length > 0) {
      setsendMail6(EscalationDetails[0].escDays);
      setinterval6(EscalationDetails[0].escInterval);
      setnumberOfMail6(EscalationDetails[0].escNoOfMail);
      setAssignedEmployee6(EscalationDetails[0].escCCmailEmployeeID.split(","));
    }
    setIsEscalate6(!IsEscalate6);
    sessionStorage.setItem("IsEscalate6", !IsEscalate6);
  };
  const handleSendMail = (e) => {
    setsendMail(!sendMail);
  };
  const getCCMailDuration = (e) => {
    setccMailDuration(e.target.value);
  };
  const handleMon = () => {
    setMon(!mon);
  };
  const handleTue = () => {
    setTue(!tue);
  };
  const handleWed = () => {
    setWed(!wed);
  };
  const handleThu = () => {
    setThu(!thu);
  };
  const handleFri = () => {
    setFri(!fri);
  };
  const handleSat = () => {
    setSat(!sat);
  };
  const handleSun = () => {
    setSun(!sun);
  };
  const handleNoOfEscalations = (e) => {
    setnoOfEscalations(e.target.value);
  };
  const handleIncludeWeekend = () => {
    setincludeWeekend(!includeWeekend);
  };
  const handleconsolidatedMail = () => {
    setconsolidatedMail(!consolidatedMail);
  };
  const handleUnassignedWorkItems = () => {
    setsendMailToUnassignedWorkItems(!sendMailToUnassignedWorkItems);
  };
  const handleCheckboxChange = (value) => {
    if (milestoneList !== null) {
      if (!milestoneList.includes(+value.currentTarget.value)) {
        setMilestoneList([...milestoneList, +value.currentTarget.value]);
      } else {
        setMilestoneList(
          milestoneList.filter((item) => item !== +value.currentTarget.value)
        );
      }
    } else {
      const newValue = +value.currentTarget.value;
      setMilestoneList((preArray) => {
        const currentArray = preArray || [];
        return [...currentArray, newValue];
      });
    }
  };
  const GetActivitiesBySubProcess = () => {
    let queryString =
      reference.SubprocessID === undefined
        ? sessionStorage.getItem("ProcessId")
        : reference.SubprocessID;
    let fetchUrl =
      getAPIURL(REACT_APP_FETCH_MILESTONESBYSUBPROCESSID) + "/" + queryString;
    let req = new Request(`${fetchUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setActivities(result.data);
          //setIsDashboardload(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //setIsDashboardload(false);
        //navigate("/");
      });
  };
  const checkInOutFlows = () => {
    const elementRegistry = bpmnModeler.current.get("elementRegistry");
    const allElements = elementRegistry.getAll();
    let filteredEles = allElements.filter(
      (e, index) => e.type == "bpmn:Task" && e.id.split("_")[2] != 8
    );

    let allNodesHaveInOutFlows = true;

    for (let i = 0; i < filteredEles.length; i++) {
      if (
        filteredEles[i].businessObject.name === "Client" &&
        filteredEles[i].businessObject.incoming.some(
          (incoming) => incoming.name === "Complete"
        )
      ) {
        continue;
      } else if (
        filteredEles[i].businessObject.name === "Upload" ||
        filteredEles[i].businessObject.id == "Process_1" ||
        filteredEles[i].businessObject.id == "StartEvent_1"
      ) {
        continue;
      } else if (
        !(
          filteredEles[i].businessObject.incoming &&
          filteredEles[i].businessObject.incoming.length > 0 &&
          filteredEles[i].businessObject.outgoing &&
          filteredEles[i].businessObject.outgoing.length > 0
        )
      ) {
        allNodesHaveInOutFlows = false;
      }
    }
    if (allNodesHaveInOutFlows) {
      return true;
      setisallNodesHaveInOutFlows(true);
      console.log(
        "All nodes have at least one incoming and one outgoing flow. Ready to save."
      );
    } else {
      return false;
      setisallNodesHaveInOutFlows(false);
      console.log(
        "Not all nodes have at least one incoming and one outgoing flow."
      );
    }
  };
  const CheckValidWorkActivityConfig = (isClicked) => {
    let body = {
      SubProcessID: reference.SubprocessID,
    };
    let ValidConfigUrl = getAPIURL(REACT_APP_CheckValidWorkActivityConfig);
    let req = new Request(`${ValidConfigUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setIsValidWorkActivityConfig(result.data);

          let isChecked = checkInOutFlows();
          const elementRegistry = bpmnModeler.current.get("elementRegistry");
          const allElements = elementRegistry.getAll();
          let hasClientWithCompleteIncoming = false;
          for (const element of allElements) {
            const { businessObject } = element;
            if (businessObject.name === "Client") {
              if (
                businessObject.incoming &&
                businessObject.incoming.some(
                  (incoming) => incoming.name === "Complete" ||incoming.name === "Completed"
                )
              ) {
                hasClientWithCompleteIncoming = true;
                break;
              }
            }
          }
          if (!IsCleintCompleteExist && !hasClientWithCompleteIncoming) {
            addToast("Atleast one of the flow should be Client Complete", {
              appearance: "error",
              autoDismiss: true,
            });
          } else if (!isChecked) {
            addToast(
              "Not all nodes have at least one incoming and one outgoing flow.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          } else if (result.data.length > 0) {
            let elementRegistry = bpmnModeler.current
              .get("elementRegistry")
              .filter(function (element) {
                return (
                  element.businessObject &&
                  element.businessObject.$type === "bpmn:Task"
                );
              });
            var modeling = bpmnModeler.current.get("modeling");
            elementRegistry.map((e) => {
              result.data.map((r) => {
                if (+e.id.split("_")[2] == r.value) {
                  modeling.setColor([e], {
                    stroke: "red",
                    ////fill: "yellow",
                  });
                }
              });
            });
            addToast("Not all nodes have Activities Configured.", {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            executePublish(isClicked);
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //setIsDashboardload(false);
        //navigate("/");
      });
  };
  const executePublish = async (isClicked) => {
    let invalidSequence = 0;
    //let sequenceFlows = CurrentElement.di.bpmnElement.$parent.flowElements;
    let elementRegistry = bpmnModeler.current
      .get("elementRegistry")
      .filter(function (element) {
        return (
          element.businessObject &&
          element.businessObject.$type === "bpmn:SequenceFlow"
        );
      });
    if (
      elementRegistry
        .filter((e) => e.businessObject.name == undefined)
        .filter(
          (e) =>
            e.businessObject.targetRef.name != "Upload" &&
            e.businessObject.sourceRef.name != "Upload" &&
            e.businessObject.sourceRef.name != "Client" &&
            //e.businessObject.targetRef.name != "Upload" &&
            e.businessObject.sourceRef.name != undefined
        ).length
    ) {
      addToast("No Workflow can be Empty kindly Check!", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      for (let i = 0; i < elementRegistry.length; i++) {
        if (elementRegistry[i].di.stroke === "#ff0000") {
          invalidSequence = invalidSequence + 1;
        }
      }
      if (invalidSequence === 0) {
        saveWorkflowUpdatedXml(isClicked);

        const result = await bpmnModeler.current.saveXML();
        const updatedXml = result.xml;
        const jsonXml = JSON.parse(JSON.stringify(result.xml));

        let body = {
          SubProcessID: reference.SubprocessID,
          WorkflowXml: updatedXml,
        };
        let fetchUrl = getAPIURL(REACT_APP_SUBPROCESSWORKFLOWPUBLISH);
        let req = new Request(`${fetchUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
            UserName: sessionStorage.getItem("UserName"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status === 200) {
              setIsPublish(true);
              sessionStorage.setItem("IsPublished", "true");
              addToast("Published Successfully!!", {
                appearance: "success",
                autoDismiss: true,
              });
              onDataSend(true);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
            //setIsDashboardload(false);
            //navigate("/");
          });
      } else {
        addToast("Atleast one of the flow is invalid,kindly check!!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };
  const publishWorkflow = () => {
    setIsPlublishedClicked(true);
    CheckValidWorkActivityConfig(true);
  };
  const handleExportXML = async () => {
    const result = await bpmnModeler.current.saveXML();
    console.log(result.xml);
    setxmlDiagram(result.xml);
  };
  const handleClose = () => setShow(false);
  const closePropertiesPanel = (e) => {
    e.preventDefault();
    handleClose();
  };
  // Inside the BpmnEditor component
  const openPropertiesPanel = () => {
    const propertiesPanel = bpmnModeler.current.get("propertiesPanel");
    const canvas = bpmnModeler.current.get("canvas");

    if (propertiesPanel) {
      const element = canvas.getRootElement();
      propertiesPanel.update(element);
    } else {
      console.error(
        "Properties panel not available or not properly configured."
      );
    }
  };
  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      // sessionStorage.setItem(
      //   "selectedDepartment",
      //   e.target.value.split("/")[1]
      // );
      // sessionStorage.setItem(
      //   "selectedDepartmentName",
      //   e.target.value.split("/")[0]
      // );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };
  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
    }

    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);
    setSelectedClientName(e.target.options[e.target.selectedIndex].text);
    // sessionStorage.setItem("selectedLocation", e.target.value.split("/")[1]);
    // sessionStorage.setItem(
    //   "selectedLocationName",
    //   e.target.value.split("/")[0]
    // );
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const preferenceClose = () => {
    setpreferenceModalShow(false);
    setShowModalButton(false);
  };
  const AdvanceConfigClose = () => {
    setIsEscalate1(false);
    setIsEscalate2(false);
    setIsEscalate3(false);
    setIsEscalate4(false);
    setIsEscalate5(false);
    setIsEscalate6(false);
    setAdvanceConfigModal(false);
    setAdvanceConfigModalButton(false);
  };
  const notificationClick = (e) => {
    e.preventDefault();
    setNotificationModal(!NotificationModal);
  };
  const RemindernotificationClick = (e) => {
    e.preventDefault();
    setRemindernotificationModal(!RemindernotificationModal);
  };
  const handleCCNotificationTo = (e) => {
    var isExist = intCCEmployees.filter((val) => val == +e.target.value);
    if (isExist.length > 0) {
      var newArray = intCCEmployees.filter((val) => val != +e.target.value);
      setintCCEmployees(newArray);
    } else {
      var newArray = [...intCCEmployees, +e.target.value];
      setintCCEmployees(newArray);
    }
  };
  const GetEmployeesWithProcessAccess = () => {
    let body = {
      SubProcessId: reference.SubprocessID,
    };
    let loginUrl = getAPIURL(REACT_APP_GETEMPLOYEESWITHPROCESSACCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setEmployeesWithAccess(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
  const GetActivityEscalationModOnWorflowStateChange = (ActivityId) => {
    let body = {
      SubProcessId: reference.SubprocessID,
      ActivityId: ActivityId,
      UserId: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(
      REACT_APP_GETSUBPROCESSACTIVITYESCALATIONCONFIGMOD
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setActivityEscalationMod(result.data);
          result.data.map((e) => {
            if (e.escLevel == 1) {
              setIsEscalate1(e.escLevel);
              setsendMail1(e.escDays);
              setinterval1(e.escInterval);
              setnumberOfMail1(e.escNoOfMail);
              setAssignedEmployee1(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 2) {
              setIsEscalate2(e.escLevel);
              setsendMail2(e.escDays);
              setinterval2(e.escInterval);
              setnumberOfMail2(e.escNoOfMail);
              setAssignedEmployee2(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 3) {
              setIsEscalate3(e.escLevel);
              setsendMail3(e.escDays);
              setinterval3(e.escInterval);
              setnumberOfMail3(e.escNoOfMail);
              setAssignedEmployee3(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 4) {
              setIsEscalate4(e.escLevel);
              setsendMail4(e.escDays);
              setinterval4(e.escInterval);
              setnumberOfMail4(e.escNoOfMail);
              setAssignedEmployee4(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 5) {
              setIsEscalate5(e.escLevel);
              setsendMail5(e.escDays);
              setinterval5(e.escInterval);
              setnumberOfMail5(e.escNoOfMail);
              setAssignedEmployee5(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 6) {
              setIsEscalate6(e.escLevel);
              setsendMail6(e.escDays);
              setinterval6(e.escInterval);
              setnumberOfMail6(e.escNoOfMail);
              setAssignedEmployee6(e.escCCmailEmployeeID.split(","));
            }
          });
        } else if (result.status === 404) {
          sessionStorage.setItem("IsEscalate1", false);
          sessionStorage.setItem("IsEscalate2", false);
          sessionStorage.setItem("IsEscalate3", false);
          sessionStorage.setItem("IsEscalate4", false);
          sessionStorage.setItem("IsEscalate5", false);
          sessionStorage.setItem("IsEscalate6", false);
          setActivityEscalationMod([]);
          setIsEscalate1(false);
          setsendMail1("");
          setinterval1("");
          setnumberOfMail1("");
          setAssignedEmployee1([]);
          setIsEscalate2(false);
          setsendMail2("");
          setinterval2("");
          setnumberOfMail2("");
          setAssignedEmployee2([]);
          setIsEscalate3(false);
          setsendMail3("");
          setinterval3("");
          setnumberOfMail3("");
          setAssignedEmployee3([]);
          setIsEscalate4(false);
          setsendMail4("");
          setinterval4("");
          setnumberOfMail4("");
          setAssignedEmployee4([]);
          setIsEscalate5(false);
          setsendMail5("");
          setinterval5("");
          setnumberOfMail5("");
          setAssignedEmployee5([]);
          setIsEscalate6(false);
          setsendMail6("");
          setinterval6("");
          setnumberOfMail6("");
          setAssignedEmployee6([]);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
  const GetActivityEscalationMod = () => {
    let body = {
      SubProcessId: reference.SubprocessID,
      ActivityId: ActivityId.split("_")[ActivityId.split("_").length - 1],
      UserId: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(
      REACT_APP_GETSUBPROCESSACTIVITYESCALATIONCONFIGMOD
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setActivityEscalationMod(result.data);
          result.data.map((e) => {
            if (e.escLevel == 1) {
              setIsEscalate1(e.escLevel);
              setsendMail1(e.escDays);
              setinterval1(e.escInterval);
              setnumberOfMail1(e.escNoOfMail);
              setAssignedEmployee1(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 2) {
              setIsEscalate2(e.escLevel);
              setsendMail2(e.escDays);
              setinterval2(e.escInterval);
              setnumberOfMail2(e.escNoOfMail);
              setAssignedEmployee2(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 3) {
              setIsEscalate3(e.escLevel);
              setsendMail3(e.escDays);
              setinterval3(e.escInterval);
              setnumberOfMail3(e.escNoOfMail);
              setAssignedEmployee3(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 4) {
              setIsEscalate4(e.escLevel);
              setsendMail4(e.escDays);
              setinterval4(e.escInterval);
              setnumberOfMail4(e.escNoOfMail);
              setAssignedEmployee4(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 5) {
              setIsEscalate5(e.escLevel);
              setsendMail5(e.escDays);
              setinterval5(e.escInterval);
              setnumberOfMail5(e.escNoOfMail);
              setAssignedEmployee5(e.escCCmailEmployeeID.split(","));
            }
            if (e.escLevel == 6) {
              setIsEscalate6(e.escLevel);
              setsendMail6(e.escDays);
              setinterval6(e.escInterval);
              setnumberOfMail6(e.escNoOfMail);
              setAssignedEmployee6(e.escCCmailEmployeeID.split(","));
            }
          });
        } else if (result.status === 404) {
          setActivityEscalationMod([]);
          setIsEscalate1(false);
          setsendMail1("");
          setinterval1("");
          setnumberOfMail1("");
          setAssignedEmployee1([]);
          setIsEscalate2(false);
          setsendMail2("");
          setinterval2("");
          setnumberOfMail2("");
          setAssignedEmployee2([]);
          setIsEscalate3(false);
          setsendMail3("");
          setinterval3("");
          setnumberOfMail3("");
          setAssignedEmployee3([]);
          setIsEscalate4(false);
          setsendMail4("");
          setinterval4("");
          setnumberOfMail4("");
          setAssignedEmployee4([]);
          setIsEscalate5(false);
          setsendMail5("");
          setinterval5("");
          setnumberOfMail5("");
          setAssignedEmployee5([]);
          setIsEscalate6(false);
          setsendMail6("");
          setinterval6("");
          setnumberOfMail6("");
          setAssignedEmployee6([]);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
  const notificationClose = () => {
    setNotificationModal(false);
  };
  const OpenAdvanceConfiguration = () => {
    setIsEscalate1(false);
    setsendMail1("");
    setAssignedEmployee1([]);
    setIsEscalate2(false);
    setsendMail2("");
    setAssignedEmployee2([]);
    setIsEscalate3(false);
    setsendMail3("");
    setAssignedEmployee3([]);
    setIsEscalate4(false);
    setsendMail5("");
    setAssignedEmployee5([]);
    setIsEscalate6(false);
    setsendMail6("");
    setAssignedEmployee6([]);
    setAdvanceConfigModal(true);
    GetActivityEscalationMod();
  };
  const openModal = () => {
    setpreferenceModalShow(true);
  };
  const FetchInvalidProcessSubprocessByGuid = (e) => {
    if (e.target.value == "Select") {
      setShowModalButton(false);
    } else setShowModalButton(true);

    setSelectedSubprocess(e.target.value);
    setselectedSubprocessName(e.target.options[e.target.selectedIndex].text);
  };
  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    //sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };
  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          //GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: processSubProcessID,
      clientId: locationClientID,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // sessionStorage.setItem(
          //   "SelctedSubprocessName",
          //   result.data[0].processSubProcess
          // );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const GetEmailTemplates = () => {
    let body = {
      //subProcessID: processSubProcessID,
      subProcessID: reference.SubprocessID,
      workItemMode: "Multiple",
    };
    let fetchUrl = getAPIURL(REACT_APP_GetEmailTemplatesAsync);
    let req = new Request(`${fetchUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          if (result.data.length > 0) {
            setEmailTemplates(result.data);
          } else {
            setEmailTemplates([]);
          }
          //setIsDashboardload(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //setIsDashboardload(false);
        //navigate("/");
      });
  };
  const handleConsolidatedMailTemplate = (e) => {
    setconsolidatedEmailConfigID(e.target.value.split("/")[1].toString());
  };

  const handleNonConsolidatedMailTemplate = (e) => {
    setnonConsolidatedEmailConfigID(e.target.value.split("/")[1].toString());
  };

  const handleUnassignedMailTemplate = (e) => {
    setunassignedEmailConfigID(e.target.value.split("/")[1].toString());
  };

  return (
    <>
      <div className="">
        {isLoading ? (
          <div
            className="full-screen-loader d-flex align-items-center"
            style={{ top: "-20%" }}
          >
            <LoadingSpinner />
          </div>
        ) : null}
        <div
          className="dashBoxs"
          style={{
            overflowX: "hidden",
          }}
        >
          {/* <div className="row">
            <div className="col-8"></div>
            <div className="col-4 text-right pe-5">
              <b>{!IsPublish ? "In Draft" : "Published"}</b>
            </div>
          </div> */}

          {IsDashboardload ? (
            <LoadingSpinner />
          ) : (
            <div style={{ overflowX: "hidden" }}>
              <div className="dndflow">
                <div className="bpmn-editor-container">
                  <div ref={containerRef} className="bpmn-canvas"></div>
                  {/* <div id="properties-panel" className="dashBoxs "></div> */}
                </div>
                <button
                  ref={reference.FlowConfigRef}
                  style={{ display: "none" }}
                  onClick={publishWorkflow}
                ></button>
              </div>
              {/* {!IsPublish && ( */}
              {/* <div
                  className="fieldFooter col-4"
                  style={{
                    width: "100%",
                    borderTop: "1px solid #aaaaaa",
                    paddingTop: "5px",
                    //paddingtop: "5px",
                  }}
                >
                  <div className="row">
                    <div className="col text-start" style={{padding:0}}>
                      <button
                        className="btn btn-outline-primary btn-sm ms-3"
                        title="Click here to save the updates on this workitem"
                        onClick={publishWorkflow}
                        disabled={IsPublish}
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </div> */}
              {/* )} */}
            </div>
          )}
        </div>
      </div>
      {!SequenceCanvas ? (
        ActivityConfigLoading ? null : TaskCanvas ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="w-40"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Workflow State Configuration</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="">
                <div className="pageBody">
                  <div className="dashBoxs mb-3">
                    <div className="formWrap">
                      <div className="row align-items-end">
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label"> Activity *</label>
                            <select
                              className="form-control form-control-sm"
                              onChange={(e) =>
                                //updateElementProperties(e, true)
                                handleOnchangeWorkflowStates(e)
                              }
                            >
                              <option>Select</option>
                              {WorkflowStates.map((state) => (
                                <option
                                  value={state.workflowStateID}
                                  selected={
                                    state.workflowStateName ==
                                    CurrentWorkflowState
                                  }
                                >
                                  {state.workflowStateName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Workitem Allocation Mode
                            </label>
                            <select
                              className="form-control form-control-sm"
                              onChange={(e) => handleOnchangeAllocationMode(e)}
                            >
                              <option>Select</option>
                              <option
                                value="Manual"
                                selected={
                                  SelectWorkAllocationMode.toLocaleLowerCase() ===
                                  "manual"
                                }
                              >
                                Manual
                              </option>
                              <option
                                value="Pool"
                                selected={
                                  SelectWorkAllocationMode.toLocaleLowerCase() ===
                                  "pool"
                                }
                              >
                                Pool
                              </option>
                              <option
                                value="Random"
                                selected={
                                  SelectWorkAllocationMode.toLocaleLowerCase() ===
                                  "random"
                                }
                              >
                                Random
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Activity Standard Time (in mins) *
                            </label>
                            <input
                              title="Activity Standard Time (in mins)"
                              value={ActiveStandardTime}
                              onChange={getActivityStandardTime}
                              type="text"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <input
                              className="form-check-input"
                              onChange={getErrorCapture}
                              checked={errorCapture}
                              type="checkbox"
                            />
                            <label className="checkbox-inline">
                              Allow Error Capturing
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={getAutoAssignmentStatus}
                              checked={autoAssignment}
                            />
                            <label className="checkbox-inline">
                              Allow Auto-Assignment on Status Change
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="">
                            <input
                              className="form-check-input"
                              onChange={getErrorCountReport}
                              checked={includeErrorCount}
                              type="checkbox"
                            />
                            <label className="checkbox-inline">
                              Include Error Count in Report
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="">
                            <input
                              className="form-check-input"
                              onChange={getAllocationmodeonRework}
                              checked={allocationModeOnRework}
                              type="checkbox"
                            />
                            <label className="checkbox-inline">
                              Use Allocation Mode on Rework
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashBoxs mb-3">
                    <div className="formWrap">
                      <div className="row align-items-end">
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Workitem Status
                            </label>
                            <div
                              style={{
                                border: "solid 1px #ddd",
                                boxShadow: "0 0 20px rgba(0, 0, 0, 0.08)",
                                maxHeight: "100px",
                                overflowY: "auto",
                                whiteSpace: "nowrap",
                                borderRadius: "5px",
                                width: "240px",
                              }}
                            >
                              <div style={{ paddingLeft: "5px" }}>
                                {WorkflowStatusesConfig.map((state) => (
                                  <div>
                                    <label className="checkbox-inline">
                                      <input
                                        className="form-check-input"
                                        //{...register("isException")}
                                        type="checkbox"
                                        checked={WorkItemstatusIds.includes(
                                          state.workflowStateID
                                        )}
                                        value={state.workflowStateID}
                                        onChange={modifyWorkflowStatuses}
                                      />
                                      {state.workflowStateName}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-start">
                          <button
                            style={{ marginLeft: "0px !important" }}
                            className="btn btn-outline-primary btn-sm"
                            title="Click here to save the updates on this workitem"
                            onClick={OpenAdvanceConfiguration}
                          >
                            Advance Configuration
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="fieldFooter col-4"
                    style={{
                      width: "100%",
                      borderTop: "1px solid #aaaaaa",
                      paddingTop: "5px",
                      //paddingtop: "5px",
                    }}
                  >
                    {!IsWorkflowStatePannelButton ? (
                      <div className="row">
                        <div className="col text-start">
                          <button
                            className="btn btn-outline-primary btn-sm ms-3"
                            title="Click here to save the updates on this workitem"
                            disabled={ButtonDisable}
                            onClick={changeWorkflowStatePanel}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-outline-primary btn-sm ms-3"
                            title="Click here to close this panel"
                            disabled={ButtonDisable}
                            onClick={closePropertiesPanel}
                          >
                            Cancel
                          </button>
                          {/* <button
                                className="btn btn-outline-primary btn-sm ms-3"
                                title="Click here to close this panel"
                                onClick={DeleteNodeOrEdge}
                              >
                                Delete
                              </button> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        ) : null
      ) : IsPropertiesLoad ? null : (
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          style={{ width: "50% !important" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Subprocess Workflow Configuration</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="">
              <div className="pageBody">
                <form onSubmit={handleSubmit(saveWorkflow)}>
                  <div className="dashBoxs mb-3">
                    <div className="formWrap">
                      <div className="row align-items-end">
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {" "}
                              Workflow Status
                            </label>
                            <select
                              className="form-control form-control-sm"
                              {...register("workItemStatusID", {
                                required: true,
                              })}
                              onChange={(e) =>
                                //updateElementProperties(e, false)
                                handleOnchangeWorkflowStatus(e)
                              }
                            >
                              <option value={0}>Select</option>
                              {WorkflowStatuses.map((state) => (
                                <option value={state.workItemStatusID}>
                                  {state.statusName}
                                </option>
                              ))}
                            </select>
                            {errors.WorkflowStatus && (
                              <p>Workflow Status is required.</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div style={{ paddingLeft: "2px" }}>
                        <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-6">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("showMessageOnStatusChange")}
                                type="checkbox"
                                onChange={handleConfirmationOnChange}
                              />
                              Show Confirmation On Status Change
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isStatusCommentMandatory")}
                                type="checkbox"
                              />
                              Is Status Comment Mandatory
                            </label>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-3">
                            <label>Confirmation Text</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control form-control-xs"
                              type="text"
                              // style={{ width: "50px" }}
                              defaultValue={0}
                              readOnly={IsConfirmationOnChange}
                              disabled={IsConfirmationOnChange}
                              {...register("messageText")}
                            ></input>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-5">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isStatusReasonMandatory")}
                                type="checkbox"
                              />
                              Is Status Reason Mandatory
                            </label>
                          </div>
                          <div className="col-md-4">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isUserDefinedStatus")}
                                type="checkbox"
                                onChange={handleUserDefinedStatusOnChange}
                              />
                              User Defined Status
                            </label>
                          </div>
                          <div className="col-md-3">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isEmailEnabled")}
                                type="checkbox"
                              />
                              Send Email
                            </label>
                          </div>
                        </div>
                        {/* <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-5">
                            <label>Email Templates</label>
                          </div>
                          <div className="col-md-7">
                            <input
                              className="form-control form-control-xs"
                              type="text"
                              // style={{ width: "50px" }}
                              defaultValue={0}
                              {...register("EmailConfigID")}
                            ></input>
                          </div>
                        </div> */}
                        <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-5">
                            <label>User Defined Status</label>
                          </div>
                          <div className="col-md-7">
                            <input
                              className="form-control form-control-xs"
                              type="text"
                              // style={{ width: "50px" }}
                              //defaultValue={0}
                              readOnly={IsUserDefinedStatus}
                              disabled={IsUserDefinedStatus}
                              {...register("userDefinedStatus")}
                            ></input>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-12">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isEscalateWorkitems")}
                                type="checkbox"
                                //checked={IsEscalate}
                                onChange={handleEscalateWorkitems}
                              />
                              Using this workflow, escalate workitems pending in
                              this activity for more than
                              <input
                                className="form-control form-control-xs"
                                type="text"
                                style={{ width: "50px" }}
                                //defaultValue={0}
                                readOnly={IsEscalate}
                                disabled={IsEscalate}
                                {...register("noOfDaysToEscalateWorkItem")}
                              ></input>
                              Days
                            </label>
                          </div>
                        </div>
                        {/* <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-6">
                            <label>SLA Config for Workitems in Exception</label>
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-control form-control-xs"
                              type="text"
                              // style={{ width: "50px" }}
                              defaultValue={0}
                              {...register("EmailConfigID")}
                            ></input>
                          </div>
                        </div> */}
                        <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-4">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isException")}
                                type="checkbox"
                              />
                              Exception
                            </label>
                          </div>
                          <div className="col-md-5">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isCompleteForSLA")}
                                type="checkbox"
                              />
                              Completed SLA
                            </label>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ paddingTop: "10px", paddingLeft: "2px" }}
                        >
                          <div className="col-md-4">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isNormalPath")}
                                type="checkbox"
                                onChange={handleNormalPathChanged}
                              />
                              Normal Path
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className="checkbox-inline">
                              <input
                                className="form-check-input"
                                {...register("isDefaultStatusSetForScreen")}
                                type="checkbox"
                                defaultChecked="true"
                                defaultValue={true}
                                disabled={IsDefaultStatusSetforScreen}
                              />
                              Is Default Status Set for Screen
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashBoxs mb-3">
                    <div className="formWrap">
                      <div className="row align-items-end">
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">MileStone</label>

                            <div
                              className="row"
                              style={{
                                paddingTop: "10px",
                                paddingLeft: "2px",
                              }}
                            >
                              {Activities.map((activity, i) => (
                                <label className="checkbox-inline">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value={activity.activityID}
                                    name="milestoneList"
                                    checked={milestoneList?.includes(
                                      activity.activityID
                                    )}
                                    {...register("milestoneList")}
                                    onChange={handleCheckboxChange}
                                  />
                                  {activity.activityName}
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="fieldFooter col-4"
                    style={{
                      width: "100%",
                      borderTop: "1px solid #aaaaaa",
                      paddingTop: "5px",
                      //paddingtop: "5px",
                    }}
                  >
                    <div className="row">
                      <div className="col text-start">
                        <button
                          className="btn btn-outline-primary btn-sm ms-3"
                          title="Click here to save the updates on this workitem"
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-outline-primary btn-sm ms-3"
                          title="Click here to close this panel"
                          onClick={closePropertiesPanel}
                        >
                          Cancel
                        </button>
                        {/* <button
                          className="btn btn-outline-primary btn-sm ms-3"
                          title="Click here to close this panel"
                          onClick={DeleteNodeOrEdge}
                        >
                          Delete
                        </button> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <Modal
        show={preferenceModal}
        size="lg"
        onHide={preferenceClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Process/Sub-Process</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="optionWrap">
            <div className="row align-items-end">
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label"> Company Department</label>
                  <select
                    className="form-control form-control-sm"
                    onChange={FetchLocationClientsByGuid}
                  >
                    <option>Select</option>
                    {departments.map((department) => (
                      <option
                        value={
                          department.companyDepartment +
                          "/" +
                          department.departmentIDString
                        }
                        selected={
                          department.departmentIDString == companyDeparmentID
                        }
                      >
                        {department.companyDepartment}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label">Location Client</label>
                  <select
                    className="form-control form-control-sm"
                    onChange={FetchProcessSubProcessByGuid}
                  >
                    <option>Select</option>
                    {clientLocations.map((location) => (
                      <option
                        value={
                          location.locationClient +
                          "/" +
                          location.clientIDString
                        }
                        selected={
                          location.clientIDString ==
                          (locationClientID == ""
                            ? selectedLocation
                            : locationClientID)
                        }
                      >
                        {location.locationClient}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label">Process SubProcess</label>
                  <select
                    className="form-control form-control-sm"
                    onChange={FetchInvalidProcessSubprocessByGuid}
                  >
                    <option>Select</option>
                    {processSubprocess.map((subprocess) => (
                      <option
                        value={subprocess.subProcessIDString}
                        selected={
                          subprocess.subProcessIDString ==
                          (processSubProcessID == ""
                            ? selectedSubprocess
                            : processSubProcessID)
                        }
                      >
                        {subprocess.processSubProcess}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={subprocessSubmitForWorkflow}
            //disabled={!showModalButton || isSubmit}
          >
            {/* {!isSubmit ? "Submit" : "Loading..."} */}
            Submit
          </button>
          <button
            className="btn btn-outline-primary me-3"
            onClick={preferenceClose}
            //disabled={!showModalButton}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {/* modal for advance configuration */}
      <Modal
        show={AdvanceConfigModal}
        //style={{maxWidth: "800px"}}
        size="lg"
        onHide={AdvanceConfigClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation
      >
        <Modal.Header closeButton style={{ padding: "8px" }}>
          <Modal.Title>
            <h5>Advance Configuration</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="formWrap">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{
                    backgroundColor: "#eeeeee !important",
                    height: "40px !important",
                    padding: "5px !important",
                    minHeight: "0px !important",
                    width: "100% !important",
                  }}
                >
                  <Typography>
                    <h6>Reminder Notification</h6>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="dashBoxs">
                      <div className="formWrap">
                        <div className="row align-items-end">
                          <div className="row">
                            <div className="col-md-12">
                              <label
                                className="checkbox-inline"
                                style={{ fontSize: "13px" }}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={sendMail}
                                  onChange={handleSendMail}
                                />
                                Send reminder notifications for Workitems
                                Pending in this activity for more than
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-2"
                              style={{ fontSize: "13px" }}
                            >
                              Days
                              <input
                                className="form-control form-control-xs"
                                type="text"
                                style={{ width: "50px", float: "right" }}
                                //readOnly={!sendMail}
                                disabled={!sendMail}
                                onChange={getCCMailDuration}
                                value={ccMailDuration}
                              ></input>
                            </div>
                          </div>
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-md-12">
                              <label
                                className="checkbox-inline"
                                // style={{fontSize:"small"}}
                                style={{ fontSize: "13px" }}
                              >
                                Send reminder notifications on :
                              </label>
                              <label
                                className="checkbox-inline"
                                style={{ fontSize: "13px" }}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={mon}
                                  onChange={handleMon}
                                  disabled={!sendMail}
                                />
                                Mon
                              </label>
                              <label
                                className="checkbox-inline"
                                style={{
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  checked={tue}
                                  onChange={handleTue}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Tue
                              </label>
                              <label
                                className="checkbox-inline"
                                style={{
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  checked={wed}
                                  onChange={handleWed}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Wed
                              </label>
                              <label
                                className="checkbox-inline"
                                style={{
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  checked={thu}
                                  onChange={handleThu}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Thu
                              </label>
                              <label
                                className="checkbox-inline"
                                style={{
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  checked={fri}
                                  onChange={handleFri}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Fri
                              </label>
                              <label
                                className="checkbox-inline"
                                style={{
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  checked={sat}
                                  onChange={handleSat}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Sat
                              </label>
                              <label
                                className="checkbox-inline"
                                style={{
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  checked={sun}
                                  onChange={handleSun}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Sun
                              </label>
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ paddingTop: "10px", fontSize: "13px" }}
                          >
                            <div className="col-md-4">
                              <label className="checkbox-inline">
                                CC Notification to:
                              </label>
                              {RemindernotificationModal ? (
                                <>
                                  <a
                                    href="#"
                                    onClick={RemindernotificationClick}
                                  >
                                    CC
                                  </a>
                                  <div
                                    style={{
                                      border: "solid 1px",
                                      maxHeight: "120px",
                                      overflow: "scroll auto",
                                      width: "300px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <div style={{ paddingLeft: "5px" }}>
                                      {EmployeesWithAccess.map((department) => (
                                        <div>
                                          <label className="checkbox-inline">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={intCCEmployees.includes(
                                                +department.personID
                                              )}
                                              value={department.personID}
                                              onChange={handleCCNotificationTo}
                                              disabled={!sendMail}
                                            />
                                            {department.name +
                                              "(" +
                                              department.userName +
                                              ")"}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <a href="#" onClick={RemindernotificationClick}>
                                  CC
                                </a>
                              )}
                            </div>
                            <div
                              className="col-md-5"
                              style={{ fontSize: "13px" }}
                            >
                              No of reminder escalation
                              <input
                                className="form-control form-control-xs"
                                type="text"
                                style={{ width: "50px", float: "right" }}
                                value={noOfEscalations}
                                onChange={handleNoOfEscalations}
                                disabled={!sendMail}
                                //defaultValue={0}
                                //readOnly={IsEscalate}
                                //disabled={IsEscalate}
                                //{...register("noOfDaysToEscalateWorkItem")}
                              />
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ paddingTop: "10px", fontSize: "13px" }}
                          >
                            <div className="col-sm-3">
                              <label className="checkbox-inline">
                                <input
                                  className="form-check-input"
                                  checked={includeWeekend}
                                  onChange={handleIncludeWeekend}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Included Weekend
                              </label>
                            </div>
                            <div className="col-sm-3">
                              <label
                                className="checkbox-inline"
                                style={{
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  checked={consolidatedMail}
                                  onChange={handleconsolidatedMail}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Consolidated Mail
                              </label>
                            </div>
                            <div className="col-sm-6">
                              <label
                                className="checkbox-inline"
                                style={{
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  checked={sendMailToUnassignedWorkItems}
                                  onChange={handleUnassignedWorkItems}
                                  type="checkbox"
                                  disabled={!sendMail}
                                />
                                Send Mail for Unassiged Workitems
                              </label>
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ paddingTop: "10px", fontSize: "13px" }}
                          >
                            <div className="col-3">
                              <div className="mb-3">
                                <label className="checkbox-inline">
                                  Consolidated Email Templates
                                </label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={handleConsolidatedMailTemplate}
                                  disabled={!sendMail}
                                >
                                  <option>-Select Email Template-</option>
                                  {emailTemplates.map((template) => (
                                    <option
                                      value={
                                        template.TemplateName +
                                        "/" +
                                        template.ConsolidatedEmailConfigID
                                      }
                                      selected={
                                        template.ConsolidatedEmailConfigID
                                      }
                                    >
                                      {template.TemplateName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label
                                  className="checkbox-inline"
                                  style={{ fontSize: "13px" }}
                                >
                                  NonConsolidated Email Templates
                                </label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={handleNonConsolidatedMailTemplate}
                                  disabled={!sendMail}
                                >
                                  <option>-Select Email Template-</option>
                                  {emailTemplates.map((template) => (
                                    <option
                                      value={
                                        template.TemplateName +
                                        "/" +
                                        template.NonConsolidatedEmailConfigID
                                      }
                                      selected={
                                        template.NonConsolidatedEmailConfigID
                                      }
                                    >
                                      {template.TemplateName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div
                              className="col-4"
                              style={{ paddingTop: "17px", fontSize: "13px" }}
                            >
                              <div className="mb-3">
                                <label className="checkbox-inline">
                                  Unassigned Email Templates
                                </label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={handleUnassignedMailTemplate}
                                  disabled={!sendMail}
                                >
                                  <option>-Select Email Template-</option>
                                  {emailTemplates.map((template) => (
                                    <option
                                      value={
                                        template.TemplateName +
                                        "/" +
                                        template.UnassignedEmailConfigID
                                      }
                                      selected={
                                        template.UnassignedEmailConfigID
                                      }
                                    >
                                      {template.TemplateName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{
                    backgroundColor: "#eeeeee !important",
                    height: "40px !important",
                    padding: "5px !important",
                    minHeight: "0px !important",
                    width: "100% !important",
                  }}
                >
                  <Typography>
                    <h6>Work Activity Escalation Config</h6>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="dashBoxs mb-3">
                      <div className="formWrap">
                        <div className="row align-items-end">
                          <div className="row">
                            <div className="col-md-12">
                              <label
                                className="checkbox-inline"
                                // style={{fontSize:"small"}}
                                style={{ fontSize: "13px" }}
                              >
                                <input
                                  className="form-check-input"
                                  //{...register("isEscalateWorkitems")}
                                  type="checkbox"
                                  checked={IsEscalate1}
                                  onChange={handleEscalateWorkitems1}
                                />
                                Level 1 Escalation
                              </label>
                            </div>
                          </div>
                          {IsEscalate1 ? (
                            <>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-7">
                                  <label
                                    className="checkbox-inline"
                                    style={{ fontSize: "13px" }}
                                    // style={{fontSize:"small"}}
                                  >
                                    Send Email if Workitem is present more than
                                    :
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{ width: "50px", float: "right" }}
                                    value={sendMail1}
                                    onChange={handlesendMail1}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  ></input>
                                </div>
                                <div className="col-md-3">
                                  <label
                                    className="checkbox-inline"
                                    style={{
                                      paddingTop: "4px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Interval:
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{
                                      width: "50px",
                                      float: "right",
                                      backgroundColor: "#D4D0C8",
                                    }}
                                    value={interval1}
                                    //defaultValue={0}
                                    readOnly={true}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Number of mail:
                                      </label>
                                    </div>
                                    <div className="col-md-2">
                                      <input
                                        className="form-control form-control-xs"
                                        type="text"
                                        style={{
                                          width: "50px",
                                          float: "right",
                                          backgroundColor: "#D4D0C8",
                                        }}
                                        value={numberOfMail1}
                                        readOnly={true}
                                        //defaultValue={0}
                                        // readOnly={IsEscalate}
                                        // disabled={IsEscalate}
                                        // {...register(
                                        //   "noOfDaysToEscalateWorkItem"
                                        // )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        CC:
                                      </label>
                                      {NotificationModal ? (
                                        <>
                                          <a
                                            href="#"
                                            onClick={notificationClick}
                                          >
                                            CC
                                          </a>
                                          <div
                                            style={{
                                              border: "solid 1px",
                                              maxHeight: "120px",
                                              overflow: "scroll auto",
                                              width: "300px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <div style={{ paddingLeft: "5px" }}>
                                              {EmployeesWithAccess.map(
                                                (department) => (
                                                  <div>
                                                    <label className="checkbox-inline">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={AssignedEmployee1.includes(
                                                          department.email
                                                        )}
                                                        value={department.email}
                                                        onChange={
                                                          handleEmployeesWithAccess1
                                                        }
                                                      />
                                                      {department.email}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <a href="#" onClick={notificationClick}>
                                          CC
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-md-12">
                              <label
                                className="checkbox-inline"
                                // style={{fontSize:"small"}}
                                style={{ fontSize: "13px" }}
                              >
                                <input
                                  className="form-check-input"
                                  //{...register("isEscalateWorkitems")}
                                  type="checkbox"
                                  checked={IsEscalate2}
                                  onChange={handleEscalateWorkitems2}
                                />
                                Level 2 Escalation
                              </label>
                            </div>
                          </div>
                          {IsEscalate2 ? (
                            <>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-7">
                                  <label
                                    className="checkbox-inline"
                                    // style={{fontSize:"small"}}
                                    style={{ fontSize: "13px" }}
                                  >
                                    Send Email if Workitem is present more than
                                    :
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{ width: "50px", float: "right" }}
                                    value={sendMail2}
                                    onChange={handlesendMail2}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  ></input>
                                </div>
                                <div className="col-md-3">
                                  <label
                                    className="checkbox-inline"
                                    style={{
                                      paddingTop: "4px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Interval:
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{
                                      width: "50px",
                                      float: "right",
                                      backgroundColor: "#D4D0C8",
                                    }}
                                    value={interval2}
                                    readOnly={true}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Number of mail:
                                      </label>
                                    </div>
                                    <div className="col-md-2">
                                      <input
                                        className="form-control form-control-xs"
                                        type="text"
                                        style={{
                                          width: "50px",
                                          float: "right",
                                          backgroundColor: "#D4D0C8",
                                        }}
                                        value={numberOfMail2}
                                        readOnly={true}
                                        //defaultValue={0}
                                        //readOnly={IsEscalate}
                                        //disabled={IsEscalate}
                                        // {...register(
                                        //   "noOfDaysToEscalateWorkItem"
                                        // )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        CC:
                                      </label>
                                      {NotificationModal ? (
                                        <>
                                          <a
                                            href="#"
                                            onClick={notificationClick}
                                          >
                                            CC
                                          </a>
                                          <div
                                            style={{
                                              border: "solid 1px",
                                              maxHeight: "120px",
                                              overflow: "scroll auto",
                                              width: "220px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <div style={{ paddingLeft: "5px" }}>
                                              {EmployeesWithAccess.map(
                                                (department) => (
                                                  <div>
                                                    <label className="checkbox-inline">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={AssignedEmployee2.includes(
                                                          department.email
                                                        )}
                                                        value={department.email}
                                                        onChange={
                                                          handleEmployeesWithAccess2
                                                        }
                                                      />
                                                      {department.email}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <a href="#" onClick={notificationClick}>
                                          CC
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-md-12">
                              <label
                                className="checkbox-inline"
                                // style={{fontSize:"small"}}
                                style={{ fontSize: "13px" }}
                              >
                                <input
                                  className="form-check-input"
                                  //{...register("isEscalateWorkitems")}
                                  type="checkbox"
                                  checked={IsEscalate3}
                                  onChange={handleEscalateWorkitems3}
                                />
                                Level 3 Escalation
                              </label>
                            </div>
                          </div>
                          {IsEscalate3 ? (
                            <>
                              <div
                                className="row"
                                style={{ paddingTop: "10px", fontSize: "13px" }}
                              >
                                <div className="col-md-7">
                                  <label
                                    className="checkbox-inline"
                                    // style={{fontSize:"small"}}
                                  >
                                    Send Email if Workitem is present more than
                                    :
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{ width: "50px", float: "right" }}
                                    value={sendMail3}
                                    onChange={handlesendMail3}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  ></input>
                                </div>
                                <div className="col-md-3">
                                  <label
                                    className="checkbox-inline"
                                    style={{
                                      paddingTop: "4px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Interval:
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{
                                      width: "50px",
                                      float: "right",
                                      backgroundColor: "#D4D0C8",
                                    }}
                                    value={interval3}
                                    readOnly={true}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Number of mail:
                                      </label>
                                    </div>
                                    <div className="col-md-2">
                                      <input
                                        className="form-control form-control-xs"
                                        type="text"
                                        style={{
                                          width: "50px",
                                          float: "right",
                                          backgroundColor: "#D4D0C8",
                                        }}
                                        value={numberOfMail3}
                                        readOnly={true}
                                        //defaultValue={0}
                                        //readOnly={IsEscalate}
                                        //disabled={IsEscalate}
                                        // {...register(
                                        //   "noOfDaysToEscalateWorkItem"
                                        // )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        CC:
                                      </label>
                                      {NotificationModal ? (
                                        <>
                                          <a
                                            href="#"
                                            onClick={notificationClick}
                                          >
                                            CC
                                          </a>
                                          <div
                                            style={{
                                              border: "solid 1px",
                                              maxHeight: "120px",
                                              overflow: "scroll auto",
                                              width: "220px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <div style={{ paddingLeft: "5px" }}>
                                              {EmployeesWithAccess.map(
                                                (department) => (
                                                  <div>
                                                    <label className="checkbox-inline">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={AssignedEmployee3.includes(
                                                          department.email
                                                        )}
                                                        value={department.email}
                                                        onChange={
                                                          handleEmployeesWithAccess3
                                                        }
                                                      />
                                                      {department.email}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <a href="#" onClick={notificationClick}>
                                          CC
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-md-12">
                              <label
                                className="checkbox-inline"
                                // style={{fontSize:"small"}}
                                style={{ fontSize: "13px" }}
                              >
                                <input
                                  className="form-check-input"
                                  {...register("isEscalateWorkitems")}
                                  type="checkbox"
                                  checked={IsEscalate4}
                                  onChange={handleEscalateWorkitems4}
                                />
                                Level 4 Escalation
                              </label>
                            </div>
                          </div>
                          {IsEscalate4 ? (
                            <>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-7">
                                  <label
                                    className="checkbox-inline"
                                    style={{ fontSize: "13px" }}
                                    // style={{fontSize:"small"}}
                                  >
                                    Send Email if Workitem is present more than
                                    :
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{ width: "50px", float: "right" }}
                                    value={sendMail4}
                                    onChange={handlesendMail4}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  ></input>
                                </div>
                                <div className="col-md-3">
                                  <label
                                    className="checkbox-inline"
                                    style={{
                                      paddingTop: "4px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Interval:
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{
                                      width: "50px",
                                      float: "right",
                                      backgroundColor: "#D4D0C8",
                                    }}
                                    value={interval4}
                                    readOnly={true}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Number of mail:
                                      </label>
                                    </div>
                                    <div className="col-md-2">
                                      <input
                                        className="form-control form-control-xs"
                                        type="text"
                                        style={{
                                          width: "50px",
                                          float: "right",
                                          backgroundColor: "#D4D0C8",
                                        }}
                                        value={numberOfMail4}
                                        readOnly={true}
                                        //defaultValue={0}
                                        //readOnly={IsEscalate}
                                        //disabled={IsEscalate}
                                        // {...register(
                                        //   "noOfDaysToEscalateWorkItem"
                                        // )}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        CC:
                                      </label>
                                      {NotificationModal ? (
                                        <>
                                          <a
                                            href="#"
                                            onClick={notificationClick}
                                          >
                                            CC
                                          </a>
                                          <div
                                            style={{
                                              border: "solid 1px",
                                              maxHeight: "120px",
                                              overflow: "scroll auto",
                                              width: "220px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <div style={{ paddingLeft: "5px" }}>
                                              {EmployeesWithAccess.map(
                                                (department) => (
                                                  <div>
                                                    <label className="checkbox-inline">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={AssignedEmployee4.includes(
                                                          department.email
                                                        )}
                                                        value={department.email}
                                                        onChange={
                                                          handleEmployeesWithAccess4
                                                        }
                                                      />
                                                      {department.email}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <a href="#" onClick={notificationClick}>
                                          CC
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-md-12">
                              <label
                                className="checkbox-inline"
                                // style={{fontSize:"small"}}
                                style={{ fontSize: "13px" }}
                              >
                                <input
                                  className="form-check-input"
                                  //{...register("isEscalateWorkitems")}
                                  type="checkbox"
                                  checked={IsEscalate5}
                                  onChange={handleEscalateWorkitems5}
                                />
                                Level 5 Escalation
                              </label>
                            </div>
                          </div>
                          {IsEscalate5 ? (
                            <>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-7">
                                  <label
                                    className="checkbox-inline"
                                    // style={{fontSize:"small"}}
                                    style={{ fontSize: "13px" }}
                                  >
                                    Send Email if Workitem is present more than
                                    :
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{ width: "50px", float: "right" }}
                                    value={sendMail5}
                                    onChange={handlesendMail5}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  ></input>
                                </div>
                                <div className="col-md-3">
                                  <label
                                    className="checkbox-inline"
                                    style={{
                                      paddingTop: "4px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Interval:
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{
                                      width: "50px",
                                      float: "right",
                                      backgroundColor: "#D4D0C8",
                                    }}
                                    value={interval5}
                                    readOnly={true}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Number of mail:
                                      </label>
                                    </div>
                                    <div className="col-md-2">
                                      <input
                                        className="form-control form-control-xs"
                                        type="text"
                                        style={{
                                          width: "50px",
                                          float: "right",
                                          backgroundColor: "#D4D0C8",
                                        }}
                                        value={numberOfMail5}
                                        readOnly={true}
                                        //defaultValue={0}
                                        //readOnly={IsEscalate}
                                        //disabled={IsEscalate}
                                        // {...register(
                                        //   "noOfDaysToEscalateWorkItem"
                                        // )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        CC:
                                      </label>
                                      {NotificationModal ? (
                                        <>
                                          <a
                                            href="#"
                                            onClick={notificationClick}
                                          >
                                            CC
                                          </a>
                                          <div
                                            style={{
                                              border: "solid 1px",
                                              maxHeight: "120px",
                                              overflow: "scroll auto",
                                              width: "220px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <div style={{ paddingLeft: "5px" }}>
                                              {EmployeesWithAccess.map(
                                                (department) => (
                                                  <div>
                                                    <label className="checkbox-inline">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={AssignedEmployee5.includes(
                                                          department.email
                                                        )}
                                                        value={department.email}
                                                        onClick={
                                                          handleEmployeesWithAccess5
                                                        }
                                                      />
                                                      {department.email}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <a href="#" onClick={notificationClick}>
                                          CC
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-md-12">
                              <label
                                className="checkbox-inline"
                                style={{ fontSize: "13px" }}
                                // style={{fontSize:"small"}}
                              >
                                <input
                                  className="form-check-input"
                                  //{...register("isEscalateWorkitems")}
                                  type="checkbox"
                                  checked={IsEscalate6}
                                  onChange={handleEscalateWorkitems6}
                                />
                                Level 6 Escalation
                              </label>
                            </div>
                          </div>
                          {IsEscalate6 ? (
                            <>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-7">
                                  <label
                                    className="checkbox-inline"
                                    // style={{fontSize:"small"}}
                                    style={{ fontSize: "13px" }}
                                  >
                                    Send Email if Workitem is present more than
                                    :
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{ width: "50px", float: "right" }}
                                    value={sendMail6}
                                    onChange={handlesendMail6}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  ></input>
                                </div>
                                <div className="col-md-3">
                                  <label
                                    className="checkbox-inline"
                                    style={{
                                      paddingTop: "4px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Interval:
                                  </label>
                                  <input
                                    className="form-control form-control-xs"
                                    type="text"
                                    style={{
                                      width: "50px",
                                      float: "right",
                                      backgroundColor: "#D4D0C8",
                                    }}
                                    value={interval6}
                                    readOnly={true}
                                    //defaultValue={0}
                                    //readOnly={IsEscalate}
                                    //disabled={IsEscalate}
                                    //{...register("noOfDaysToEscalateWorkItem")}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Number of mail:
                                      </label>
                                    </div>
                                    <div className="col-md-2">
                                      <input
                                        className="form-control form-control-xs"
                                        type="text"
                                        style={{
                                          width: "50px",
                                          float: "right",
                                          backgroundColor: "#D4D0C8",
                                        }}
                                        value={numberOfMail6}
                                        readOnly={true}
                                        //defaultValue={0}
                                        //readOnly={IsEscalate}
                                        //disabled={IsEscalate}
                                        // {...register(
                                        //   "noOfDaysToEscalateWorkItem"
                                        // )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label
                                        className="checkbox-inline"
                                        style={{
                                          paddingTop: "4px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        CC:
                                      </label>
                                      {NotificationModal ? (
                                        <>
                                          <a
                                            href="#"
                                            onClick={notificationClick}
                                          >
                                            CC
                                          </a>
                                          <div
                                            style={{
                                              border: "solid 1px",
                                              maxHeight: "120px",
                                              overflow: "scroll auto",
                                              width: "220px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <div style={{ paddingLeft: "5px" }}>
                                              {EmployeesWithAccess.map(
                                                (department) => (
                                                  <div>
                                                    <label className="checkbox-inline">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={AssignedEmployee6.includes(
                                                          department.email
                                                        )}
                                                        value={department.email}
                                                        onChange={
                                                          handleEmployeesWithAccess6
                                                        }
                                                      />
                                                      {department.email}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <a href="#" onClick={notificationClick}>
                                          CC
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div
                            className="col-md-4"
                            style={{ paddingTop: "8px" }}
                          >
                            <label
                              className="checkbox-inline"
                              // style={{fontSize:"small"}}
                              style={{ fontSize: "13px" }}
                            >
                              <input
                                className="form-check-input"
                                //{...register("isEscalateWorkitems")}
                                type="checkbox"
                                //checked={IsEscalate}
                                //onChange={handleEscalateWorkitems}
                              />
                              Exclude weekend from interval
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "4px" }}>
          <button
            className="btn btn-primary"
            style={{ padding: "3px" }}
            onClick={AdvanceConfigClose}
          >
            Submit
          </button>
          <button
            className="btn btn-outline-primary me-3"
            style={{ padding: "3px" }}
            onClick={AdvanceConfigClose}
            //disabled={!showModalButton}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WorkFlowConfig;
