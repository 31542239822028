import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import Dropdown from "react-bootstrap/Dropdown";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import user01 from "../../../../assets/images/user/user01.jpg";

const Reports = () => {
  const { addToast } = useToasts();
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_API_URL, React_APP_Report_URL } = process.env;
  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  useEffect(() => {
    //modeRef.current.classList.toggle("mini-mode");

    window.open(React_APP_Report_URL, "_blank", "noopener,noreferrer");
  }, []);
  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="CWS Reports"
        timeCaptureActivityName="Reporting"
      />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="reports" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="">
                <h6>
                  Reports application has been launched & your time is now being
                  captured against the Report activity. You are requested to
                  stay on this page until finishing the report related work.
                  After the report related work is completed, please close the
                  reports application window & navigate away from this page.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Reports;
